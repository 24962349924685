import { URLs } from '../../constants';

export const planUrl = 'https://planagency.ru';
export const sentissUrl = 'https://oftolik.ru';
export const phone = '+7 (495) 229-7663';

export const siteMap = [
  {
    text: 'Главная',
    to: '/',
  },
  {
    text: 'Ночные капли',
    to: URLs.nightdrops,
  },
  {
    text: 'Проблематика КЗС',
    to: URLs.problem,
  },
  {
    text: 'Симптоматика КЗС',
    to: URLs.symptoms,
  },
  {
    text: 'Тест на КЗС',
    to: URLs.test,
  },
  {
    text: 'Полезные советы',
    to: URLs.advices,
  },
];
