import React from 'react';
import styled from 'styled-components';
import { Text } from 'Components/Typography/Text';
import { getVwLg, getVwSm, media, EnumScreens } from 'theme/helpers/css';
import Flex from 'Components/Flex';
import { planUrl } from './constants';
import { ExternalLink } from 'Components/Link';
import { EnumFontFamily, TextStylesLg, TextStylesSm, EnumTextType } from 'theme';

const Container = styled(Flex).attrs({ column: true, between: true })`
  padding: ${getVwSm('15px')};

  ${media.up(EnumScreens.lg)} {
    padding: ${getVwLg('8px')} ${getVwLg('64px')};
    flex-direction: row;
  }
`;

const StyledExtlLink = styled(ExternalLink).attrs({
  href: planUrl,
  target: '_blank',
})`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`;

const textStyle = {
  sm: TextStylesSm[EnumFontFamily.futura + EnumTextType.note],
  lg: TextStylesLg[EnumFontFamily.futura + EnumTextType.body],
};

const StyledText = styled(Text).attrs({ textStyle })`
  ${media.down(EnumScreens.sm)} {
    margin-bottom: ${getVwSm('8px')};
  }
`;

const Note: React.FC = () => (
  <Container>
    <StyledText>© Copyright 2021 Sentiss Pharma Pvt. Ltd. All rights reserved</StyledText>
    <Text textStyle={textStyle}>
      Сайт и материал подготовлены <StyledExtlLink>коммуникационным&nbsp;агентством PLAN</StyledExtlLink>
    </Text>
  </Container>
);

export default Note;
