import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import { media, EnumScreens } from 'theme/helpers/css';

const Box = styled.div`
  ${({ hideBefore }) =>
    hideBefore &&
    `${media.down(hideBefore)} {
        display: none;
      }
    `}

  ${({ hideAfter }) =>
    hideAfter &&
    `${media.up(hideAfter)} {
          display: none;
        }
      `}
`;

interface IDisclaimer {
  color: string;
}

const Disclaimer: React.FC<IDisclaimer> = ({ color }) => (
  <>
    <Box hideBefore={EnumScreens.sm}>
      <svg width="100%" height="100%" viewBox="0 0 1920 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M1920 0H0V81H1920V0Z" fill={colors.dark} />
        <path d="M62.9033 62.6586H60V21.5886H71.9748V62.6586H69.0715V25.7534H62.8932V62.6586H62.9033Z" fill={color} />
        <path
          d="M78.2634 62.6586H75.3601V21.5886H81.0461C84.964 21.5886 86.923 24.9093 86.923 31.5618V35.0824C86.923 41.6905 84.964 45 81.0461 45H78.2533V62.6586H78.2634ZM78.2634 25.7534V40.8353H81.0561C82.1009 40.8353 82.8544 40.4133 83.3265 39.5692C83.7987 38.7252 84.0298 37.3147 84.0298 35.3156V31.2619C84.0298 29.2629 83.7987 27.8524 83.3265 27.0083C82.8544 26.1643 82.1009 25.7423 81.0561 25.7423H78.2634V25.7534Z"
          fill={color}
        />
        <path
          d="M100.103 62.6586H89.4246V21.5886H100.103V25.7534H92.3278V39.1361H98.6467V43.3008H92.3278V58.4938H100.103V62.6586Z"
          fill={color}
        />
        <path
          d="M115.715 21.5886V58.4938H117.573V68.1782H114.67V62.6586H104.222V68.1782H101.319V58.4938H103.368C103.961 57.361 104.302 55.4841 104.373 52.8631L105.116 21.5886H115.715ZM106.231 58.4938H112.811V25.7534H107.869L107.316 52.6854C107.236 55.1065 106.874 57.05 106.231 58.4938Z"
          fill={color}
        />
        <path
          d="M125.459 63.2472C123.55 63.2472 122.084 62.3698 121.049 60.604C120.024 58.8492 119.502 56.3392 119.502 53.0963V31.0953C119.502 27.8524 120.014 25.3535 121.049 23.6099C122.074 21.8663 123.55 21 125.459 21C127.398 21 128.875 21.8663 129.899 23.6099C130.924 25.3535 131.447 27.8413 131.447 31.0953V53.0963C131.447 56.3392 130.934 58.8492 129.899 60.604C128.875 62.3698 127.398 63.2472 125.459 63.2472ZM125.459 59.0824C127.519 59.0824 128.543 57.1833 128.543 53.3962V30.8066C128.543 27.0528 127.519 25.1759 125.459 25.1759C123.43 25.1759 122.405 27.0528 122.405 30.8066V53.3962C122.415 57.1833 123.43 59.0824 125.459 59.0824Z"
          fill={color}
        />
        <path
          d="M140.006 63.2472C138.167 63.2472 136.741 62.392 135.726 60.6706C134.712 58.9492 134.199 56.5503 134.199 53.4517V30.8066C134.199 27.7191 134.702 25.3091 135.706 23.5877C136.711 21.8663 138.147 21.0111 140.006 21.0111C141.864 21.0111 143.301 21.8774 144.305 23.5877C145.31 25.3091 145.812 27.7191 145.812 30.8066V35.0269H143.06V30.5067C143.06 28.7853 142.788 27.4637 142.256 26.5419C141.724 25.6201 141 25.1647 140.076 25.1647C139.162 25.1647 138.429 25.6201 137.896 26.5419C137.364 27.4637 137.092 28.7853 137.092 30.5067V53.7405C137.092 55.4619 137.364 56.7835 137.896 57.7053C138.429 58.6271 139.152 59.0824 140.076 59.0824C140.99 59.0824 141.714 58.6271 142.256 57.7053C142.788 56.7835 143.06 55.4619 143.06 53.7405V47.9876H145.812V53.4406C145.812 56.5281 145.31 58.9381 144.305 60.6595C143.301 62.3809 141.864 63.2472 140.006 63.2472Z"
          fill={color}
        />
        <path d="M154.884 62.6586H151.981V25.7534H147.37V21.5886H159.495V25.7534H154.884V62.6586Z" fill={color} />
        <path
          d="M172.143 62.6586H169.209L168.245 53.9182H162.217L161.253 62.6586H158.541L163.363 21.5886H167.27L172.143 62.6586ZM165.181 26.8084L162.649 49.92H167.783L165.181 26.8084Z"
          fill={color}
        />
        <path
          d="M180.471 62.6586H174.373V21.5886H180.18C182.239 21.5886 183.736 22.3438 184.66 23.8431C185.594 25.3536 186.056 27.708 186.056 30.9177V32.6169C186.056 36.9926 185.092 39.7358 183.153 40.8353V40.9575C185.363 41.9792 186.468 44.8446 186.468 49.5868V53.1074C186.458 59.4711 184.459 62.6586 180.471 62.6586ZM177.276 25.7534V39.1361H179.728C180.913 39.1361 181.787 38.6918 182.329 37.8145C182.872 36.9371 183.143 35.4933 183.143 33.5053V31.3952C183.143 29.3961 182.912 27.9635 182.44 27.0861C181.968 26.2087 181.184 25.7645 180.099 25.7645H177.276V25.7534ZM177.276 43.3008V58.4938H180.471C181.536 58.4938 182.319 58.0607 182.812 57.2055C183.304 56.3504 183.555 54.9399 183.555 52.9852V49.4646C183.555 47.2323 183.274 45.6553 182.721 44.7113C182.169 43.7673 181.214 43.3008 179.878 43.3008H177.276Z"
          fill={color}
        />
        <path
          d="M188.909 62.7141C188.558 62.7141 188.256 62.6586 187.975 62.5364V58.4827C188.327 58.5605 188.518 58.6049 188.568 58.6049C189.482 58.6049 190.145 58.1717 190.537 57.3166C190.929 56.4614 191.14 54.9732 191.17 52.8631L191.873 21.5997H202.471V62.6697H199.568V25.7534H194.626L194.083 52.6854C194.013 56.1616 193.581 58.7048 192.797 60.3152C192.024 61.9145 190.728 62.7141 188.909 62.7141Z"
          fill={color}
        />
        <path
          d="M217.259 21.5886V62.6586H214.356V44.1227H211.975C210.739 44.1227 209.855 44.578 209.333 45.4665C208.81 46.3661 208.549 47.9098 208.549 50.0977V57.6609C208.549 59.5822 208.439 61.237 208.218 62.6475H205.244C205.515 61.3592 205.656 59.6933 205.656 57.6609V50.3309C205.656 45.4443 206.661 42.59 208.67 41.7682V41.7127C206.661 40.6576 205.656 37.9033 205.656 33.4387V30.9177C205.656 27.708 206.118 25.3536 207.052 23.8431C207.987 22.3438 209.473 21.5886 211.533 21.5886H217.259ZM211.975 39.9579H214.356V25.7534H211.603C210.508 25.7534 209.735 26.1976 209.263 27.075C208.79 27.9524 208.559 29.3961 208.559 31.3841V34.3161C208.559 36.3152 208.831 37.7478 209.373 38.6252C209.915 39.5137 210.779 39.9579 211.975 39.9579Z"
          fill={color}
        />
        <path
          d="M231.273 62.6586H220.604V21.5886H231.283V25.7534H223.508V39.1361H229.826V43.3008H223.508V58.4938H231.283V62.6586H231.273Z"
          fill={color}
        />
        <path
          d="M236.226 62.6586H233.664V21.5886H237.863L241.691 54.3846L245.478 21.5886H249.717V62.6586H246.925V28.3966L242.796 62.6586H240.345L236.216 28.3966V62.6586H236.226Z"
          fill={color}
        />
        <path
          d="M265.53 62.6586H262.596L261.632 53.9182H255.604L254.64 62.6586H251.927L256.75 21.5886H260.657L265.53 62.6586ZM258.578 26.8084L256.046 49.92H261.18L258.578 26.8084Z"
          fill={color}
        />
        <path
          d="M279.142 21.5886V62.6586H276.239V44.1227H273.858C272.622 44.1227 271.738 44.578 271.216 45.4665C270.693 46.3661 270.432 47.9098 270.432 50.0977V57.6609C270.432 59.5822 270.322 61.237 270.101 62.6475H267.127C267.398 61.3592 267.539 59.6933 267.539 57.6609V50.3309C267.539 45.4443 268.543 42.59 270.553 41.7682V41.7127C268.543 40.6576 267.539 37.9033 267.539 33.4387V30.9177C267.539 27.708 268.001 25.3536 268.935 23.8431C269.869 22.3438 271.356 21.5886 273.416 21.5886H279.142ZM273.858 39.9579H276.239V25.7534H273.486C272.391 25.7534 271.617 26.1976 271.145 27.075C270.673 27.9524 270.442 29.3961 270.442 31.3841V34.3161C270.442 36.3152 270.713 37.7478 271.256 38.6252C271.798 39.5137 272.672 39.9579 273.858 39.9579Z"
          fill={color}
        />
        <path
          d="M291.006 52.2745L297.586 21.5886H300.6V62.6586H297.958V31.7395L291.378 62.6586H288.364V21.5886H291.006V52.2745Z"
          fill={color}
        />
        <path
          d="M306.889 62.6586H303.986V21.5886H306.889V39.1361H313.067V21.5886H315.97V62.6586H313.067V43.3008H306.889V62.6586Z"
          fill={color}
        />
        <path
          d="M329.241 62.6586H326.338V58.0274H325.152C323.043 58.0274 321.435 57.1056 320.34 55.2731C319.235 53.4406 318.683 50.7418 318.683 47.1768V37.0815C318.683 29.8515 320.842 26.2309 325.152 26.2309H326.338V21.5886H329.241V26.2198H330.426C334.716 26.2198 336.856 29.8404 336.856 37.0704V47.1657C336.856 50.7196 336.313 53.4184 335.218 55.262C334.123 57.1056 332.526 58.0163 330.416 58.0163H329.231V62.6586H329.241ZM325.152 53.796H326.338V30.3957H325.152C322.771 30.3957 321.586 32.528 321.586 36.7927V47.4656C321.576 51.6858 322.771 53.796 325.152 53.796ZM329.241 30.3957V53.796H330.426C332.777 53.796 333.962 51.6858 333.962 47.4656V36.7927C333.962 32.528 332.787 30.3957 330.426 30.3957H329.241Z"
          fill={color}
        />
        <path
          d="M345.234 63.2472C343.325 63.2472 341.859 62.3698 340.824 60.604C339.799 58.8492 339.277 56.3392 339.277 53.0963V31.0953C339.277 27.8524 339.789 25.3535 340.824 23.6099C341.849 21.8663 343.325 21 345.234 21C347.173 21 348.65 21.8663 349.674 23.6099C350.699 25.3535 351.221 27.8413 351.221 31.0953V53.0963C351.221 56.3392 350.709 58.8492 349.674 60.604C348.65 62.3698 347.163 63.2472 345.234 63.2472ZM345.234 59.0824C347.294 59.0824 348.318 57.1833 348.318 53.3962V30.8066C348.318 27.0528 347.294 25.1759 345.234 25.1759C343.205 25.1759 342.18 27.0528 342.18 30.8066V53.3962C342.18 57.1833 343.195 59.0824 345.234 59.0824Z"
          fill={color}
        />
        <path
          d="M357.169 62.6586H354.265V21.5886H359.951C363.869 21.5886 365.828 24.9093 365.828 31.5618V35.0824C365.828 41.6905 363.869 45 359.951 45H357.159V62.6586H357.169ZM357.169 25.7534V40.8353H359.961C361.006 40.8353 361.76 40.4133 362.232 39.5692C362.704 38.7252 362.935 37.3147 362.935 35.3156V31.2619C362.935 29.2629 362.704 27.8524 362.232 27.0083C361.76 26.1643 361.006 25.7423 359.961 25.7423H357.169V25.7534Z"
          fill={color}
        />
        <path
          d="M370.892 62.6586H368.33V21.5886H372.529L376.357 54.3846L380.154 21.5886H384.393V62.6586H381.601V28.3966L377.472 62.6586H375.02L370.892 28.3966V62.6586Z"
          fill={color}
        />
        <path
          d="M400.205 62.6586H397.272L396.308 53.9182H390.28L389.316 62.6586H386.603L391.425 21.5886H395.333L400.205 62.6586ZM393.244 26.8084L390.712 49.92H395.846L393.244 26.8084Z"
          fill={color}
        />
        <path
          d="M416.6 68.1782H413.697V62.6586H402.436V21.5886H405.339V58.4938H411.517V21.5886H414.42V58.4938H416.61V68.1782H416.6Z"
          fill={color}
        />
        <path
          d="M421.443 52.2745L428.023 21.5886H431.036V62.6586H428.394V31.7395L421.814 62.6586H418.801V21.5886H421.443V52.2745Z"
          fill={color}
        />
        <path
          d="M445.804 21.5886V62.6586H442.901V44.1227H440.52C439.284 44.1227 438.4 44.578 437.878 45.4665C437.355 46.3661 437.094 47.9098 437.094 50.0977V57.6609C437.094 59.5822 436.984 61.237 436.763 62.6475H433.789C434.06 61.3592 434.201 59.6933 434.201 57.6609V50.3309C434.201 45.4443 435.206 42.59 437.215 41.7682V41.7127C435.206 40.6576 434.201 37.9033 434.201 33.4387V30.9177C434.201 27.708 434.663 25.3536 435.597 23.8431C436.532 22.3438 438.018 21.5886 440.078 21.5886H445.804ZM440.52 39.9579H442.901V25.7534H440.148C439.053 25.7534 438.28 26.1976 437.807 27.075C437.335 27.9524 437.104 29.3961 437.104 31.3841V34.3161C437.104 36.3152 437.375 37.7478 437.918 38.6252C438.46 39.5137 439.334 39.9579 440.52 39.9579Z"
          fill={color}
        />
        <path
          d="M457.929 62.6586H455.026V21.5886H457.929V39.1361H464.108V21.5886H467.011V62.6586H464.108V43.3008H457.929V62.6586Z"
          fill={color}
        />
        <path
          d="M481.055 62.6586H470.376V21.5886H481.055V25.7534H473.28V39.1361H479.599V43.3008H473.28V58.4938H481.055V62.6586Z"
          fill={color}
        />
        <path
          d="M494.406 63.2472C492.447 63.2472 490.971 62.3809 489.976 60.6373C488.982 58.8936 488.489 56.4281 488.489 53.2185V50.1088H491.242V53.3962C491.242 57.1944 492.267 59.0824 494.326 59.0824C496.355 59.0824 497.38 57.05 497.38 52.9852V49.4091C497.38 45.3443 496.285 43.3119 494.105 43.3119H492.538V39.1361H494.175C496.255 39.1361 497.3 37.2814 497.3 33.5609V30.8621C497.3 27.0639 496.305 25.1759 494.326 25.1759C492.367 25.1759 491.393 27.0195 491.393 30.6955V33.1055H488.64V31.0509C488.64 27.808 489.132 25.3202 490.107 23.5988C491.091 21.8774 492.528 21.0222 494.436 21.0222C496.295 21.0222 497.721 21.8885 498.716 23.5988C499.711 25.3202 500.203 27.7858 500.203 30.9954V32.6391C500.203 37.137 499.188 39.9135 497.149 40.9686V41.0907C499.228 42.1458 500.273 44.9223 500.273 49.4202V53.2296C500.273 56.4725 499.781 58.9603 498.786 60.6817C497.812 62.3809 496.345 63.2472 494.406 63.2472Z"
          fill={color}
        />
        <path
          d="M515.312 62.6586H512.378L511.414 53.9182H505.386L504.422 62.6586H501.709L506.532 21.5886H510.439L515.312 62.6586ZM508.36 26.8084L505.828 49.92H510.962L508.36 26.8084Z"
          fill={color}
        />
        <path
          d="M520.114 62.6586H517.552V21.5886H521.751L525.579 54.3846L529.376 21.5886H533.615V62.6586H530.823V28.3966L526.694 62.6586H524.243L520.114 28.3966V62.6586Z"
          fill={color}
        />
        <path
          d="M547.63 62.6586H536.951V21.5886H547.63V25.7534H539.854V39.1361H546.173V43.3008H539.854V58.4938H547.63V62.6586Z"
          fill={color}
        />
        <path
          d="M552.914 62.6586H550.01V21.5886H552.914V39.1361H559.092V21.5886H561.995V62.6586H559.092V43.3008H552.914V62.6586Z"
          fill={color}
        />
        <path
          d="M576.753 21.5886V62.6586H573.849V44.1227H571.469C570.233 44.1227 569.349 44.578 568.826 45.4665C568.304 46.3661 568.043 47.9098 568.043 50.0977V57.6609C568.043 59.5822 567.932 61.237 567.711 62.6475H564.738C565.009 61.3592 565.15 59.6933 565.15 57.6609V50.3309C565.15 45.4443 566.154 42.59 568.163 41.7682V41.7127C566.154 40.6576 565.15 37.9033 565.15 33.4387V30.9177C565.15 27.708 565.612 25.3536 566.546 23.8431C567.48 22.3438 568.967 21.5886 571.027 21.5886H576.753ZM571.479 39.9579H573.86V25.7534H571.107C570.012 25.7534 569.238 26.1976 568.766 27.075C568.294 27.9524 568.063 29.3961 568.063 31.3841V34.3161C568.063 36.3152 568.334 37.7478 568.877 38.6252C569.419 39.5026 570.283 39.9579 571.479 39.9579Z"
          fill={color}
        />
        <path
          d="M590.777 62.6586H580.098V21.5886H590.777V25.7534H583.001V39.1361H589.32V43.3008H583.001V58.4938H590.777V62.6586Z"
          fill={color}
        />
        <path d="M599.517 62.6586H596.613V25.7534H592.002V21.5886H604.128V25.7534H599.517V62.6586Z" fill={color} />
        <path
          d="M624.993 62.6586H621.939L617.589 42.2458L615.098 49.1093V62.6586H612.195V21.5886H615.098V41.8237L621.909 21.5886H624.923L619.528 37.0259L624.993 62.6586Z"
          fill={color}
        />
        <path
          d="M632.317 63.2472C630.408 63.2472 628.941 62.3698 627.907 60.604C626.882 58.8492 626.36 56.3392 626.36 53.0963V31.0953C626.36 27.8524 626.872 25.3535 627.907 23.6099C628.931 21.8663 630.408 21 632.317 21C634.256 21 635.732 21.8663 636.757 23.6099C637.782 25.3535 638.304 27.8413 638.304 31.0953V53.0963C638.304 56.3392 637.792 58.8492 636.757 60.604C635.732 62.3698 634.256 63.2472 632.317 63.2472ZM632.317 59.0824C634.376 59.0824 635.401 57.1833 635.401 53.3962V30.8066C635.401 27.0528 634.376 25.1759 632.317 25.1759C630.288 25.1759 629.263 27.0528 629.263 30.8066V53.3962C629.273 57.1833 630.288 59.0824 632.317 59.0824Z"
          fill={color}
        />
        <path
          d="M644.261 62.6586H641.358V21.5886H644.261V39.1361H650.44V21.5886H653.343V62.6586H650.44V43.3008H644.261V62.6586Z"
          fill={color}
        />
        <path
          d="M662.224 63.2472C660.385 63.2472 658.959 62.392 657.944 60.6706C656.929 58.9492 656.417 56.5503 656.417 53.4517V30.8066C656.417 27.7191 656.919 25.3091 657.924 23.5877C658.928 21.8663 660.355 21.0111 662.224 21.0111C664.082 21.0111 665.519 21.8774 666.523 23.5877C667.528 25.3091 668.03 27.7191 668.03 30.8066V35.0269H665.278V30.5067C665.278 28.7853 665.006 27.4637 664.474 26.5419C663.941 25.6201 663.218 25.1647 662.294 25.1647C661.38 25.1647 660.646 25.6201 660.114 26.5419C659.581 27.4637 659.31 28.7853 659.31 30.5067V53.7405C659.31 55.4619 659.581 56.7835 660.114 57.7053C660.646 58.6271 661.37 59.0824 662.294 59.0824C663.208 59.0824 663.931 58.6271 664.474 57.7053C665.006 56.7835 665.278 55.4619 665.278 53.7405V47.9876H668.03V53.4406C668.03 56.5281 667.528 58.9381 666.523 60.6595C665.519 62.3809 664.082 63.2472 662.224 63.2472Z"
          fill={color}
        />
        <path
          d="M676.65 45.6442L680.186 21.5886H682.938L677.544 56.4947C677.172 58.9158 676.599 60.6484 675.836 61.6812C675.062 62.7141 673.977 63.2361 672.561 63.2361C671.787 63.2361 671.114 63.0251 670.511 62.5919V58.5382C671.054 58.8936 671.617 59.0713 672.189 59.0713C672.933 59.0713 673.495 58.8381 673.867 58.3717C674.239 57.9052 674.52 57.0834 674.721 55.9061L675.133 53.2629L669.698 21.5886H672.671L676.65 45.6442Z"
          fill={color}
        />
        <path
          d="M682.757 62.7141C682.406 62.7141 682.104 62.6586 681.823 62.5364V58.4827C682.175 58.5605 682.366 58.6049 682.416 58.6049C683.33 58.6049 683.993 58.1717 684.385 57.3166C684.776 56.4614 684.987 54.9732 685.018 52.8631L685.731 21.5886H696.329V62.6586H693.426V25.7534H688.483L687.921 52.6854C687.851 56.1616 687.419 58.7048 686.635 60.3152C685.862 61.9145 684.566 62.7141 682.757 62.7141Z"
          fill={color}
        />
        <path
          d="M705.401 62.6586H699.715V21.5886H702.618V37.9588H705.411C709.329 37.9588 711.288 41.2795 711.288 47.932V52.6854C711.278 59.3379 709.319 62.6586 705.401 62.6586ZM702.618 42.1236V58.4938H705.411C706.456 58.4938 707.209 58.0718 707.681 57.2277C708.153 56.3837 708.384 54.9732 708.384 52.9741V47.6322C708.384 45.6331 708.153 44.2226 707.681 43.3786C707.209 42.5345 706.456 42.1125 705.411 42.1125H702.618V42.1236Z"
          fill={color}
        />
        <path d="M717.526 62.6586H714.623V25.7534H710.012V21.5886H722.137V25.7534H717.526V62.6586Z" fill={color} />
        <path
          d="M734.785 62.6586H731.852L730.888 53.9182H724.86L723.896 62.6586H721.183L726.005 21.5886H729.913L734.785 62.6586ZM727.834 26.8084L725.302 49.92H730.435L727.834 26.8084Z"
          fill={color}
        />
        <path
          d="M751.19 68.1782H748.287V62.6586H737.015V21.5886H739.919V58.4938H746.097V21.5886H749V58.4938H751.19V68.1782Z"
          fill={color}
        />
        <path
          d="M756.022 52.2745L762.603 21.5886H765.616V62.6586H762.974V31.7395L756.394 62.6586H753.38V21.5886H756.022V52.2745Z"
          fill={color}
        />
        <path
          d="M781.348 21.0111C783.277 21.0111 784.764 21.8774 785.788 23.621C786.813 25.3647 787.336 27.8524 787.336 31.1065V53.1074C787.336 56.3504 786.823 58.8603 785.788 60.6151C784.764 62.3698 783.277 63.2583 781.348 63.2583C779.439 63.2583 777.973 62.3809 776.938 60.6151C775.913 58.8603 775.391 56.3504 775.391 53.1074V43.3119H771.895V62.6697H768.992V21.5886H771.895V39.1361H775.391V31.0953C775.391 27.8524 775.903 25.3535 776.938 23.6099C777.973 21.8774 779.439 21.0111 781.348 21.0111ZM784.442 53.3851V30.8066C784.442 27.0528 783.418 25.1759 781.358 25.1759C779.329 25.1759 778.304 27.0528 778.304 30.8066V53.3962C778.304 57.1944 779.319 59.0824 781.358 59.0824C783.408 59.0824 784.442 57.1833 784.442 53.3851Z"
          fill={color}
        />
        <path
          d="M796.045 62.7141C795.694 62.7141 795.392 62.6586 795.111 62.5364V58.4827C795.463 58.5605 795.654 58.6049 795.704 58.6049C796.618 58.6049 797.281 58.1717 797.673 57.3166C798.065 56.4614 798.276 54.9732 798.306 52.8631L799.009 21.5997H809.607V62.6697H806.704V25.7534H801.761L801.209 52.6854C801.139 56.1616 800.707 58.7048 799.923 60.3152C799.15 61.9145 797.854 62.7141 796.045 62.7141Z"
          fill={color}
        />
        <path
          d="M823.672 62.6586H812.993V21.5886H823.672V25.7534H815.896V39.1361H822.215V43.3008H815.896V58.4938H823.672V62.6586Z"
          fill={color}
        />
        <path
          d="M834.642 36.9593V21.5886H837.545V62.6586H834.642V44.467C833.647 46.4993 832.241 47.5211 830.402 47.5211C827.077 47.5211 825.42 44.6669 825.42 38.9584V21.5886H828.323V38.3031C828.323 41.6682 829.257 43.3453 831.116 43.3453C832.181 43.3453 833.035 42.79 833.677 41.6682C834.32 40.5688 834.642 39.0028 834.642 36.9593Z"
          fill={color}
        />
        <path
          d="M853.357 62.6586H850.424L849.459 53.9182H843.432L842.468 62.6586H839.755L844.577 21.5886H848.485L853.357 62.6586ZM846.395 26.8084L843.864 49.92H848.997L846.395 26.8084Z"
          fill={color}
        />
        <path
          d="M873.921 21.5886V58.4938H876.081V68.2338H873.178V62.6586H855.588V21.5886H858.491V58.4938H863.293V21.5886H866.196V58.4938H871.028V21.5886H873.921Z"
          fill={color}
        />
        <path
          d="M888.98 62.6586H878.312V21.5886H888.98V25.7534H881.205V39.1361H887.524V43.3008H881.205V58.4938H888.98V62.6586Z"
          fill={color}
        />
        <path d="M894.264 62.6586H891.361V21.5886H901.327V25.7534H894.264V62.6586Z" fill={color} />
        <path
          d="M908.962 63.2472C907.053 63.2472 905.586 62.3698 904.551 60.604C903.527 58.8492 903.004 56.3392 903.004 53.0963V31.0953C903.004 27.8524 903.517 25.3535 904.551 23.6099C905.576 21.8663 907.053 21 908.962 21C910.89 21 912.377 21.8663 913.402 23.6099C914.427 25.3535 914.949 27.8413 914.949 31.0953V53.0963C914.949 56.3392 914.437 58.8492 913.402 60.604C912.367 62.3698 910.89 63.2472 908.962 63.2472ZM908.962 59.0824C911.021 59.0824 912.046 57.1833 912.046 53.3962V30.8066C912.046 27.0528 911.021 25.1759 908.962 25.1759C906.932 25.1759 905.908 27.0528 905.908 30.8066V53.3962C905.908 57.1833 906.922 59.0824 908.962 59.0824Z"
          fill={color}
        />
        <path
          d="M929.968 62.6586H923.87V21.5886H929.676C931.736 21.5886 933.232 22.3438 934.157 23.8431C935.091 25.3536 935.553 27.708 935.553 30.9177V32.6169C935.553 36.9926 934.589 39.7358 932.65 40.8353V40.9575C934.86 41.9792 935.955 44.8446 935.955 49.5868V53.1074C935.955 59.4711 933.966 62.6586 929.968 62.6586ZM926.773 25.7534V39.1361H929.224C930.42 39.1361 931.284 38.6918 931.826 37.8145C932.368 36.9371 932.64 35.4933 932.64 33.5053V31.3952C932.64 29.3961 932.409 27.9635 931.937 27.0861C931.464 26.2087 930.681 25.7645 929.596 25.7645H926.773V25.7534ZM926.773 43.3008V58.4938H929.968C931.032 58.4938 931.816 58.0607 932.308 57.2055C932.8 56.3504 933.052 54.9399 933.052 52.9852V49.4646C933.052 47.2323 932.77 45.6553 932.218 44.7113C931.655 43.7673 930.711 43.3008 929.375 43.3008H926.773Z"
          fill={color}
        />
        <path
          d="M941.54 62.6586H938.637V21.5886H944.323C948.241 21.5886 950.2 24.9093 950.2 31.5618V35.0824C950.2 41.6905 948.241 45 944.323 45H941.53V62.6586H941.54ZM941.54 25.7534V40.8353H944.333C945.378 40.8353 946.131 40.4133 946.604 39.5692C947.076 38.7252 947.307 37.3147 947.307 35.3156V31.2619C947.307 29.2629 947.076 27.8524 946.604 27.0083C946.131 26.1643 945.378 25.7423 944.333 25.7423H941.54V25.7534Z"
          fill={color}
        />
        <path
          d="M963.742 62.6586H960.809L959.844 53.9182H953.817L952.852 62.6586H950.14L954.962 21.5886H958.87L963.742 62.6586ZM956.79 26.8084L954.259 49.92H959.392L956.79 26.8084Z"
          fill={color}
        />
        <path
          d="M973.226 36.9593V21.5886H976.129V62.6586H973.226V44.467C972.231 46.4993 970.825 47.5211 968.986 47.5211C965.661 47.5211 964.003 44.6669 964.003 38.9584V21.5886H966.907V38.3031C966.907 41.6682 967.841 43.3453 969.699 43.3453C970.764 43.3453 971.618 42.79 972.261 41.6682C972.904 40.5688 973.226 39.0028 973.226 36.9593Z"
          fill={color}
        />
        <path
          d="M991.931 62.6586H988.998L988.033 53.9182H982.006L981.041 62.6586H978.329L983.151 21.5886H987.059L991.931 62.6586ZM984.979 26.8084L982.448 49.92H987.581L984.979 26.8084Z"
          fill={color}
        />
        <path d="M997.255 62.6585H994.322V56.739H997.255V62.6585Z" fill={color} />
        <path
          d="M1009.3 52.2745L1015.88 21.5886H1018.89V62.6586H1016.25V31.7395L1009.67 62.6586H1006.66V21.5886H1009.3V52.2745Z"
          fill={color}
        />
        <path
          d="M1024.85 62.6586H1022.29V21.5886H1026.49L1030.32 54.3846L1034.11 21.5886H1038.35V62.6586H1035.56V28.3966L1031.43 62.6586H1028.98L1024.85 28.3966V62.6586Z"
          fill={color}
        />
        <path
          d="M1052.37 62.6586H1041.69V21.5886H1052.37V25.7534H1044.59V39.1361H1050.91V43.3008H1044.59V58.4938H1052.37V62.6586Z"
          fill={color}
        />
        <path
          d="M1067.09 21.0111C1069.03 21.0111 1070.51 21.8774 1071.53 23.621C1072.56 25.3647 1073.08 27.8524 1073.08 31.1065V53.1074C1073.08 56.3504 1072.57 58.8603 1071.53 60.6151C1070.51 62.3698 1069.02 63.2583 1067.09 63.2583C1065.19 63.2583 1063.72 62.3809 1062.68 60.6151C1061.66 58.8603 1061.14 56.3504 1061.14 53.1074V43.3119H1057.64V62.6697H1054.74V21.5886H1057.64V39.1361H1061.14V31.0953C1061.14 27.8524 1061.65 25.3535 1062.68 23.6099C1063.72 21.8774 1065.19 21.0111 1067.09 21.0111ZM1070.19 53.3851V30.8066C1070.19 27.0528 1069.16 25.1759 1067.1 25.1759C1065.08 25.1759 1064.05 27.0528 1064.05 30.8066V53.3962C1064.05 57.1944 1065.07 59.0824 1067.1 59.0824C1069.15 59.0824 1070.19 57.1833 1070.19 53.3851Z"
          fill={color}
        />
        <path d="M1082.27 62.6586H1079.37V25.7534H1074.76V21.5886H1086.89V25.7534H1082.27V62.6586Z" fill={color} />
        <path
          d="M1094.36 63.2472C1092.52 63.2472 1091.09 62.392 1090.08 60.6706C1089.07 58.9492 1088.55 56.5503 1088.55 53.4517V30.8066C1088.55 27.7191 1089.06 25.3091 1090.06 23.5877C1091.06 21.8663 1092.49 21.0111 1094.36 21.0111C1096.22 21.0111 1097.65 21.8774 1098.66 23.5877C1099.66 25.3091 1100.17 27.7191 1100.17 30.8066V35.0269H1097.41V30.5067C1097.41 28.7853 1097.14 27.4637 1096.61 26.5419C1096.08 25.6201 1095.35 25.1647 1094.43 25.1647C1093.52 25.1647 1092.78 25.6201 1092.25 26.5419C1091.72 27.4637 1091.45 28.7853 1091.45 30.5067V53.7405C1091.45 55.4619 1091.72 56.7835 1092.25 57.7053C1092.78 58.6271 1093.51 59.0824 1094.43 59.0824C1095.34 59.0824 1096.07 58.6271 1096.61 57.7053C1097.14 56.7835 1097.41 55.4619 1097.41 53.7405V47.9876H1100.17V53.4406C1100.17 56.5281 1099.66 58.9381 1098.66 60.6595C1097.65 62.3809 1096.22 63.2472 1094.36 63.2472Z"
          fill={color}
        />
        <path
          d="M1114.26 21.5886V62.6586H1111.36V44.1227H1108.98C1107.74 44.1227 1106.86 44.578 1106.33 45.4665C1105.81 46.3661 1105.55 47.9098 1105.55 50.0977V57.6609C1105.55 59.5822 1105.44 61.237 1105.22 62.6475H1102.25C1102.52 61.3592 1102.66 59.6933 1102.66 57.6609V50.3309C1102.66 45.4443 1103.66 42.59 1105.67 41.7682V41.7127C1103.66 40.6576 1102.66 37.9033 1102.66 33.4387V30.9177C1102.66 27.708 1103.12 25.3536 1104.05 23.8431C1104.99 22.3438 1106.47 21.5886 1108.53 21.5886H1114.26ZM1108.98 39.9579H1111.36V25.7534H1108.6C1107.51 25.7534 1106.74 26.1976 1106.26 27.075C1105.79 27.9524 1105.56 29.3961 1105.56 31.3841V34.3161C1105.56 36.3152 1105.83 37.7478 1106.37 38.6252C1106.92 39.5137 1107.79 39.9579 1108.98 39.9579Z"
          fill={color}
        />
        <path
          d="M1126.39 62.6586H1123.48V21.5886H1135.46V62.6586H1132.55V25.7534H1126.38V62.6586H1126.39Z"
          fill={color}
        />
        <path
          d="M1141.75 62.6586H1138.84V21.5886H1144.53C1148.45 21.5886 1150.41 24.9093 1150.41 31.5618V35.0824C1150.41 41.6905 1148.45 45 1144.53 45H1141.74V62.6586H1141.75ZM1141.75 25.7534V40.8353H1144.54C1145.58 40.8353 1146.34 40.4133 1146.81 39.5692C1147.28 38.7252 1147.51 37.3147 1147.51 35.3156V31.2619C1147.51 29.2629 1147.28 27.8524 1146.81 27.0083C1146.34 26.1643 1145.58 25.7423 1144.54 25.7423H1141.75V25.7534Z"
          fill={color}
        />
        <path
          d="M1158.55 63.2472C1156.64 63.2472 1155.18 62.3698 1154.14 60.604C1153.12 58.8492 1152.6 56.3392 1152.6 53.0963V31.0953C1152.6 27.8524 1153.11 25.3535 1154.14 23.6099C1155.17 21.8663 1156.64 21 1158.55 21C1160.48 21 1161.97 21.8663 1162.99 23.6099C1164.02 25.3535 1164.54 27.8413 1164.54 31.0953V53.0963C1164.54 56.3392 1164.03 58.8492 1162.99 60.604C1161.97 62.3698 1160.49 63.2472 1158.55 63.2472ZM1158.55 59.0824C1160.61 59.0824 1161.64 57.1833 1161.64 53.3962V30.8066C1161.64 27.0528 1160.61 25.1759 1158.55 25.1759C1156.52 25.1759 1155.5 27.0528 1155.5 30.8066V53.3962C1155.51 57.1833 1156.52 59.0824 1158.55 59.0824Z"
          fill={color}
        />
        <path d="M1173.73 62.6586H1170.83V25.7534H1166.22V21.5886H1178.34V25.7534H1173.73V62.6586Z" fill={color} />
        <path
          d="M1183.18 52.2745L1189.76 21.5886H1192.77V62.6586H1190.13V31.7395L1183.55 62.6586H1180.53V21.5886H1183.18V52.2745Z"
          fill={color}
        />
        <path
          d="M1202.25 62.6586H1196.15V21.5886H1201.96C1204.02 21.5886 1205.52 22.3438 1206.44 23.8431C1207.38 25.3536 1207.84 27.708 1207.84 30.9177V32.6169C1207.84 36.9926 1206.87 39.7358 1204.93 40.8353V40.9575C1207.15 41.9792 1208.24 44.8446 1208.24 49.5868V53.1074C1208.24 59.4711 1206.25 62.6586 1202.25 62.6586ZM1199.06 25.7534V39.1361H1201.51C1202.69 39.1361 1203.57 38.6918 1204.11 37.8145C1204.65 36.9371 1204.92 35.4933 1204.92 33.5053V31.3952C1204.92 29.3961 1204.69 27.9635 1204.22 27.0861C1203.75 26.2087 1202.97 25.7645 1201.88 25.7645H1199.06V25.7534ZM1199.06 43.3008V58.4938H1202.25C1203.32 58.4938 1204.1 58.0607 1204.59 57.2055C1205.09 56.3504 1205.34 54.9399 1205.34 52.9852V49.4646C1205.34 47.2323 1205.06 45.6553 1204.5 44.7113C1203.94 43.7673 1203 43.3008 1201.66 43.3008H1199.06Z"
          fill={color}
        />
        <path
          d="M1216.58 63.2472C1214.67 63.2472 1213.2 62.3698 1212.17 60.604C1211.14 58.8492 1210.62 56.3392 1210.62 53.0963V31.0953C1210.62 27.8524 1211.13 25.3535 1212.17 23.6099C1213.19 21.8663 1214.67 21 1216.58 21C1218.51 21 1219.99 21.8663 1221.02 23.6099C1222.04 25.3535 1222.57 27.8413 1222.57 31.0953V53.0963C1222.57 56.3392 1222.05 58.8492 1221.02 60.604C1219.99 62.3698 1218.51 63.2472 1216.58 63.2472ZM1216.58 59.0824C1218.64 59.0824 1219.66 57.1833 1219.66 53.3962V30.8066C1219.66 27.0528 1218.64 25.1759 1216.58 25.1759C1214.55 25.1759 1213.52 27.0528 1213.52 30.8066V53.3962C1213.52 57.1833 1214.54 59.0824 1216.58 59.0824Z"
          fill={color}
        />
        <path
          d="M1228.51 62.6586H1225.61V21.5886H1237.58V62.6586H1234.68V25.7534H1228.5V62.6586H1228.51Z"
          fill={color}
        />
        <path
          d="M1246.63 63.2472C1244.72 63.2472 1243.25 62.3698 1242.22 60.604C1241.19 58.8492 1240.67 56.3392 1240.67 53.0963V31.0953C1240.67 27.8524 1241.18 25.3535 1242.22 23.6099C1243.24 21.8663 1244.72 21 1246.63 21C1248.55 21 1250.04 21.8663 1251.07 23.6099C1252.09 25.3535 1252.61 27.8413 1252.61 31.0953V53.0963C1252.61 56.3392 1252.1 58.8492 1251.07 60.604C1250.04 62.3698 1248.56 63.2472 1246.63 63.2472ZM1246.63 59.0824C1248.69 59.0824 1249.71 57.1833 1249.71 53.3962V30.8066C1249.71 27.0528 1248.69 25.1759 1246.63 25.1759C1244.6 25.1759 1243.57 27.0528 1243.57 30.8066V53.3962C1243.57 57.1833 1244.6 59.0824 1246.63 59.0824Z"
          fill={color}
        />
        <path
          d="M1268.46 62.6586H1265.4L1261.05 42.2458L1258.56 49.1093V62.6586H1255.66V21.5886H1258.56V41.8237L1265.37 21.5886H1268.38L1262.99 37.0148L1268.46 62.6586Z"
          fill={color}
        />
        <path
          d="M1283.11 62.6586H1280.18L1279.21 53.9182H1273.19L1272.22 62.6586H1269.51L1274.34 21.5886H1278.25L1283.11 62.6586ZM1276.16 26.8084L1273.63 49.92H1278.76L1276.16 26.8084Z"
          fill={color}
        />
        <path
          d="M1290.44 63.2472C1288.48 63.2472 1287 62.3809 1286.01 60.6373C1285.01 58.8936 1284.52 56.4281 1284.52 53.2185V50.1088H1287.27V53.3962C1287.27 57.1944 1288.3 59.0824 1290.36 59.0824C1292.38 59.0824 1293.41 57.05 1293.41 52.9852V49.4091C1293.41 45.3443 1292.31 43.3119 1290.13 43.3119H1288.57V39.1361H1290.2C1292.28 39.1361 1293.33 37.2814 1293.33 33.5609V30.8621C1293.33 27.0639 1292.33 25.1759 1290.36 25.1759C1288.4 25.1759 1287.42 27.0195 1287.42 30.6955V33.1055H1284.67V31.0509C1284.67 27.808 1285.16 25.3202 1286.14 23.5988C1287.11 21.8774 1288.56 21.0222 1290.47 21.0222C1292.32 21.0222 1293.75 21.8885 1294.75 23.5988C1295.74 25.3202 1296.23 27.7858 1296.23 30.9954V32.6391C1296.23 37.137 1295.22 39.9135 1293.18 40.9686V41.0907C1295.26 42.1458 1296.3 44.9223 1296.3 49.4202V53.2296C1296.3 56.4725 1295.81 58.9603 1294.82 60.6817C1293.83 62.3809 1292.37 63.2472 1290.44 63.2472Z"
          fill={color}
        />
        <path
          d="M1311.34 62.6586H1308.41L1307.44 53.9182H1301.42L1300.45 62.6586H1297.74L1302.57 21.5886H1306.48L1311.34 62.6586ZM1304.39 26.8084L1301.86 49.92H1306.99L1304.39 26.8084Z"
          fill={color}
        />
        <path
          d="M1316.47 62.6586H1313.57V21.5886H1316.47V39.1361H1322.65V21.5886H1325.56V62.6586H1322.64V43.3008H1316.46V62.6586H1316.47Z"
          fill={color}
        />
        <path
          d="M1331.57 52.2745L1338.15 21.5886H1341.17V62.6586H1338.53V31.7395L1331.95 62.6586H1328.93V21.5886H1331.57V52.2745Z"
          fill={color}
        />
        <path
          d="M1355.94 21.5886V62.6586H1353.03V44.1227H1350.65C1349.42 44.1227 1348.53 44.578 1348.01 45.4665C1347.49 46.3661 1347.23 47.9098 1347.23 50.0977V57.6609C1347.23 59.5822 1347.11 61.237 1346.89 62.6475H1343.92C1344.19 61.3592 1344.33 59.6933 1344.33 57.6609V50.3309C1344.33 45.4443 1345.34 42.59 1347.35 41.7682V41.7127C1345.34 40.6576 1344.33 37.9033 1344.33 33.4387V30.9177C1344.33 27.708 1344.79 25.3536 1345.73 23.8431C1346.66 22.3438 1348.15 21.5886 1350.21 21.5886H1355.94ZM1350.65 39.9579H1353.03V25.7534H1350.28C1349.18 25.7534 1348.41 26.1976 1347.94 27.075C1347.47 27.9524 1347.24 29.3961 1347.24 31.3841V34.3161C1347.24 36.3152 1347.51 37.7478 1348.05 38.6252C1348.59 39.5137 1349.47 39.9579 1350.65 39.9579Z"
          fill={color}
        />
        <path d="M1360.55 69H1359.21L1360.48 62.6585H1359.43V56.739H1362.36V62.1921L1360.55 69Z" fill={color} />
        <path
          d="M1374.68 62.6586H1371.78V21.5886H1374.68V39.1361H1380.86V21.5886H1383.76V62.6586H1380.86V43.3008H1374.68V62.6586Z"
          fill={color}
        />
        <path
          d="M1397.82 62.6586H1387.14V21.5886H1397.82V25.7534H1390.04V39.1361H1396.36V43.3008H1390.04V58.4938H1397.82V62.6586Z"
          fill={color}
        />
        <path
          d="M1405.84 63.2472C1403.93 63.2472 1402.47 62.3698 1401.43 60.604C1400.41 58.8492 1399.89 56.3392 1399.89 53.0963V31.0953C1399.89 27.8524 1400.4 25.3535 1401.43 23.6099C1402.46 21.8663 1403.93 21 1405.84 21C1407.78 21 1409.26 21.8663 1410.28 23.6099C1411.31 25.3535 1411.83 27.8413 1411.83 31.0953V53.0963C1411.83 56.3392 1411.32 58.8492 1410.28 60.604C1409.26 62.3698 1407.78 63.2472 1405.84 63.2472ZM1405.84 59.0824C1407.9 59.0824 1408.93 57.1833 1408.93 53.3962V30.8066C1408.93 27.0528 1407.9 25.1759 1405.84 25.1759C1403.81 25.1759 1402.79 27.0528 1402.79 30.8066V53.3962C1402.8 57.1833 1403.81 59.0824 1405.84 59.0824Z"
          fill={color}
        />
        <path
          d="M1420.87 62.6586H1414.88V21.5886H1425.26V25.7534H1417.79V38.5475H1420.87C1424.79 38.5475 1426.75 41.8682 1426.75 48.5206V52.6854C1426.75 59.3379 1424.79 62.6586 1420.87 62.6586ZM1417.79 42.7122V58.4938H1420.87C1421.92 58.4938 1422.67 58.0718 1423.14 57.2277C1423.61 56.3837 1423.85 54.9732 1423.85 52.9741V48.2208C1423.85 46.2217 1423.61 44.8112 1423.14 43.9672C1422.67 43.1231 1421.92 42.7011 1420.87 42.7011H1417.79V42.7122Z"
          fill={color}
        />
        <path
          d="M1430.73 62.6586H1427.93L1433.07 41.6571L1428.17 21.5886H1431.25L1434.9 36.9593L1438.61 21.5886H1441.41L1436.49 41.6571L1441.63 62.6586H1438.54L1434.64 46.2883L1430.73 62.6586Z"
          fill={color}
        />
        <path
          d="M1448.95 63.2472C1447.04 63.2472 1445.58 62.3698 1444.54 60.604C1443.52 58.8492 1442.99 56.3392 1442.99 53.0963V31.0953C1442.99 27.8524 1443.51 25.3535 1444.54 23.6099C1445.57 21.8663 1447.04 21 1448.95 21C1450.88 21 1452.37 21.8663 1453.39 23.6099C1454.42 25.3535 1454.94 27.8413 1454.94 31.0953V53.0963C1454.94 56.3392 1454.43 58.8492 1453.39 60.604C1452.37 62.3698 1450.89 63.2472 1448.95 63.2472ZM1448.95 59.0824C1451.01 59.0824 1452.03 57.1833 1452.03 53.3962V30.8066C1452.03 27.0528 1451.01 25.1759 1448.95 25.1759C1446.92 25.1759 1445.9 27.0528 1445.9 30.8066V53.3962C1445.91 57.1833 1446.92 59.0824 1448.95 59.0824Z"
          fill={color}
        />
        <path
          d="M1471.12 21.5886V58.4938H1472.98V68.1782H1470.08V62.6586H1459.63V68.1782H1456.73V58.4938H1458.78C1459.37 57.361 1459.71 55.4841 1459.78 52.8631L1460.52 21.5997H1471.12V21.5886ZM1461.64 58.4938H1468.22V25.7534H1463.28L1462.71 52.6854C1462.64 55.1065 1462.28 57.05 1461.64 58.4938Z"
          fill={color}
        />
        <path
          d="M1477.85 52.2745L1484.43 21.5886H1487.45V62.6586H1484.8V31.7395L1478.22 62.6586H1475.21V21.5886H1477.85V52.2745Z"
          fill={color}
        />
        <path
          d="M1493.4 62.6586H1490.84V21.5886H1495.04L1498.87 54.3846L1502.67 21.5886H1506.91V62.6586H1504.11V28.3966L1499.98 62.6586H1497.53L1493.4 28.3966V62.6586Z"
          fill={color}
        />
        <path
          d="M1515.9 63.2472C1513.99 63.2472 1512.52 62.3698 1511.49 60.604C1510.46 58.8492 1509.94 56.3392 1509.94 53.0963V31.0953C1509.94 27.8524 1510.45 25.3535 1511.49 23.6099C1512.51 21.8663 1513.99 21 1515.9 21C1517.83 21 1519.31 21.8663 1520.34 23.6099C1521.36 25.3535 1521.88 27.8413 1521.88 31.0953V53.0963C1521.88 56.3392 1521.37 58.8492 1520.34 60.604C1519.31 62.3698 1517.84 63.2472 1515.9 63.2472ZM1515.9 59.0824C1517.96 59.0824 1518.98 57.1833 1518.98 53.3962V30.8066C1518.98 27.0528 1517.96 25.1759 1515.9 25.1759C1513.87 25.1759 1512.84 27.0528 1512.84 30.8066V53.3962C1512.85 57.1833 1513.87 59.0824 1515.9 59.0824Z"
          fill={color}
        />
        <path
          d="M1536.47 63.2472C1534.56 63.2472 1533.1 62.3698 1532.06 60.604C1531.04 58.8492 1530.51 56.3392 1530.51 53.0963V31.0953C1530.51 27.8524 1531.03 25.3535 1532.06 23.6099C1533.09 21.8663 1534.56 21 1536.47 21C1538.41 21 1539.89 21.8663 1540.91 23.6099C1541.94 25.3535 1542.46 27.8413 1542.46 31.0953V53.0963C1542.46 56.3392 1541.95 58.8492 1540.91 60.604C1539.89 62.3698 1538.4 63.2472 1536.47 63.2472ZM1536.47 59.0824C1538.53 59.0824 1539.56 57.1833 1539.56 53.3962V30.8066C1539.56 27.0528 1538.53 25.1759 1536.47 25.1759C1534.44 25.1759 1533.42 27.0528 1533.42 30.8066V53.3962C1533.42 57.1833 1534.43 59.0824 1536.47 59.0824Z"
          fill={color}
        />
        <path
          d="M1551.49 62.6586H1545.5V21.5886H1555.88V25.7534H1548.41V38.5475H1551.49C1555.41 38.5475 1557.37 41.8682 1557.37 48.5206V52.6854C1557.37 59.3379 1555.41 62.6586 1551.49 62.6586ZM1548.41 42.7122V58.4938H1551.49C1552.53 58.4938 1553.29 58.0718 1553.76 57.2277C1554.23 56.3837 1554.46 54.9732 1554.46 52.9741V48.2208C1554.46 46.2217 1554.23 44.8112 1553.76 43.9672C1553.29 43.1231 1552.53 42.7011 1551.49 42.7011H1548.41V42.7122Z"
          fill={color}
        />
        <path
          d="M1562.8 62.6586H1559.9V21.5886H1565.59C1569.51 21.5886 1571.47 24.9093 1571.47 31.5618V35.0824C1571.47 41.6905 1569.51 45 1565.59 45H1562.8V62.6586ZM1562.8 25.7534V40.8353H1565.59C1566.64 40.8353 1567.39 40.4133 1567.86 39.5692C1568.34 38.7252 1568.57 37.3147 1568.57 35.3156V31.2619C1568.57 29.2629 1568.33 27.8524 1567.86 27.0083C1567.39 26.1643 1566.64 25.7423 1565.59 25.7423H1562.8V25.7534Z"
          fill={color}
        />
        <path
          d="M1585 62.6586H1582.07L1581.1 53.9182H1575.08L1574.11 62.6586H1571.4L1576.23 21.5886H1580.14L1585 62.6586ZM1578.05 26.8084L1575.52 49.92H1580.65L1578.05 26.8084Z"
          fill={color}
        />
        <path d="M1591.58 62.6586H1588.68V25.7534H1584.07V21.5886H1596.19V25.7534H1591.58V62.6586Z" fill={color} />
        <path
          d="M1601.04 52.2745L1607.62 21.5886H1610.63V62.6586H1607.99V31.7395L1601.41 62.6586H1598.39V21.5886H1601.04V52.2745Z"
          fill={color}
        />
        <path d="M1620.37 62.6586H1617.47V25.7534H1612.86V21.5886H1624.99V25.7534H1620.37V62.6586Z" fill={color} />
        <path
          d="M1632.87 62.6586H1627.19V21.5886H1630.09V37.9588H1632.88C1636.8 37.9588 1638.76 41.2795 1638.76 47.932V52.6854C1638.75 59.3379 1636.79 62.6586 1632.87 62.6586ZM1630.08 42.1236V58.4938H1632.87C1633.92 58.4938 1634.67 58.0718 1635.14 57.2277C1635.61 56.3837 1635.85 54.9732 1635.85 52.9741V47.6322C1635.85 45.6331 1635.61 44.2226 1635.14 43.3786C1634.67 42.5345 1633.92 42.1125 1632.87 42.1125H1630.08V42.1236Z"
          fill={color}
        />
        <path
          d="M1646.79 63.2472C1644.95 63.2472 1643.52 62.392 1642.51 60.6706C1641.49 58.9492 1640.98 56.5503 1640.98 53.4517V30.8066C1640.98 27.7191 1641.48 25.3091 1642.49 23.5877C1643.49 21.8663 1644.92 21.0111 1646.79 21.0111C1648.64 21.0111 1650.08 21.8774 1651.08 23.5877C1652.09 25.3091 1652.59 27.7191 1652.59 30.8066V35.0269H1649.84V30.5067C1649.84 28.7853 1649.57 27.4637 1649.04 26.5419C1648.5 25.6201 1647.78 25.1647 1646.86 25.1647C1645.94 25.1647 1645.21 25.6201 1644.68 26.5419C1644.14 27.4637 1643.87 28.7853 1643.87 30.5067V53.7405C1643.87 55.4619 1644.14 56.7835 1644.68 57.7053C1645.21 58.6271 1645.93 59.0824 1646.86 59.0824C1647.77 59.0824 1648.49 58.6271 1649.04 57.7053C1649.57 56.7835 1649.84 55.4619 1649.84 53.7405V47.9876H1652.59V53.4406C1652.59 56.5281 1652.09 58.9381 1651.08 60.6595C1650.07 62.3809 1648.64 63.2472 1646.79 63.2472Z"
          fill={color}
        />
        <path
          d="M1666.68 21.5886V62.6586H1663.77V44.1227H1661.39C1660.16 44.1227 1659.27 44.578 1658.75 45.4665C1658.23 46.3661 1657.97 47.9098 1657.97 50.0977V57.6609C1657.97 59.5822 1657.86 61.237 1657.63 62.6475H1654.66C1654.93 61.3592 1655.07 59.6933 1655.07 57.6609V50.3309C1655.07 45.4443 1656.08 42.59 1658.09 41.7682V41.7127C1656.08 40.6576 1655.07 37.9033 1655.07 33.4387V30.9177C1655.07 27.708 1655.54 25.3536 1656.47 23.8431C1657.4 22.3438 1658.89 21.5886 1660.95 21.5886H1666.68ZM1661.4 39.9579H1663.78V25.7534H1661.03C1659.94 25.7534 1659.16 26.1976 1658.69 27.075C1658.22 27.9524 1657.99 29.3961 1657.99 31.3841V34.3161C1657.99 36.3152 1658.26 37.7478 1658.8 38.6252C1659.34 39.5137 1660.21 39.9579 1661.4 39.9579Z"
          fill={color}
        />
        <path
          d="M1688.7 62.6586H1685.64L1681.29 42.2458L1678.8 49.1093V62.6586H1675.9V21.5886H1678.8V41.8237L1685.61 21.5886H1688.63L1683.23 37.0148L1688.7 62.6586Z"
          fill={color}
        />
        <path
          d="M1702.27 63.2472C1700.43 63.2472 1699 62.392 1697.99 60.6706C1696.97 58.9492 1696.46 56.5503 1696.46 53.4517V30.8066C1696.46 27.7191 1696.96 25.3091 1697.97 23.5877C1698.97 21.8663 1700.4 21.0111 1702.27 21.0111C1704.13 21.0111 1705.56 21.8774 1706.57 23.5877C1707.57 25.3091 1708.08 27.7191 1708.08 30.8066V35.0269H1705.32V30.5067C1705.32 28.7853 1705.05 27.4637 1704.52 26.5419C1703.99 25.6201 1703.26 25.1647 1702.34 25.1647C1701.43 25.1647 1700.69 25.6201 1700.16 26.5419C1699.63 27.4637 1699.36 28.7853 1699.36 30.5067V53.7405C1699.36 55.4619 1699.63 56.7835 1700.16 57.7053C1700.69 58.6271 1701.42 59.0824 1702.34 59.0824C1703.25 59.0824 1703.98 58.6271 1704.52 57.7053C1705.05 56.7835 1705.32 55.4619 1705.32 53.7405V47.9876H1708.08V53.4406C1708.08 56.5281 1707.57 58.9381 1706.57 60.6595C1705.56 62.3809 1704.13 63.2472 1702.27 63.2472Z"
          fill={color}
        />
        <path
          d="M1713.69 62.6586H1710.79V21.5886H1722.76V62.6586H1719.86V25.7534H1713.68V62.6586H1713.69Z"
          fill={color}
        />
        <path
          d="M1736.83 62.6586H1726.15V21.5886H1736.83V25.7534H1729.05V39.1361H1735.37V43.3008H1729.05V58.4938H1736.83V62.6586Z"
          fill={color}
        />
        <path
          d="M1753.37 68.1782H1750.47V62.6586H1739.2V21.5886H1742.1V58.4938H1748.28V21.5886H1751.18V58.4938H1753.37V68.1782Z"
          fill={color}
        />
        <path
          d="M1758.2 52.2745L1764.78 21.5886H1767.8V62.6586H1765.16V31.7395L1758.58 62.6586H1755.56V21.5886H1758.2V52.2745Z"
          fill={color}
        />
        <path
          d="M1783.65 62.6586H1780.72L1779.75 53.9182H1773.73L1772.76 62.6586H1770.05L1774.88 21.5886H1778.79L1783.65 62.6586ZM1776.69 26.8084L1774.16 49.92H1779.29L1776.69 26.8084Z"
          fill={color}
        />
        <path
          d="M1785.77 62.7141C1785.42 62.7141 1785.12 62.6586 1784.84 62.5364V58.4827C1785.19 58.5605 1785.38 58.6049 1785.43 58.6049C1786.34 58.6049 1787.01 58.1717 1787.4 57.3166C1787.79 56.4614 1788 54.9732 1788.03 52.8631L1788.73 21.5997H1799.33V62.6697H1796.43V25.7534H1791.49L1790.93 52.6854C1790.86 56.1616 1790.43 58.7048 1789.65 60.3152C1788.87 61.9145 1787.58 62.7141 1785.77 62.7141Z"
          fill={color}
        />
        <path
          d="M1805.37 52.2745L1811.95 21.5886H1814.96V62.6586H1812.32V31.7395L1805.74 62.6586H1802.73V21.5886H1805.37V52.2745Z"
          fill={color}
        />
        <path
          d="M1823.85 63.2472C1822.02 63.2472 1820.59 62.392 1819.58 60.6706C1818.56 58.9492 1818.05 56.5503 1818.05 53.4517V30.8066C1818.05 27.7191 1818.55 25.3091 1819.56 23.5877C1820.56 21.8663 1821.99 21.0111 1823.85 21.0111C1825.71 21.0111 1827.15 21.8774 1828.15 23.5877C1829.16 25.3091 1829.66 27.7191 1829.66 30.8066V35.0269H1826.91V30.5067C1826.91 28.7853 1826.64 27.4637 1826.11 26.5419C1825.57 25.6201 1824.85 25.1647 1823.93 25.1647C1823 25.1647 1822.28 25.6201 1821.75 26.5419C1821.21 27.4637 1820.94 28.7853 1820.94 30.5067V53.7405C1820.94 55.4619 1821.2 56.7835 1821.75 57.7053C1822.28 58.6271 1823 59.0824 1823.93 59.0824C1824.85 59.0824 1825.56 58.6271 1826.11 57.7053C1826.64 56.7835 1826.91 55.4619 1826.91 53.7405V47.9876H1829.66V53.4406C1829.66 56.5281 1829.16 58.9381 1828.15 60.6595C1827.14 62.3809 1825.71 63.2472 1823.85 63.2472Z"
          fill={color}
        />
        <path d="M1838.73 62.6586H1835.83V25.7534H1831.22V21.5886H1843.34V25.7534H1838.73V62.6586Z" fill={color} />
        <path
          d="M1851.45 45.6442L1854.98 21.5886H1857.73L1852.34 56.4947C1851.96 58.9158 1851.39 60.6484 1850.63 61.6812C1849.85 62.7141 1848.77 63.2361 1847.35 63.2361C1846.58 63.2361 1845.91 63.0251 1845.3 62.5919V58.5382C1845.85 58.8936 1846.41 59.0713 1846.98 59.0713C1847.72 59.0713 1848.29 58.8381 1848.66 58.3717C1849.03 57.9052 1849.31 57.0834 1849.51 55.9061L1849.92 53.2629L1844.49 21.5886H1847.46L1851.45 45.6442Z"
          fill={color}
        />
        <path d="M1860 62.6585H1857.07V56.739H1860V62.6585Z" fill={color} />
      </svg>
    </Box>
    <Box hideAfter={EnumScreens.lg}>
      <svg width="100%" height="100%" viewBox="0 0 375 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.2" width="375" height="80" fill={colors.dark} />
        <path d="M16.0687 37.9869H15V8.42389H19.4079V37.9869H18.3392V11.4218H16.065V37.9869H16.0687Z" fill={color} />
        <path
          d="M21.7227 37.9869H20.6541V8.42389H22.747C24.1892 8.42389 24.9103 10.8142 24.9103 15.6028V18.137C24.9103 22.8936 24.1892 25.2759 22.747 25.2759H21.719V37.9869H21.7227ZM21.7227 11.4218V22.278H22.7507C23.1353 22.278 23.4127 21.9743 23.5865 21.3667C23.7603 20.7591 23.8453 19.7438 23.8453 18.3049V15.3869C23.8453 13.948 23.7603 12.9327 23.5865 12.3251C23.4127 11.7175 23.1353 11.4138 22.7507 11.4138H21.7227V11.4218Z"
          fill={color}
        />
        <path
          d="M29.7619 37.9869H25.8311V8.42389H29.7619V11.4218H26.8997V21.0549H29.2257V24.0528H26.8997V34.989H29.7619V37.9869Z"
          fill={color}
        />
        <path
          d="M35.5083 8.42389V34.989H36.1924V41.96H35.1237V37.9869H31.2779V41.96H30.2092V34.989H30.9636C31.1818 34.1736 31.3075 32.8225 31.3334 30.9359L31.607 8.42389H35.5083ZM32.0175 34.989H34.4396V11.4218H32.6202L32.4169 30.808C32.3873 32.5507 32.2541 33.9497 32.0175 34.989Z"
          fill={color}
        />
        <path
          d="M39.0952 38.4104C38.3926 38.4104 37.8527 37.7788 37.4718 36.5077C37.0946 35.2446 36.9023 33.4379 36.9023 31.1036V15.2668C36.9023 12.9325 37.0909 11.1338 37.4718 9.87866C37.849 8.62356 38.3926 8 39.0952 8C39.8089 8 40.3525 8.62356 40.7296 9.87866C41.1068 11.1338 41.2991 12.9245 41.2991 15.2668V31.1036C41.2991 33.4379 41.1105 35.2446 40.7296 36.5077C40.3525 37.7788 39.8089 38.4104 39.0952 38.4104ZM39.0952 35.4125C39.8532 35.4125 40.2304 34.0455 40.2304 31.3194V15.059C40.2304 12.3569 39.8532 11.0059 39.0952 11.0059C38.3482 11.0059 37.971 12.3569 37.971 15.059V31.3194C37.9747 34.0455 38.3482 35.4125 39.0952 35.4125Z"
          fill={color}
        />
        <path
          d="M44.4499 38.4104C43.7732 38.4104 43.2481 37.7949 42.8746 36.5558C42.5011 35.3166 42.3125 33.5899 42.3125 31.3594V15.059C42.3125 12.8366 42.4974 11.1019 42.8672 9.86274C43.237 8.62362 43.7658 8.00806 44.4499 8.00806C45.134 8.00806 45.6628 8.63161 46.0326 9.86274C46.4023 11.1019 46.5872 12.8366 46.5872 15.059V18.0969H45.574V14.8432C45.574 13.6041 45.4742 12.6528 45.2782 11.9892C45.0822 11.3257 44.816 10.9979 44.4757 10.9979C44.1392 10.9979 43.8693 11.3257 43.6733 11.9892C43.4773 12.6528 43.3775 13.6041 43.3775 14.8432V31.5673C43.3775 32.8064 43.4773 33.7577 43.6733 34.4213C43.8693 35.0848 44.1355 35.4126 44.4757 35.4126C44.8123 35.4126 45.0785 35.0848 45.2782 34.4213C45.4742 33.7577 45.574 32.8064 45.574 31.5673V27.4262H46.5872V31.3515C46.5872 33.5739 46.4023 35.3086 46.0326 36.5478C45.6628 37.7869 45.134 38.4104 44.4499 38.4104Z"
          fill={color}
        />
        <path d="M49.9264 37.9869H48.8577V11.4218H47.1604V8.42389H51.6237V11.4218H49.9264V37.9869Z" fill={color} />
        <path
          d="M56.2794 37.9869H55.1996L54.8446 31.6953H52.6259L52.2709 37.9869H51.2725L53.0474 8.42389H54.4859L56.2794 37.9869ZM53.7168 12.1812L52.7849 28.8174H54.6745L53.7168 12.1812Z"
          fill={color}
        />
        <path
          d="M59.345 37.9869H57.1003V8.42389H59.2377C59.9958 8.42389 60.5468 8.9675 60.887 10.0467C61.2309 11.134 61.401 12.8288 61.401 15.1391V16.3622C61.401 19.512 61.046 21.4866 60.3323 22.278V22.366C61.1458 23.1014 61.5526 25.164 61.5526 28.5776V31.1118C61.5489 35.6925 60.813 37.9869 59.345 37.9869ZM58.169 11.4218V21.0549H59.0713C59.5077 21.0549 59.8294 20.7351 60.0291 20.1036C60.2287 19.472 60.3286 18.4328 60.3286 17.0018V15.4829C60.3286 14.0439 60.2435 13.0126 60.0697 12.3811C59.8959 11.7495 59.6075 11.4298 59.2081 11.4298H58.169V11.4218ZM58.169 24.0528V34.989H59.345C59.7369 34.989 60.0254 34.6772 60.2066 34.0617C60.3877 33.4461 60.4802 32.4308 60.4802 31.0238V28.4896C60.4802 26.8828 60.3767 25.7476 60.1733 25.0681C59.9699 24.3885 59.6186 24.0528 59.1268 24.0528H58.169Z"
          fill={color}
        />
        <path
          d="M62.4511 38.0266C62.3217 38.0266 62.2107 37.9866 62.1072 37.8987V34.9808C62.2366 35.0367 62.3069 35.0687 62.3254 35.0687C62.6619 35.0687 62.9059 34.7569 63.0501 34.1414C63.1944 33.5258 63.272 32.4546 63.2831 30.9356L63.5419 8.43164H67.4432V37.9946H66.3745V11.4215H64.5552L64.3555 30.8077C64.3296 33.3099 64.1706 35.1406 63.8822 36.2998C63.5974 37.451 63.1204 38.0266 62.4511 38.0266Z"
          fill={color}
        />
        <path
          d="M72.8865 8.42389V37.9869H71.8178V24.6444H70.9414C70.4866 24.6444 70.1612 24.9721 69.9689 25.6117C69.7766 26.2592 69.6805 27.3704 69.6805 28.9453V34.3894C69.6805 35.7724 69.6398 36.9636 69.5584 37.9789H68.4639C68.5637 37.0515 68.6155 35.8524 68.6155 34.3894V29.1132C68.6155 25.5957 68.9853 23.5411 69.7248 22.9496V22.9096C68.9853 22.1501 68.6155 20.1675 68.6155 16.9538V15.1391C68.6155 12.8288 68.7856 11.134 69.1295 10.0467C69.4734 8.9675 70.0207 8.42389 70.7787 8.42389H72.8865ZM70.9414 21.6465H71.8178V11.4218H70.8046C70.4015 11.4218 70.1168 11.7415 69.943 12.3731C69.7692 13.0046 69.6842 14.0439 69.6842 15.4749V17.5854C69.6842 19.0243 69.784 20.0556 69.9837 20.6872C70.1834 21.3267 70.5014 21.6465 70.9414 21.6465Z"
          fill={color}
        />
        <path
          d="M78.0448 37.9869H74.1177V8.42389H78.0485V11.4218H75.1864V21.0549H77.5123V24.0528H75.1864V34.989H78.0485V37.9869H78.0448Z"
          fill={color}
        />
        <path
          d="M79.868 37.9869H78.925V8.42389H80.4708L81.8796 32.0311L83.2737 8.42389H84.8342V37.9869H83.8062V13.3244L82.2864 37.9869H81.3841L79.8643 13.3244V37.9869H79.868Z"
          fill={color}
        />
        <path
          d="M90.6546 37.9869H89.5748L89.2198 31.6953H87.0011L86.6461 37.9869H85.6477L87.4227 8.42389H88.8612L90.6546 37.9869ZM88.0957 12.1812L87.1638 28.8174H89.0534L88.0957 12.1812Z"
          fill={color}
        />
        <path
          d="M95.6653 8.42389V37.9869H94.5966V24.6444H93.7203C93.2654 24.6444 92.94 24.9721 92.7477 25.6117C92.5554 26.2592 92.4593 27.3704 92.4593 28.9453V34.3894C92.4593 35.7724 92.4186 36.9636 92.3372 37.9789H91.2427C91.3425 37.0515 91.3943 35.8524 91.3943 34.3894V29.1132C91.3943 25.5957 91.7641 23.5411 92.5037 22.9496V22.9096C91.7641 22.1501 91.3943 20.1675 91.3943 16.9538V15.1391C91.3943 12.8288 91.5644 11.134 91.9083 10.0467C92.2522 8.9675 92.7995 8.42389 93.5575 8.42389H95.6653ZM93.7203 21.6465H94.5966V11.4218H93.5834C93.1804 11.4218 92.8956 11.7415 92.7218 12.3731C92.548 13.0046 92.463 14.0439 92.463 15.4749V17.5854C92.463 19.0243 92.5628 20.0556 92.7625 20.6872C92.9622 21.3267 93.2839 21.6465 93.7203 21.6465Z"
          fill={color}
        />
        <path
          d="M100.033 30.5122L102.455 8.42389H103.564V37.9869H102.592V15.7307L100.169 37.9869H99.0601V8.42389H100.033V30.5122Z"
          fill={color}
        />
        <path
          d="M105.879 37.9869H104.81V8.42389H105.879V21.0549H108.153V8.42389H109.222V37.9869H108.153V24.0528H105.879V37.9869Z"
          fill={color}
        />
        <path
          d="M114.106 37.9869H113.038V34.6532H112.601C111.825 34.6532 111.233 33.9897 110.83 32.6706C110.423 31.3516 110.22 29.409 110.22 26.8428V19.576C110.22 14.3717 111.015 11.7655 112.601 11.7655H113.038V8.42389H114.106V11.7575H114.543C116.122 11.7575 116.909 14.3637 116.909 19.568V26.8348C116.909 29.393 116.71 31.3356 116.307 32.6627C115.904 33.9897 115.316 34.6452 114.539 34.6452H114.103V37.9869H114.106ZM112.601 31.6074H113.038V14.7634H112.601C111.725 14.7634 111.289 16.2983 111.289 19.3681V27.0506C111.285 30.0885 111.725 31.6074 112.601 31.6074ZM114.106 14.7634V31.6074H114.543C115.408 31.6074 115.844 30.0885 115.844 27.0506V19.3681C115.844 16.2983 115.412 14.7634 114.543 14.7634H114.106Z"
          fill={color}
        />
        <path
          d="M119.994 38.4104C119.291 38.4104 118.751 37.7788 118.37 36.5077C117.993 35.2446 117.801 33.4379 117.801 31.1036V15.2668C117.801 12.9325 117.989 11.1338 118.37 9.87866C118.747 8.62356 119.291 8 119.994 8C120.707 8 121.251 8.62356 121.628 9.87866C122.005 11.1338 122.198 12.9245 122.198 15.2668V31.1036C122.198 33.4379 122.009 35.2446 121.628 36.5077C121.251 37.7788 120.704 38.4104 119.994 38.4104ZM119.994 35.4125C120.752 35.4125 121.129 34.0455 121.129 31.3194V15.059C121.129 12.3569 120.752 11.0059 119.994 11.0059C119.247 11.0059 118.869 12.3569 118.869 15.059V31.3194C118.869 34.0455 119.243 35.4125 119.994 35.4125Z"
          fill={color}
        />
        <path
          d="M124.387 37.9869H123.318V8.42389H125.411C126.853 8.42389 127.574 10.8142 127.574 15.6028V18.137C127.574 22.8936 126.853 25.2759 125.411 25.2759H124.383V37.9869H124.387ZM124.387 11.4218V22.278H125.415C125.799 22.278 126.076 21.9743 126.25 21.3667C126.424 20.7591 126.509 19.7438 126.509 18.3049V15.3869C126.509 13.948 126.424 12.9327 126.25 12.3251C126.076 11.7175 125.799 11.4138 125.415 11.4138H124.387V11.4218Z"
          fill={color}
        />
        <path
          d="M129.438 37.9869H128.495V8.42389H130.041L131.449 32.0311L132.847 8.42389H134.408V37.9869H133.38V13.3244L131.86 37.9869H130.958L129.438 13.3244V37.9869Z"
          fill={color}
        />
        <path
          d="M140.228 37.9869H139.149L138.794 31.6953H136.575L136.22 37.9869H135.221L136.996 8.42389H138.435L140.228 37.9869ZM137.666 12.1812L136.734 28.8174H138.623L137.666 12.1812Z"
          fill={color}
        />
        <path
          d="M146.263 41.96H145.194V37.9869H141.049V8.42389H142.118V34.989H144.392V8.42389H145.461V34.989H146.267V41.96H146.263Z"
          fill={color}
        />
        <path
          d="M148.046 30.5122L150.468 8.42389H151.577V37.9869H150.604V15.7307L148.182 37.9869H147.073V8.42389H148.046V30.5122Z"
          fill={color}
        />
        <path
          d="M157.013 8.42389V37.9869H155.944V24.6444H155.068C154.613 24.6444 154.288 24.9721 154.095 25.6117C153.903 26.2592 153.807 27.3704 153.807 28.9453V34.3894C153.807 35.7724 153.766 36.9636 153.685 37.9789H152.59C152.69 37.0515 152.742 35.8524 152.742 34.3894V29.1132C152.742 25.5957 153.112 23.5411 153.851 22.9496V22.9096C153.112 22.1501 152.742 20.1675 152.742 16.9538V15.1391C152.742 12.8288 152.912 11.134 153.256 10.0467C153.6 8.9675 154.147 8.42389 154.905 8.42389H157.013ZM155.068 21.6465H155.944V11.4218H154.931C154.528 11.4218 154.243 11.7415 154.069 12.3731C153.896 13.0046 153.811 14.0439 153.811 15.4749V17.5854C153.811 19.0243 153.91 20.0556 154.11 20.6872C154.31 21.3267 154.632 21.6465 155.068 21.6465Z"
          fill={color}
        />
        <path
          d="M161.476 37.9869H160.407V8.42389H161.476V21.0549H163.75V8.42389H164.819V37.9869H163.75V24.0528H161.476V37.9869Z"
          fill={color}
        />
        <path
          d="M169.989 37.9869H166.058V8.42389H169.989V11.4218H167.127V21.0549H169.453V24.0528H167.127V34.989H169.989V37.9869Z"
          fill={color}
        />
        <path
          d="M174.903 38.4106C174.182 38.4106 173.638 37.787 173.272 36.5319C172.906 35.2768 172.725 33.502 172.725 31.1917V28.9533H173.738V31.3196C173.738 34.0537 174.115 35.4127 174.874 35.4127C175.621 35.4127 175.998 33.9497 175.998 31.0238V28.4496C175.998 25.5237 175.595 24.0608 174.792 24.0608H174.215V21.0549H174.818C175.584 21.0549 175.968 19.7198 175.968 17.0418V15.0991C175.968 12.3651 175.602 11.006 174.874 11.006C174.152 11.006 173.794 12.3331 173.794 14.9792V16.714H172.781V15.235C172.781 12.9007 172.962 11.11 173.32 9.87085C173.683 8.63174 174.212 8.01617 174.914 8.01617C175.598 8.01617 176.123 8.63973 176.49 9.87085C176.856 11.11 177.037 12.8847 177.037 15.1951V16.3782C177.037 19.6159 176.663 21.6145 175.913 22.374V22.4619C176.678 23.2214 177.063 25.2199 177.063 28.4576V31.1997C177.063 33.534 176.882 35.3248 176.515 36.5639C176.157 37.787 175.617 38.4106 174.903 38.4106Z"
          fill={color}
        />
        <path
          d="M182.598 37.9869H181.519L181.164 31.6953H178.945L178.59 37.9869H177.592L179.367 8.42389H180.805L182.598 37.9869ZM180.04 12.1812L179.108 28.8174H180.997L180.04 12.1812Z"
          fill={color}
        />
        <path
          d="M184.366 37.9869H183.423V8.42389H184.969L186.378 32.0311L187.775 8.42389H189.336V37.9869H188.308V13.3244L186.788 37.9869H185.886L184.366 13.3244V37.9869Z"
          fill={color}
        />
        <path
          d="M194.495 37.9869H190.564V8.42389H194.495V11.4218H191.632V21.0549H193.958V24.0528H191.632V34.989H194.495V37.9869Z"
          fill={color}
        />
        <path
          d="M196.44 37.9869H195.371V8.42389H196.44V21.0549H198.714V8.42389H199.782V37.9869H198.714V24.0528H196.44V37.9869Z"
          fill={color}
        />
        <path
          d="M205.215 8.42389V37.9869H204.146V24.6444H203.27C202.815 24.6444 202.489 24.9721 202.297 25.6117C202.105 26.2592 202.009 27.3704 202.009 28.9453V34.3894C202.009 35.7724 201.968 36.9636 201.887 37.9789H200.792C200.892 37.0515 200.944 35.8524 200.944 34.3894V29.1132C200.944 25.5957 201.313 23.5411 202.053 22.9496V22.9096C201.313 22.1501 200.944 20.1675 200.944 16.9538V15.1391C200.944 12.8288 201.114 11.134 201.458 10.0467C201.802 8.9675 202.349 8.42389 203.107 8.42389H205.215ZM203.273 21.6465H204.15V11.4218H203.136C202.733 11.4218 202.449 11.7415 202.275 12.3731C202.101 13.0046 202.016 14.0439 202.016 15.4749V17.5854C202.016 19.0243 202.116 20.0556 202.316 20.6872C202.515 21.3187 202.833 21.6465 203.273 21.6465Z"
          fill={color}
        />
        <path
          d="M210.377 37.9869H206.446V8.42389H210.377V11.4218H207.515V21.0549H209.841V24.0528H207.515V34.989H210.377V37.9869Z"
          fill={color}
        />
        <path d="M213.594 37.9869H212.525V11.4218H210.828V8.42389H215.291V11.4218H213.594V37.9869Z" fill={color} />
        <path
          d="M222.972 37.9869H221.848L220.246 23.2933L219.329 28.2338V37.9869H218.261V8.42389H219.329V22.9895L221.837 8.42389H222.946L220.96 19.536L222.972 37.9869Z"
          fill={color}
        />
        <path
          d="M225.667 38.4104C224.965 38.4104 224.425 37.7788 224.044 36.5077C223.667 35.2446 223.475 33.4379 223.475 31.1036V15.2668C223.475 12.9325 223.663 11.1338 224.044 9.87866C224.421 8.62356 224.965 8 225.667 8C226.381 8 226.925 8.62356 227.302 9.87866C227.679 11.1338 227.871 12.9245 227.871 15.2668V31.1036C227.871 33.4379 227.683 35.2446 227.302 36.5077C226.925 37.7788 226.381 38.4104 225.667 38.4104ZM225.667 35.4125C226.426 35.4125 226.803 34.0455 226.803 31.3194V15.059C226.803 12.3569 226.426 11.0059 225.667 11.0059C224.92 11.0059 224.543 12.3569 224.543 15.059V31.3194C224.547 34.0455 224.92 35.4125 225.667 35.4125Z"
          fill={color}
        />
        <path
          d="M230.064 37.9869H228.996V8.42389H230.064V21.0549H232.338V8.42389H233.407V37.9869H232.338V24.0528H230.064V37.9869Z"
          fill={color}
        />
        <path
          d="M236.676 38.4104C235.999 38.4104 235.474 37.7949 235.101 36.5558C234.727 35.3166 234.539 33.5899 234.539 31.3594V15.059C234.539 12.8366 234.724 11.1019 235.093 9.86274C235.463 8.62362 235.988 8.00806 236.676 8.00806C237.36 8.00806 237.889 8.63161 238.259 9.86274C238.629 11.1019 238.814 12.8366 238.814 15.059V18.0969H237.8V14.8432C237.8 13.6041 237.701 12.6528 237.505 11.9892C237.309 11.3257 237.042 10.9979 236.702 10.9979C236.366 10.9979 236.096 11.3257 235.9 11.9892C235.704 12.6528 235.604 13.6041 235.604 14.8432V31.5673C235.604 32.8064 235.704 33.7577 235.9 34.4213C236.096 35.0848 236.362 35.4126 236.702 35.4126C237.039 35.4126 237.305 35.0848 237.505 34.4213C237.701 33.7577 237.8 32.8064 237.8 31.5673V27.4262H238.814V31.3515C238.814 33.5739 238.629 35.3086 238.259 36.5478C237.889 37.7869 237.36 38.4104 236.676 38.4104Z"
          fill={color}
        />
        <path
          d="M241.986 25.7396L243.288 8.42389H244.301L242.315 33.55C242.178 35.2928 241.968 36.5399 241.687 37.2834C241.402 38.0268 241.003 38.4026 240.481 38.4026C240.196 38.4026 239.949 38.2507 239.727 37.9389V35.021C239.926 35.2768 240.134 35.4047 240.344 35.4047C240.618 35.4047 240.825 35.2368 240.962 34.9011C241.099 34.5653 241.202 33.9737 241.276 33.1263L241.428 31.2237L239.427 8.42389H240.522L241.986 25.7396Z"
          fill={color}
        />
        <path
          d="M244.235 38.0268C244.105 38.0268 243.994 37.9869 243.891 37.8989V34.981C244.02 35.037 244.09 35.0689 244.109 35.0689C244.445 35.0689 244.689 34.7572 244.834 34.1416C244.978 33.526 245.055 32.4548 245.067 30.9359L245.329 8.42389H249.23V37.9869H248.162V11.4218H246.342L246.135 30.808C246.109 33.3102 245.95 35.1409 245.662 36.3001C245.377 37.4512 244.9 38.0268 244.235 38.0268Z"
          fill={color}
        />
        <path
          d="M252.57 37.9869H250.477V8.42389H251.545V20.2075H252.573C254.015 20.2075 254.737 22.5978 254.737 27.3864V30.808C254.733 35.5966 254.012 37.9869 252.57 37.9869ZM251.545 23.2054V34.989H252.573C252.958 34.989 253.235 34.6852 253.409 34.0776C253.583 33.4701 253.668 32.4548 253.668 31.0158V27.1706C253.668 25.7316 253.583 24.7163 253.409 24.1087C253.235 23.5012 252.958 23.1974 252.573 23.1974H251.545V23.2054Z"
          fill={color}
        />
        <path d="M257.033 37.9869H255.964V11.4218H254.267V8.42389H258.73V11.4218H257.033V37.9869Z" fill={color} />
        <path
          d="M263.386 37.9869H262.306L261.951 31.6953H259.732L259.377 37.9869H258.379L260.154 8.42389H261.592L263.386 37.9869ZM260.827 12.1812L259.895 28.8174H261.785L260.827 12.1812Z"
          fill={color}
        />
        <path
          d="M269.424 41.96H268.356V37.9869H264.207V8.42389H265.275V34.989H267.549V8.42389H268.618V34.989H269.424V41.96Z"
          fill={color}
        />
        <path
          d="M271.203 30.5122L273.625 8.42389H274.734V37.9869H273.762V15.7307L271.34 37.9869H270.23V8.42389H271.203V30.5122Z"
          fill={color}
        />
        <path
          d="M280.525 8.00806C281.235 8.00806 281.783 8.63161 282.16 9.88672C282.537 11.1418 282.729 12.9326 282.729 15.2749V31.1116C282.729 33.446 282.541 35.2527 282.16 36.5158C281.783 37.7789 281.235 38.4184 280.525 38.4184C279.823 38.4184 279.283 37.7869 278.902 36.5158C278.525 35.2527 278.333 33.446 278.333 31.1116V24.0606H277.046V37.9947H275.977V8.42376H277.046V21.0548H278.333V15.2669C278.333 12.9326 278.521 11.1338 278.902 9.87873C279.283 8.63161 279.823 8.00806 280.525 8.00806ZM281.664 31.3115V15.059C281.664 12.357 281.287 11.0059 280.529 11.0059C279.782 11.0059 279.405 12.357 279.405 15.059V31.3195C279.405 34.0535 279.778 35.4126 280.529 35.4126C281.283 35.4126 281.664 34.0455 281.664 31.3115Z"
          fill={color}
        />
        <path
          d="M285.935 38.0266C285.806 38.0266 285.695 37.9866 285.592 37.8987V34.9808C285.721 35.0367 285.791 35.0687 285.81 35.0687C286.146 35.0687 286.39 34.7569 286.535 34.1414C286.679 33.5258 286.756 32.4546 286.767 30.9356L287.026 8.43164H290.928V37.9946H289.859V11.4215H288.04L287.836 30.8077C287.81 33.3099 287.651 35.1406 287.363 36.2998C287.078 37.451 286.601 38.0266 285.935 38.0266Z"
          fill={color}
        />
        <path
          d="M296.105 37.9869H292.174V8.42389H296.105V11.4218H293.243V21.0549H295.568V24.0528H293.243V34.989H296.105V37.9869Z"
          fill={color}
        />
        <path
          d="M300.143 19.488V8.42389H301.211V37.9869H300.143V24.8922C299.777 26.3551 299.259 27.0906 298.582 27.0906C297.358 27.0906 296.748 25.0361 296.748 20.927V8.42389H297.817V20.4553C297.817 22.8776 298.161 24.0848 298.845 24.0848C299.237 24.0848 299.551 23.685 299.788 22.8776C300.024 22.0862 300.143 20.959 300.143 19.488Z"
          fill={color}
        />
        <path
          d="M307.032 37.9869H305.952L305.597 31.6953H303.378L303.023 37.9869H302.025L303.8 8.42389H305.238L307.032 37.9869ZM304.469 12.1812L303.537 28.8174H305.427L304.469 12.1812Z"
          fill={color}
        />
        <path
          d="M314.601 8.42389V34.989H315.396V42H314.328V37.9869H307.853V8.42389H308.921V34.989H310.689V8.42389H311.757V34.989H313.536V8.42389H314.601Z"
          fill={color}
        />
        <path
          d="M320.144 37.9869H316.217V8.42389H320.144V11.4218H317.282V21.0549H319.608V24.0528H317.282V34.989H320.144V37.9869Z"
          fill={color}
        />
        <path d="M322.089 37.9869H321.021V8.42389H324.689V11.4218H322.089V37.9869Z" fill={color} />
        <path
          d="M327.499 38.4104C326.797 38.4104 326.257 37.7788 325.876 36.5077C325.499 35.2446 325.307 33.4379 325.307 31.1036V15.2668C325.307 12.9325 325.495 11.1338 325.876 9.87866C326.253 8.62356 326.797 8 327.499 8C328.209 8 328.757 8.62356 329.134 9.87866C329.511 11.1338 329.703 12.9245 329.703 15.2668V31.1036C329.703 33.4379 329.515 35.2446 329.134 36.5077C328.753 37.7788 328.209 38.4104 327.499 38.4104ZM327.499 35.4125C328.258 35.4125 328.635 34.0455 328.635 31.3194V15.059C328.635 12.3569 328.258 11.0059 327.499 11.0059C326.753 11.0059 326.375 12.3569 326.375 15.059V31.3194C326.375 34.0455 326.749 35.4125 327.499 35.4125Z"
          fill={color}
        />
        <path
          d="M335.232 37.9869H332.987V8.42389H335.124C335.883 8.42389 336.433 8.9675 336.774 10.0467C337.118 11.134 337.288 12.8288 337.288 15.1391V16.3622C337.288 19.512 336.933 21.4866 336.219 22.278V22.366C337.033 23.1014 337.436 25.164 337.436 28.5776V31.1118C337.436 35.6925 336.703 37.9869 335.232 37.9869ZM334.056 11.4218V21.0549H334.958C335.398 21.0549 335.716 20.7351 335.916 20.1036C336.115 19.472 336.215 18.4328 336.215 17.0018V15.4829C336.215 14.0439 336.13 13.0126 335.956 12.3811C335.783 11.7495 335.494 11.4298 335.095 11.4298H334.056V11.4218ZM334.056 24.0528V34.989H335.232C335.624 34.989 335.912 34.6772 336.093 34.0617C336.274 33.4461 336.367 32.4308 336.367 31.0238V28.4896C336.367 26.8828 336.263 25.7476 336.06 25.0681C335.853 24.3885 335.505 24.0528 335.014 24.0528H334.056Z"
          fill={color}
        />
        <path
          d="M339.492 37.9869H338.423V8.42389H340.516C341.958 8.42389 342.679 10.8142 342.679 15.6028V18.137C342.679 22.8936 341.958 25.2759 340.516 25.2759H339.488V37.9869H339.492ZM339.492 11.4218V22.278H340.52C340.904 22.278 341.181 21.9743 341.355 21.3667C341.529 20.7591 341.614 19.7438 341.614 18.3049V15.3869C341.614 13.948 341.529 12.9327 341.355 12.3251C341.181 11.7175 340.904 11.4138 340.52 11.4138H339.492V11.4218Z"
          fill={color}
        />
        <path
          d="M347.664 37.9869H346.584L346.229 31.6953H344.01L343.655 37.9869H342.657L344.432 8.42389H345.87L347.664 37.9869ZM345.105 12.1812L344.173 28.8174H346.063L345.105 12.1812Z"
          fill={color}
        />
        <path
          d="M351.155 19.488V8.42389H352.223V37.9869H351.155V24.8922C350.789 26.3551 350.271 27.0906 349.594 27.0906C348.37 27.0906 347.76 25.0361 347.76 20.927V8.42389H348.829V20.4553C348.829 22.8776 349.173 24.0848 349.857 24.0848C350.249 24.0848 350.563 23.685 350.8 22.8776C351.036 22.0862 351.155 20.959 351.155 19.488Z"
          fill={color}
        />
        <path
          d="M358.04 37.9869H356.96L356.605 31.6953H354.387L354.032 37.9869H353.033L354.808 8.42389H356.247L358.04 37.9869ZM355.481 12.1812L354.549 28.8174H356.439L355.481 12.1812Z"
          fill={color}
        />
        <path d="M360 37.9864H358.92V33.7255H360V37.9864Z" fill={color} />
        <path
          d="M16.0682 65.153L18.7285 43.4171H19.9469V72.5085H18.8787V50.6073L16.2185 72.5085H15V43.4171H16.0682V65.153Z"
          fill={color}
        />
        <path
          d="M22.3552 72.5085H21.3196V43.4171H23.0173L24.5647 66.6477L26.1 43.4171H27.8139V72.5085H26.6848V48.2394L25.0155 72.5085H24.0246L22.3552 48.2394V72.5085Z"
          fill={color}
        />
        <path
          d="M33.4797 72.5085H29.1624V43.4171H33.4797V46.3672H30.3361V55.8466H32.8908V58.7967H30.3361V69.5584H33.4797V72.5085Z"
          fill={color}
        />
        <path
          d="M39.4339 43.0079C40.2178 43.0079 40.8148 43.6215 41.2291 44.8566C41.6434 46.0917 41.8545 47.8539 41.8545 50.1588V65.7429C41.8545 68.04 41.6474 69.8179 41.2291 71.0609C40.8148 72.3038 40.2137 72.9332 39.4339 72.9332C38.6622 72.9332 38.0692 72.3117 37.6509 71.0609C37.2366 69.8179 37.0254 68.04 37.0254 65.7429V58.8044H35.612V72.5162H34.4382V43.417H35.612V55.8465H37.0254V50.151C37.0254 47.8539 37.2326 46.0838 37.6509 44.8488C38.0692 43.6215 38.6622 43.0079 39.4339 43.0079ZM40.6848 65.9396V49.9464C40.6848 47.2875 40.2706 45.958 39.438 45.958C38.6175 45.958 38.2033 47.2875 38.2033 49.9464V65.9475C38.2033 68.6379 38.6135 69.9752 39.438 69.9752C40.2665 69.9752 40.6848 68.63 40.6848 65.9396Z"
          fill={color}
        />
        <path d="M45.571 72.5085H44.3972V46.3672H42.533V43.4171H47.4352V46.3672H45.571V72.5085Z" fill={color} />
        <path
          d="M50.4569 72.9253C49.7137 72.9253 49.1369 72.3195 48.7267 71.1002C48.3165 69.8808 48.1094 68.1816 48.1094 65.9868V49.9464C48.1094 47.7595 48.3125 46.0524 48.7186 44.833C49.1248 43.6137 49.7015 43.0079 50.4569 43.0079C51.2083 43.0079 51.7891 43.6215 52.1953 44.833C52.6014 46.0524 52.8045 47.7595 52.8045 49.9464V52.9358H51.6916V49.734C51.6916 48.5147 51.582 47.5785 51.3667 46.9256C51.1514 46.2726 50.859 45.9501 50.4853 45.9501C50.1157 45.9501 49.8193 46.2726 49.604 46.9256C49.3888 47.5785 49.2791 48.5147 49.2791 49.734V66.1913C49.2791 67.4107 49.3888 68.3468 49.604 68.9998C49.8193 69.6527 50.1117 69.9752 50.4853 69.9752C50.8549 69.9752 51.1474 69.6527 51.3667 68.9998C51.582 68.3468 51.6916 67.4107 51.6916 66.1913V62.1163H52.8045V65.9789C52.8045 68.1659 52.6014 69.873 52.1953 71.0923C51.7891 72.3117 51.2083 72.9253 50.4569 72.9253Z"
          fill={color}
        />
        <path
          d="M58.5026 43.4171V72.5085H57.3288V59.3788H56.3663C55.8667 59.3788 55.5093 59.7013 55.2981 60.3307C55.0869 60.9679 54.9813 62.0614 54.9813 63.6111V68.9684C54.9813 70.3294 54.9366 71.5015 54.8472 72.5006H53.645C53.7547 71.588 53.8116 70.408 53.8116 68.9684V63.7763C53.8116 60.315 54.2177 58.2932 55.03 57.711V57.6717C54.2177 56.9244 53.8116 54.9734 53.8116 51.811V50.0252C53.8116 47.7517 53.9984 46.084 54.3761 45.0141C54.7538 43.9521 55.3549 43.4171 56.1875 43.4171H58.5026ZM56.3663 56.4288H57.3288V46.3672H56.2159C55.7732 46.3672 55.4605 46.6818 55.2696 47.3033C55.0787 47.9248 54.9853 48.9475 54.9853 50.3556V52.4324C54.9853 53.8485 55.095 54.8633 55.3143 55.4848C55.5336 56.1141 55.887 56.4288 56.3663 56.4288Z"
          fill={color}
        />
        <path
          d="M63.405 72.5085H62.2312V43.4171H67.0725V72.5085H65.8987V46.3672H63.4009V72.5085H63.405Z"
          fill={color}
        />
        <path
          d="M69.6149 72.5085H68.4412V43.4171H70.7399C72.3239 43.4171 73.116 45.7693 73.116 50.4815V52.9752C73.116 57.656 72.3239 60.0003 70.7399 60.0003H69.6109V72.5085H69.6149ZM69.6149 46.3672V57.0502H70.744C71.1664 57.0502 71.471 56.7513 71.6619 56.1534C71.8528 55.5556 71.9462 54.5565 71.9462 53.1405V50.2691C71.9462 48.8531 71.8528 47.854 71.6619 47.2561C71.471 46.6582 71.1664 46.3593 70.744 46.3593H69.6149V46.3672Z"
          fill={color}
        />
        <path
          d="M76.41 72.9252C75.6383 72.9252 75.0453 72.3037 74.627 71.0529C74.2127 69.81 74.0015 68.0321 74.0015 65.735V50.1509C74.0015 47.8538 74.2086 46.0838 74.627 44.8487C75.0412 43.6136 75.6383 43 76.41 43C77.1898 43 77.7908 43.6136 78.2051 44.8487C78.6194 46.0838 78.8306 47.8459 78.8306 50.1509V65.735C78.8306 68.0321 78.6234 69.81 78.2051 71.0529C77.7908 72.3037 77.1938 72.9252 76.41 72.9252ZM76.41 69.9752C77.2426 69.9752 77.6568 68.63 77.6568 65.9474V49.9464C77.6568 47.2874 77.2426 45.9579 76.41 45.9579C75.5895 45.9579 75.1752 47.2874 75.1752 49.9464V65.9474C75.1793 68.63 75.5895 69.9752 76.41 69.9752Z"
          fill={color}
        />
        <path d="M82.5468 72.5085H81.373V46.3672H79.5088V43.4171H84.411V46.3672H82.5468V72.5085Z" fill={color} />
        <path
          d="M86.3646 65.153L89.0248 43.4171H90.2433V72.5085H89.1751V50.6073L86.5148 72.5085H85.2964V43.4171H86.3646V65.153Z"
          fill={color}
        />
        <path
          d="M94.0774 72.5085H91.6121V43.4171H93.9596C94.7922 43.4171 95.3974 43.9521 95.771 45.0141C96.1488 46.084 96.3356 47.7517 96.3356 50.0252V51.2288C96.3356 54.3283 95.9457 56.2714 95.1618 57.0502V57.1368C96.0553 57.8605 96.4981 59.8901 96.4981 63.2493V65.743C96.4981 70.2507 95.6939 72.5085 94.0774 72.5085ZM92.7858 46.3672V55.8466H93.7769C94.2561 55.8466 94.6095 55.532 94.8288 54.9105C95.0481 54.289 95.1577 53.2663 95.1577 51.8582V50.3635C95.1577 48.9475 95.0643 47.9326 94.8735 47.3112C94.6826 46.6897 94.3658 46.375 93.9271 46.375H92.7858V46.3672ZM92.7858 58.7967V69.5584H94.0774C94.5079 69.5584 94.8247 69.2516 95.0237 68.6459C95.2227 68.0401 95.3243 67.041 95.3243 65.6565V63.1627C95.3243 61.5815 95.2106 60.4644 94.9872 59.7957C94.7597 59.1271 94.3779 58.7967 93.8378 58.7967H92.7858Z"
          fill={color}
        />
        <path
          d="M99.8692 72.9252C99.0975 72.9252 98.5045 72.3037 98.0862 71.0529C97.6719 69.81 97.4607 68.0321 97.4607 65.735V50.1509C97.4607 47.8538 97.6678 46.0838 98.0862 44.8487C98.5005 43.6136 99.0975 43 99.8692 43C100.649 43 101.25 43.6136 101.664 44.8487C102.079 46.0838 102.29 47.8459 102.29 50.1509V65.735C102.29 68.0321 102.083 69.81 101.664 71.0529C101.25 72.3037 100.649 72.9252 99.8692 72.9252ZM99.8692 69.9752C100.702 69.9752 101.116 68.63 101.116 65.9474V49.9464C101.116 47.2874 100.702 45.9579 99.8692 45.9579C99.0488 45.9579 98.6345 47.2874 98.6345 49.9464V65.9474C98.6345 68.63 99.0447 69.9752 99.8692 69.9752Z"
          fill={color}
        />
        <path
          d="M104.694 72.5085H103.52V43.4171H108.362V72.5085H107.188V46.3672H104.69V72.5085H104.694Z"
          fill={color}
        />
        <path
          d="M112.017 72.9252C111.245 72.9252 110.652 72.3037 110.234 71.0529C109.82 69.81 109.609 68.0321 109.609 65.735V50.1509C109.609 47.8538 109.816 46.0838 110.234 44.8487C110.648 43.6136 111.245 43 112.017 43C112.797 43 113.398 43.6136 113.812 44.8487C114.227 46.0838 114.438 47.8459 114.438 50.1509V65.735C114.438 68.0321 114.231 69.81 113.812 71.0529C113.398 72.3037 112.801 72.9252 112.017 72.9252ZM112.017 69.9752C112.85 69.9752 113.264 68.63 113.264 65.9474V49.9464C113.264 47.2874 112.85 45.9579 112.017 45.9579C111.197 45.9579 110.782 47.2874 110.782 49.9464V65.9474C110.782 68.63 111.197 69.9752 112.017 69.9752Z"
          fill={color}
        />
        <path
          d="M120.843 72.5085H119.608L117.849 58.0493L116.842 62.911V72.5085H115.668V43.4171H116.842V57.7504L119.596 43.4171H120.814L118.633 54.3441L120.843 72.5085Z"
          fill={color}
        />
        <path
          d="M126.768 72.5085H125.582L125.192 66.3173H122.756L122.366 72.5085H121.269L123.223 43.4171H124.803L126.768 72.5085ZM123.958 47.1145L122.934 63.4853H125.01L123.958 47.1145Z"
          fill={color}
        />
        <path
          d="M129.729 72.9254C128.937 72.9254 128.34 72.3118 127.938 71.0767C127.536 69.8416 127.337 68.0952 127.337 65.8217V63.619H128.45V65.9476C128.45 68.638 128.864 69.9754 129.697 69.9754C130.517 69.9754 130.931 68.5358 130.931 65.6565V63.1234C130.931 60.2442 130.489 58.8046 129.607 58.8046H128.974V55.8466H129.636C130.476 55.8466 130.899 54.5329 130.899 51.8975V49.9859C130.899 47.2955 130.497 45.9581 129.697 45.9581C128.905 45.9581 128.511 47.264 128.511 49.8679V51.575H127.398V50.1196C127.398 47.8225 127.597 46.0604 127.991 44.841C128.385 43.6217 128.97 43.0159 129.741 43.0159C130.493 43.0159 131.069 43.6295 131.471 44.841C131.874 46.0604 132.073 47.8068 132.073 50.0803V51.2446C132.073 54.4306 131.662 56.3973 130.838 57.1447V57.2312C131.679 57.9785 132.101 59.9452 132.101 63.1313V65.8296C132.101 68.1267 131.902 69.8888 131.5 71.1082C131.102 72.3118 130.513 72.9254 129.729 72.9254Z"
          fill={color}
        />
        <path
          d="M138.181 72.5085H136.995L136.605 66.3173H134.168L133.779 72.5085H132.682L134.635 43.4171H136.215L138.181 72.5085ZM135.371 47.1145L134.347 63.4853H136.423L135.371 47.1145Z"
          fill={color}
        />
        <path
          d="M140.257 72.5085H139.083V43.4171H140.257V55.8466H142.754V43.4171H143.928V72.5085H142.75V58.7967H140.252V72.5085H140.257Z"
          fill={color}
        />
        <path
          d="M146.361 65.153L149.021 43.4171H150.24V72.5085H149.172V50.6073L146.511 72.5085H145.293V43.4171H146.361V65.153Z"
          fill={color}
        />
        <path
          d="M156.21 43.4171V72.5085H155.036V59.3788H154.074C153.574 59.3788 153.217 59.7013 153.006 60.3307C152.794 60.9679 152.689 62.0614 152.689 63.6111V68.9684C152.689 70.3294 152.644 71.5015 152.555 72.5006H151.353C151.462 71.588 151.519 70.408 151.519 68.9684V63.7763C151.519 60.315 151.925 58.2932 152.737 57.711V57.6717C151.925 56.9244 151.519 54.9734 151.519 51.811V50.0252C151.519 47.7517 151.706 46.084 152.084 45.0141C152.461 43.9521 153.062 43.4171 153.895 43.4171H156.21ZM154.074 56.4288H155.036V46.3672H153.923C153.481 46.3672 153.168 46.6818 152.977 47.3033C152.786 47.9248 152.693 48.9475 152.693 50.3556V52.4324C152.693 53.8485 152.802 54.8633 153.022 55.4848C153.241 56.1141 153.594 56.4288 154.074 56.4288Z"
          fill={color}
        />
        <path d="M158.075 77H157.534L158.046 72.5081H157.624V68.3151H158.81V72.1777L158.075 77Z" fill={color} />
        <path
          d="M163.789 72.5085H162.615V43.4171H163.789V55.8466H166.287V43.4171H167.461V72.5085H166.287V58.7967H163.789V72.5085Z"
          fill={color}
        />
        <path
          d="M173.143 72.5085H168.825V43.4171H173.143V46.3672H169.999V55.8466H172.554V58.7967H169.999V69.5584H173.143V72.5085Z"
          fill={color}
        />
        <path
          d="M176.388 72.9252C175.616 72.9252 175.023 72.3037 174.605 71.0529C174.19 69.81 173.979 68.0321 173.979 65.735V50.1509C173.979 47.8538 174.186 46.0838 174.605 44.8487C175.019 43.6136 175.616 43 176.388 43C177.172 43 177.769 43.6136 178.183 44.8487C178.597 46.0838 178.808 47.8459 178.808 50.1509V65.735C178.808 68.0321 178.601 69.81 178.183 71.0529C177.769 72.3037 177.172 72.9252 176.388 72.9252ZM176.388 69.9752C177.22 69.9752 177.635 68.63 177.635 65.9474V49.9464C177.635 47.2874 177.22 45.9579 176.388 45.9579C175.567 45.9579 175.153 47.2874 175.153 49.9464V65.9474C175.157 68.63 175.567 69.9752 176.388 69.9752Z"
          fill={color}
        />
        <path
          d="M182.464 72.5085H180.043V43.4171H184.239V46.3672H181.217V55.4297H182.464C184.048 55.4297 184.84 57.7818 184.84 62.494V65.4441C184.84 70.1563 184.048 72.5085 182.464 72.5085ZM181.217 58.3797V69.5584H182.464C182.886 69.5584 183.191 69.2595 183.382 68.6616C183.572 68.0637 183.666 67.0646 183.666 65.6486V62.2816C183.666 60.8656 183.572 59.8665 183.382 59.2687C183.191 58.6708 182.886 58.3719 182.464 58.3719H181.217V58.3797Z"
          fill={color}
        />
        <path
          d="M186.448 72.5085H185.319L187.395 57.6324L185.412 43.4171H186.659L188.134 54.3047L189.636 43.4171H190.766L188.779 57.6324L190.855 72.5085H189.608L188.028 60.9128L186.448 72.5085Z"
          fill={color}
        />
        <path
          d="M193.815 72.9252C193.044 72.9252 192.451 72.3037 192.032 71.0529C191.618 69.81 191.407 68.0321 191.407 65.735V50.1509C191.407 47.8538 191.614 46.0838 192.032 44.8487C192.447 43.6136 193.044 43 193.815 43C194.595 43 195.196 43.6136 195.611 44.8487C196.025 46.0838 196.236 47.8459 196.236 50.1509V65.735C196.236 68.0321 196.029 69.81 195.611 71.0529C195.196 72.3037 194.599 72.9252 193.815 72.9252ZM193.815 69.9752C194.648 69.9752 195.062 68.63 195.062 65.9474V49.9464C195.062 47.2874 194.648 45.9579 193.815 45.9579C192.995 45.9579 192.581 47.2874 192.581 49.9464V65.9474C192.585 68.63 192.995 69.9752 193.815 69.9752Z"
          fill={color}
        />
        <path
          d="M202.779 43.4171V69.5584H203.531V76.4182H202.357V72.5085H198.133V76.4182H196.959V69.5584H197.788C198.027 68.756 198.166 67.4265 198.194 65.57L198.494 43.425H202.779V43.4171ZM198.945 69.5584H201.606V46.3672H199.607L199.38 65.4441C199.351 67.159 199.205 68.5357 198.945 69.5584Z"
          fill={color}
        />
        <path
          d="M205.501 65.153L208.161 43.4171H209.38V72.5085H208.311V50.6073L205.651 72.5085H204.433V43.4171H205.501V65.153Z"
          fill={color}
        />
        <path
          d="M211.788 72.5085H210.752V43.4171H212.45L213.997 66.6477L215.533 43.4171H217.247V72.5085H216.117V48.2394L214.448 72.5085H213.457L211.788 48.2394V72.5085Z"
          fill={color}
        />
        <path
          d="M220.881 72.9252C220.11 72.9252 219.517 72.3037 219.098 71.0529C218.684 69.81 218.473 68.0321 218.473 65.735V50.1509C218.473 47.8538 218.68 46.0838 219.098 44.8487C219.513 43.6136 220.11 43 220.881 43C221.661 43 222.262 43.6136 222.677 44.8487C223.091 46.0838 223.302 47.8459 223.302 50.1509V65.735C223.302 68.0321 223.095 69.81 222.677 71.0529C222.262 72.3037 221.665 72.9252 220.881 72.9252ZM220.881 69.9752C221.714 69.9752 222.128 68.63 222.128 65.9474V49.9464C222.128 47.2874 221.714 45.9579 220.881 45.9579C220.061 45.9579 219.647 47.2874 219.647 49.9464V65.9474C219.651 68.63 220.061 69.9752 220.881 69.9752Z"
          fill={color}
        />
        <path
          d="M229.199 72.9252C228.428 72.9252 227.835 72.3037 227.416 71.0529C227.002 69.81 226.791 68.0321 226.791 65.735V50.1509C226.791 47.8538 226.998 46.0838 227.416 44.8487C227.831 43.6136 228.428 43 229.199 43C229.983 43 230.58 43.6136 230.995 44.8487C231.409 46.0838 231.62 47.8459 231.62 50.1509V65.735C231.62 68.0321 231.413 69.81 230.995 71.0529C230.58 72.3037 229.979 72.9252 229.199 72.9252ZM229.199 69.9752C230.032 69.9752 230.446 68.63 230.446 65.9474V49.9464C230.446 47.2874 230.032 45.9579 229.199 45.9579C228.379 45.9579 227.965 47.2874 227.965 49.9464V65.9474C227.965 68.63 228.375 69.9752 229.199 69.9752Z"
          fill={color}
        />
        <path
          d="M235.271 72.5085H232.851V43.4171H237.046V46.3672H234.024V55.4297H235.271C236.855 55.4297 237.647 57.7818 237.647 62.494V65.4441C237.647 70.1563 236.855 72.5085 235.271 72.5085ZM234.024 58.3797V69.5584H235.271C235.694 69.5584 235.998 69.2595 236.189 68.6616C236.38 68.0637 236.473 67.0646 236.473 65.6486V62.2816C236.473 60.8656 236.38 59.8665 236.189 59.2687C235.998 58.6708 235.694 58.3719 235.271 58.3719H234.024V58.3797Z"
          fill={color}
        />
        <path
          d="M239.845 72.5085H238.671V43.4171H240.974C242.558 43.4171 243.35 45.7693 243.35 50.4815V52.9752C243.35 57.656 242.558 60.0003 240.974 60.0003H239.845V72.5085ZM239.845 46.3672V57.0502H240.974C241.396 57.0502 241.701 56.7513 241.892 56.1534C242.083 55.5556 242.176 54.5565 242.176 53.1405V50.2691C242.176 48.8531 242.078 47.854 241.892 47.2561C241.701 46.6582 241.396 46.3593 240.974 46.3593H239.845V46.3672Z"
          fill={color}
        />
        <path
          d="M248.821 72.5085H247.635L247.245 66.3173H244.808L244.418 72.5085H243.321L245.275 43.4171H246.855L248.821 72.5085ZM246.01 47.1145L244.986 63.4853H247.062L246.01 47.1145Z"
          fill={color}
        />
        <path d="M251.481 72.5085H250.307V46.3672H248.443V43.4171H253.345V46.3672H251.481V72.5085Z" fill={color} />
        <path
          d="M255.303 65.153L257.963 43.4171H259.181V72.5085H258.113V50.6073L255.453 72.5085H254.234V43.4171H255.303V65.153Z"
          fill={color}
        />
        <path d="M263.121 72.5085H261.947V46.3672H260.083V43.4171H264.985V46.3672H263.121V72.5085Z" fill={color} />
        <path
          d="M268.174 72.5085H265.875V43.4171H267.049V55.0127H268.178C269.762 55.0127 270.554 57.3649 270.554 62.0771V65.4441C270.55 70.1563 269.758 72.5085 268.174 72.5085ZM267.044 57.9628V69.5584H268.174C268.596 69.5584 268.901 69.2595 269.091 68.6616C269.282 68.0637 269.376 67.0646 269.376 65.6486V61.8647C269.376 60.4487 269.282 59.4496 269.091 58.8517C268.901 58.2539 268.596 57.9549 268.174 57.9549H267.044V57.9628Z"
          fill={color}
        />
        <path
          d="M273.799 72.9253C273.055 72.9253 272.479 72.3195 272.069 71.1002C271.658 69.8808 271.451 68.1816 271.451 65.9868V49.9464C271.451 47.7595 271.654 46.0524 272.06 44.833C272.467 43.6137 273.043 43.0079 273.799 43.0079C274.55 43.0079 275.131 43.6215 275.537 44.833C275.943 46.0524 276.146 47.7595 276.146 49.9464V52.9358H275.033V49.734C275.033 48.5147 274.924 47.5785 274.709 46.9256C274.493 46.2726 274.201 45.9501 273.827 45.9501C273.458 45.9501 273.161 46.2726 272.946 46.9256C272.731 47.5785 272.621 48.5147 272.621 49.734V66.1913C272.621 67.4107 272.731 68.3468 272.946 68.9998C273.161 69.6527 273.453 69.9752 273.827 69.9752C274.197 69.9752 274.489 69.6527 274.709 68.9998C274.924 68.3468 275.033 67.4107 275.033 66.1913V62.1163H276.146V65.9789C276.146 68.1659 275.943 69.873 275.537 71.0923C275.127 72.3117 274.55 72.9253 273.799 72.9253Z"
          fill={color}
        />
        <path
          d="M281.84 43.4171V72.5085H280.667V59.3788H279.704C279.205 59.3788 278.847 59.7013 278.636 60.3307C278.425 60.9679 278.319 62.0614 278.319 63.6111V68.9684C278.319 70.3294 278.274 71.5015 278.185 72.5006H276.983C277.093 71.588 277.149 70.408 277.149 68.9684V63.7763C277.149 60.315 277.556 58.2932 278.368 57.711V57.6717C277.556 56.9244 277.149 54.9734 277.149 51.811V50.0252C277.149 47.7517 277.336 46.084 277.714 45.0141C278.092 43.9521 278.693 43.4171 279.525 43.4171H281.84ZM279.708 56.4288H280.671V46.3672H279.558C279.115 46.3672 278.802 46.6818 278.612 47.3033C278.421 47.9248 278.327 48.9475 278.327 50.3556V52.4324C278.327 53.8485 278.437 54.8633 278.656 55.4848C278.876 56.1141 279.225 56.4288 279.708 56.4288Z"
          fill={color}
        />
        <path
          d="M290.743 72.5085H289.509L287.75 58.0493L286.743 62.911V72.5085H285.569V43.4171H286.743V57.7504L289.497 43.4171H290.715L288.534 54.3441L290.743 72.5085Z"
          fill={color}
        />
        <path
          d="M296.23 72.9253C295.487 72.9253 294.91 72.3195 294.5 71.1002C294.09 69.8808 293.883 68.1816 293.883 65.9868V49.9464C293.883 47.7595 294.086 46.0524 294.492 44.833C294.898 43.6137 295.475 43.0079 296.23 43.0079C296.982 43.0079 297.563 43.6215 297.969 44.833C298.375 46.0524 298.578 47.7595 298.578 49.9464V52.9358H297.465V49.734C297.465 48.5147 297.355 47.5785 297.14 46.9256C296.925 46.2726 296.632 45.9501 296.259 45.9501C295.889 45.9501 295.593 46.2726 295.377 46.9256C295.162 47.5785 295.053 48.5147 295.053 49.734V66.1913C295.053 67.4107 295.162 68.3468 295.377 68.9998C295.593 69.6527 295.885 69.9752 296.259 69.9752C296.628 69.9752 296.921 69.6527 297.14 68.9998C297.355 68.3468 297.465 67.4107 297.465 66.1913V62.1163H298.578V65.9789C298.578 68.1659 298.375 69.873 297.969 71.0923C297.563 72.3117 296.982 72.9253 296.23 72.9253Z"
          fill={color}
        />
        <path
          d="M300.848 72.5085H299.675V43.4171H304.516V72.5085H303.342V46.3672H300.844V72.5085H300.848Z"
          fill={color}
        />
        <path
          d="M310.202 72.5085H305.885V43.4171H310.202V46.3672H307.058V55.8466H309.613V58.7967H307.058V69.5584H310.202V72.5085Z"
          fill={color}
        />
        <path
          d="M316.891 76.4182H315.718V72.5085H311.161V43.4171H312.334V69.5584H314.832V43.4171H316.006V69.5584H316.891V76.4182Z"
          fill={color}
        />
        <path
          d="M318.845 65.153L321.505 43.4171H322.724V72.5085H321.655V50.6073L318.995 72.5085H317.777V43.4171H318.845V65.153Z"
          fill={color}
        />
        <path
          d="M329.133 72.5085H327.947L327.557 66.3173H325.12L324.73 72.5085H323.633L325.587 43.4171H327.167L329.133 72.5085ZM326.318 47.1145L325.294 63.4853H327.37L326.318 47.1145Z"
          fill={color}
        />
        <path
          d="M329.99 72.5476C329.847 72.5476 329.726 72.5082 329.612 72.4217V69.5503C329.754 69.6054 329.831 69.6368 329.851 69.6368C330.221 69.6368 330.489 69.33 330.648 68.7243C330.806 68.1186 330.891 67.0644 330.903 65.5697L331.188 43.4247H335.473V72.5161H334.299V46.3669H332.301L332.077 65.4439C332.049 67.9062 331.874 69.7076 331.557 70.8483C331.245 71.9811 330.721 72.5476 329.99 72.5476Z"
          fill={color}
        />
        <path
          d="M337.914 65.153L340.574 43.4171H341.792V72.5085H340.724V50.6073L338.064 72.5085H336.845V43.4171H337.914V65.153Z"
          fill={color}
        />
        <path
          d="M345.387 72.9253C344.644 72.9253 344.067 72.3195 343.657 71.1002C343.246 69.8808 343.039 68.1816 343.039 65.9868V49.9464C343.039 47.7595 343.242 46.0524 343.649 44.833C344.055 43.6137 344.631 43.0079 345.387 43.0079C346.138 43.0079 346.719 43.6215 347.125 44.833C347.531 46.0524 347.734 47.7595 347.734 49.9464V52.9358H346.622V49.734C346.622 48.5147 346.512 47.5785 346.297 46.9256C346.081 46.2726 345.789 45.9501 345.415 45.9501C345.042 45.9501 344.749 46.2726 344.534 46.9256C344.319 47.5785 344.209 48.5147 344.209 49.734V66.1913C344.209 67.4107 344.315 68.3468 344.534 68.9998C344.749 69.6527 345.042 69.9752 345.415 69.9752C345.789 69.9752 346.077 69.6527 346.297 68.9998C346.512 68.3468 346.622 67.4107 346.622 66.1913V62.1163H347.734V65.9789C347.734 68.1659 347.531 69.873 347.125 71.0923C346.715 72.3117 346.138 72.9253 345.387 72.9253Z"
          fill={color}
        />
        <path d="M351.402 72.5085H350.228V46.3672H348.364V43.4171H353.266V46.3672H351.402V72.5085Z" fill={color} />
        <path
          d="M356.544 60.4566L357.969 43.4171H359.082L356.901 68.1424C356.751 69.8574 356.519 71.0846 356.211 71.8162C355.898 72.5478 355.459 72.9175 354.887 72.9175C354.574 72.9175 354.302 72.7681 354.058 72.4613V69.5899C354.278 69.8416 354.505 69.9675 354.736 69.9675C355.037 69.9675 355.265 69.8023 355.415 69.4719C355.565 69.1415 355.679 68.5593 355.76 67.7255L355.927 65.8532L353.729 43.4171H354.931L356.544 60.4566Z"
          fill={color}
        />
        <path d="M360 72.5081H358.814V68.3151H360V72.5081Z" fill={color} />
      </svg>
    </Box>
  </>
);

export default Disclaimer;
