import { IDisclaimerVector } from '.';
import React from 'react';

export const BeforeUsingPc = ({ bgColor, color }: IDisclaimerVector) => (
  <svg width="1920" height="80" viewBox="0 0 1920 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1920 0H0V80H1920V0Z" fill={bgColor} />
    <path
      d="M63.3311 52.1147L71.6296 19.5316H75.4255V63.1404H72.0944V30.306L63.7959 63.1404H60V19.5316H63.3311V52.1147Z"
      fill={color}
    />
    <path
      d="M82.9301 63.1404H79.6958V19.5316H84.9926L89.8246 54.3597L94.6081 19.5316H99.9533V63.1404H96.4383V26.7521L91.2383 63.1317H88.1397L82.9301 26.7521V63.1404Z"
      fill={color}
    />
    <path
      d="M117.625 63.1404H104.166V19.5316H117.625V23.9523H107.826V38.1592H115.795V42.5799H107.826V58.7111H117.625V63.1404Z"
      fill={color}
    />
    <path
      d="M136.198 18.9075C138.638 18.9075 140.507 19.8349 141.805 21.6812C143.102 23.5275 143.751 26.1799 143.751 29.6211V52.9814C143.751 56.4313 143.102 59.0837 141.805 60.956C140.507 62.8283 138.638 63.7558 136.198 63.7558C133.787 63.7558 131.937 62.8197 130.64 60.956C129.342 59.0924 128.693 56.4313 128.693 52.9814V42.5798H124.287V63.1404H120.627V19.5316H124.287V38.1591H128.693V29.6211C128.693 26.1713 129.342 23.5275 130.64 21.6812C131.937 19.8263 133.787 18.9075 136.198 18.9075ZM140.091 53.2935V29.3091C140.091 25.3218 138.793 23.3282 136.198 23.3282C133.632 23.3282 132.354 25.3218 132.354 29.3091V53.2935C132.354 57.3241 133.632 59.3351 136.198 59.3351C138.793 59.3351 140.091 57.3241 140.091 53.2935Z"
      fill={color}
    />
    <path d="M155.332 63.1404H151.672V23.9523H145.852V19.5316H161.142V23.9523H155.322V63.1404H155.332Z" fill={color} />
    <path
      d="M170.574 63.7645C168.259 63.7645 166.458 62.8543 165.18 61.0254C163.902 59.1964 163.263 56.648 163.263 53.3628V29.3091C163.263 26.0326 163.892 23.4755 165.161 21.6466C166.429 19.8176 168.23 18.9075 170.574 18.9075C172.917 18.9075 174.728 19.8176 175.987 21.6466C177.255 23.4755 177.885 26.0326 177.885 29.3091V33.7905H174.418V28.997C174.418 27.1681 174.079 25.7725 173.411 24.7931C172.743 23.8136 171.823 23.3282 170.67 23.3282C169.518 23.3282 168.598 23.8136 167.93 24.7931C167.262 25.7725 166.923 27.1681 166.923 28.997V53.6662C166.923 55.4952 167.262 56.8994 167.93 57.8702C168.598 58.8497 169.518 59.3351 170.67 59.3351C171.823 59.3351 172.743 58.8497 173.411 57.8702C174.079 56.8907 174.418 55.4952 174.418 53.6662V47.5639H177.885V53.3542C177.885 56.6394 177.255 59.1877 175.987 61.0167C174.718 62.8457 172.917 63.7645 170.574 63.7645Z"
      fill={color}
    />
    <path
      d="M195.653 19.5316V63.1404H191.993V43.4553H188.991C187.423 43.4553 186.319 43.9321 185.66 44.8856C185.002 45.839 184.673 47.4773 184.673 49.809V57.8442C184.673 59.8812 184.527 61.6408 184.246 63.1404H180.499C180.848 61.7709 181.012 60.0026 181.012 57.8442V50.0604C181.012 44.8682 182.281 41.8344 184.808 40.9676V40.8983C182.271 39.7801 181.012 36.8503 181.012 32.1176V29.4392C181.012 26.0326 181.603 23.5276 182.775 21.9327C183.946 20.3377 185.825 19.5316 188.43 19.5316H195.653ZM189.001 39.026H192.003V23.9523H188.536C187.161 23.9523 186.173 24.4204 185.583 25.3565C184.992 26.2927 184.692 27.8182 184.692 29.9332V33.0451C184.692 35.1601 185.041 36.6856 185.728 37.6218C186.406 38.5579 187.5 39.026 189.001 39.026Z"
      fill={color}
    />
    <path d="M210.943 63.1404H207.283V19.5316H222.379V63.1404H218.719V23.9523H210.934V63.1404H210.943Z" fill={color} />
    <path
      d="M230.31 63.1404H226.65V19.5316H233.825C238.763 19.5316 241.233 23.0595 241.233 30.1239V33.8598C241.233 40.8809 238.763 44.3915 233.825 44.3915H230.31V63.1404ZM230.31 23.9523V39.9621H233.825C235.142 39.9621 236.091 39.5201 236.682 38.6273C237.272 37.7345 237.572 36.2262 237.572 34.1112V29.8119C237.572 27.6969 237.272 26.1887 236.682 25.2958C236.091 24.403 235.132 23.9523 233.825 23.9523H230.31Z"
      fill={color}
    />
    <path
      d="M251.507 63.7645C249.096 63.7645 247.246 62.8283 245.949 60.9647C244.651 59.0924 244.002 56.44 244.002 52.9901V29.6211C244.002 26.1713 244.651 23.5275 245.949 21.6812C247.246 19.8349 249.096 18.9075 251.507 18.9075C253.947 18.9075 255.816 19.8349 257.113 21.6812C258.411 23.5275 259.06 26.1799 259.06 29.6211V52.9814C259.06 56.4313 258.411 59.0837 257.113 60.956C255.816 62.8283 253.947 63.7645 251.507 63.7645ZM251.507 59.3351C254.102 59.3351 255.399 57.3241 255.399 53.2935V29.3091C255.399 25.3218 254.102 23.3282 251.507 23.3282C248.941 23.3282 247.662 25.3218 247.662 29.3091V53.2935C247.662 57.3241 248.941 59.3351 251.507 59.3351Z"
      fill={color}
    />
    <path d="M270.641 63.1404H266.981V23.9523H261.161V19.5316H276.451V23.9523H270.631V63.1404H270.641Z" fill={color} />
    <path
      d="M282.551 52.1147L290.85 19.5316H294.646V63.1404H291.315V30.306L283.016 63.1404H279.22V19.5316H282.551V52.1147Z"
      fill={color}
    />
    <path
      d="M306.605 63.1404H298.916V19.5316H306.227C308.822 19.5316 310.701 20.3291 311.882 21.9327C313.054 23.5362 313.644 26.0326 313.644 29.4392V31.2421C313.644 35.8968 312.424 38.8006 309.984 39.9621V40.0835C312.763 41.167 314.158 44.2181 314.158 49.2456V52.9815C314.148 59.7512 311.63 63.1404 306.605 63.1404ZM302.567 23.9523V38.1592H305.665C307.166 38.1592 308.261 37.6911 308.948 36.755C309.636 35.8188 309.984 34.2933 309.984 32.1783V29.9332C309.984 27.8182 309.684 26.2927 309.093 25.3565C308.503 24.4204 307.515 23.9523 306.14 23.9523H302.567ZM302.567 42.5799V58.7111H306.595C307.941 58.7111 308.919 58.2516 309.548 57.3415C310.178 56.4314 310.488 54.9318 310.488 52.8601V49.1242C310.488 46.7579 310.139 45.0763 309.432 44.0794C308.725 43.0826 307.534 42.5799 305.849 42.5799H302.567Z"
      fill={color}
    />
    <path
      d="M324.654 63.7645C322.243 63.7645 320.394 62.8283 319.096 60.9647C317.799 59.0924 317.15 56.44 317.15 52.9901V29.6211C317.15 26.1713 317.799 23.5275 319.096 21.6812C320.394 19.8349 322.243 18.9075 324.654 18.9075C327.095 18.9075 328.963 19.8349 330.261 21.6812C331.559 23.5275 332.207 26.1799 332.207 29.6211V52.9814C332.207 56.4313 331.559 59.0837 330.261 60.956C328.963 62.8283 327.095 63.7645 324.654 63.7645ZM324.654 59.3351C327.249 59.3351 328.547 57.3241 328.547 53.2935V29.3091C328.547 25.3218 327.249 23.3282 324.654 23.3282C322.088 23.3282 320.81 25.3218 320.81 29.3091V53.2935C320.81 57.3241 322.088 59.3351 324.654 59.3351Z"
      fill={color}
    />
    <path d="M339.712 63.1404H336.052V19.5316H351.148V63.1404H347.488V23.9523H339.702V63.1404H339.712Z" fill={color} />
    <path
      d="M362.545 63.7645C360.134 63.7645 358.285 62.8283 356.987 60.9647C355.689 59.0924 355.041 56.44 355.041 52.9901V29.6211C355.041 26.1713 355.689 23.5275 356.987 21.6812C358.285 19.8349 360.134 18.9075 362.545 18.9075C364.985 18.9075 366.854 19.8349 368.152 21.6812C369.449 23.5275 370.098 26.1799 370.098 29.6211V52.9814C370.098 56.4313 369.449 59.0837 368.152 60.956C366.854 62.8283 364.985 63.7645 362.545 63.7645ZM362.545 59.3351C365.14 59.3351 366.438 57.3241 366.438 53.2935V29.3091C366.438 25.3218 365.14 23.3282 362.545 23.3282C359.979 23.3282 358.701 25.3218 358.701 29.3091V53.2935C358.701 57.3241 359.979 59.3351 362.545 59.3351Z"
      fill={color}
    />
    <path
      d="M390.075 63.1404H386.231L380.74 41.4617L377.603 48.7515V63.1404H373.942V19.5316H377.603V41.0283L386.182 19.5316H389.978L383.18 35.9142L390.075 63.1404Z"
      fill={color}
    />
    <path
      d="M408.551 63.1404H404.842L403.622 53.857H396.02L394.8 63.1404H391.372L397.473 19.5316H402.402L408.551 63.1404ZM399.777 25.0705L396.592 49.6183H403.06L399.777 25.0705Z"
      fill={color}
    />
    <path
      d="M417.788 63.7645C415.319 63.7645 413.46 62.837 412.211 60.9907C410.962 59.1444 410.332 56.518 410.332 53.1115V49.809H413.799V53.3022C413.799 57.3328 415.096 59.3438 417.692 59.3438C420.258 59.3438 421.536 57.1854 421.536 52.8688V49.0722C421.536 44.7555 420.161 42.5972 417.411 42.5972H415.435V38.1765H417.498C420.122 38.1765 421.439 36.2002 421.439 32.2562V29.3698C421.439 25.3391 420.19 23.3282 417.692 23.3282C415.222 23.3282 413.983 25.2785 413.983 29.1877V31.7448H410.516V29.5605C410.516 26.1106 411.136 23.4755 412.366 21.6466C413.596 19.8176 415.416 18.9075 417.827 18.9075C420.171 18.9075 421.972 19.8176 423.221 21.6466C424.47 23.4755 425.099 26.0933 425.099 29.4998V31.2421C425.099 36.0181 423.821 38.9653 421.255 40.0921V40.2135C423.879 41.3316 425.196 44.2874 425.196 49.0635V53.1115C425.196 56.5613 424.567 59.1964 423.318 61.0254C422.069 62.8456 420.219 63.7645 417.788 63.7645Z"
      fill={color}
    />
    <path
      d="M444.137 63.1404H440.428L439.208 53.857H431.606L430.386 63.1404H426.958L433.059 19.5316H437.988L444.137 63.1404ZM435.373 25.0705L432.187 49.6183H438.656L435.373 25.0705Z"
      fill={color}
    />
    <path
      d="M450.605 63.1404H446.945V19.5316H450.605V38.1592H458.39V19.5316H462.051V63.1404H458.39V42.5799H450.605V63.1404Z"
      fill={color}
    />
    <path
      d="M469.652 52.1147L477.951 19.5316H481.747V63.1404H478.416V30.306L470.117 63.1404H466.321V19.5316H469.652V52.1147Z"
      fill={color}
    />
    <path
      d="M500.358 19.5316V63.1404H496.698V43.4553H493.696C492.127 43.4553 491.023 43.9321 490.365 44.8856C489.706 45.839 489.377 47.4773 489.377 49.809V57.8442C489.377 59.8812 489.232 61.6408 488.951 63.1404H485.204C485.552 61.7709 485.717 60.0026 485.717 57.8442V50.0604C485.717 44.8682 486.985 41.8344 489.513 40.9676V40.8983C486.976 39.7801 485.717 36.8503 485.717 32.1176V29.4392C485.717 26.0326 486.307 23.5276 487.479 21.9327C488.651 20.3377 490.529 19.5316 493.134 19.5316H500.358ZM493.705 39.026H496.707V23.9523H493.241C491.866 23.9523 490.878 24.4204 490.287 25.3565C489.697 26.2927 489.396 27.8182 489.396 29.9332V33.0451C489.396 35.1601 489.745 36.6856 490.433 37.6218C491.11 38.5579 492.205 39.026 493.705 39.026Z"
      fill={color}
    />
    <path d="M508.473 63.1404H504.764V56.8474H508.473V63.1404Z" fill={color} />
    <path d="M523.995 63.1404H520.335V19.5316H535.431V63.1404H531.771V23.9523H523.985V63.1404H523.995Z" fill={color} />
    <path
      d="M553.161 63.1404H539.701V19.5316H553.161V23.9523H543.362V38.1592H551.331V42.5799H543.362V58.7111H553.161V63.1404Z"
      fill={color}
    />
    <path
      d="M559.823 63.1404H556.163V19.5316H563.338C568.277 19.5316 570.746 23.0595 570.746 30.1239V33.8598C570.746 40.8809 568.277 44.3915 563.338 44.3915H559.823V63.1404ZM559.823 23.9523V39.9621H563.338C564.655 39.9621 565.604 39.5201 566.195 38.6273C566.785 37.7345 567.086 36.2262 567.086 34.1112V29.8119C567.086 27.6969 566.785 26.1887 566.195 25.2958C565.604 24.403 564.645 23.9523 563.338 23.9523H559.823Z"
      fill={color}
    />
    <path
      d="M587.343 63.1404H573.883V19.5316H587.343V23.9523H577.543V38.1592H585.513V42.5799H577.543V58.7111H587.343V63.1404Z"
      fill={color}
    />
    <path
      d="M607.039 19.5317V58.7198H609.382V69.0001H605.722V63.1405H592.543V69.0001H588.883V58.7111H591.458C592.204 57.5063 592.63 55.5126 592.727 52.7302L593.666 19.523H607.039V19.5317ZM595.08 58.7111H603.379V23.9524H597.143L596.436 52.5482C596.348 55.1226 595.893 57.1769 595.08 58.7111Z"
      fill={color}
    />
    <path d="M623.268 63.1404H619.608V19.5316H634.704V63.1404H631.044V23.9523H623.258V63.1404H623.268Z" fill={color} />
    <path
      d="M642.635 63.1404H638.974V19.5316H646.15C651.088 19.5316 653.558 23.0595 653.558 30.1239V33.8598C653.558 40.8809 651.088 44.3915 646.15 44.3915H642.635V63.1404ZM642.635 23.9523V39.9621H646.15C647.467 39.9621 648.416 39.5201 649.006 38.6273C649.597 37.7345 649.897 36.2262 649.897 34.1112V29.8119C649.897 27.6969 649.597 26.1887 649.006 25.2958C648.416 24.403 647.457 23.9523 646.15 23.9523H642.635Z"
      fill={color}
    />
    <path
      d="M660.026 52.1147L668.324 19.5316H672.12V63.1404H668.789V30.306L660.491 63.1404H656.695V19.5316H660.026V52.1147Z"
      fill={color}
    />
    <path
      d="M679.625 63.1404H676.391V19.5316H681.687L686.519 54.3597L691.303 19.5316H696.648V63.1404H693.133V26.7521L687.933 63.1317H684.835L679.625 26.7521V63.1404Z"
      fill={color}
    />
    <path
      d="M714.33 63.1404H700.87V19.5316H714.33V23.9523H704.53V38.1592H712.5V42.5799H704.53V58.7111H714.33V63.1404Z"
      fill={color}
    />
    <path
      d="M720.992 63.1404H717.332V19.5316H720.992V38.1592H728.777V19.5316H732.438V63.1404H728.777V42.5799H720.992V63.1404Z"
      fill={color}
    />
    <path
      d="M750.158 63.1404H736.698V19.5316H750.158V23.9523H740.359V38.1592H748.328V42.5799H740.359V58.7111H750.158V63.1404Z"
      fill={color}
    />
    <path
      d="M756.811 63.1404H753.15V19.5316H756.811V38.1592H764.596V19.5316H768.256V63.1404H764.596V42.5799H756.811V63.1404Z"
      fill={color}
    />
    <path
      d="M775.848 52.1147L784.147 19.5316H787.942V63.1404H784.611V30.306L776.313 63.1404H772.517V19.5316H775.848V52.1147Z"
      fill={color}
    />
    <path
      d="M805.672 63.1404H792.213V19.5316H805.672V23.9523H795.873V38.1592H803.842V42.5799H795.873V58.7111H805.672V63.1404Z"
      fill={color}
    />
    <path
      d="M811.909 63.1404H808.674V19.5316H813.971L818.803 54.3597L823.587 19.5316H828.932V63.1404H825.417V26.7521L820.217 63.1317H817.118L811.909 26.7521V63.1404Z"
      fill={color}
    />
    <path
      d="M847.688 63.7645C845.277 63.7645 843.428 62.8283 842.13 60.9647C840.833 59.0924 840.184 56.44 840.184 52.9901V29.6211C840.184 26.1713 840.833 23.5275 842.13 21.6812C843.428 19.8349 845.277 18.9075 847.688 18.9075C850.129 18.9075 851.997 19.8349 853.295 21.6812C854.593 23.5275 855.241 26.1799 855.241 29.6211V52.9814C855.241 56.4313 854.593 59.0837 853.295 60.956C851.997 62.8283 850.129 63.7645 847.688 63.7645ZM847.688 59.3351C850.283 59.3351 851.581 57.3241 851.581 53.2935V29.3091C851.581 25.3218 850.283 23.3282 847.688 23.3282C845.122 23.3282 843.844 25.3218 843.844 29.3091V53.2935C843.844 57.3241 845.132 59.3351 847.688 59.3351Z"
      fill={color}
    />
    <path
      d="M865.506 63.7645C863.036 63.7645 861.177 62.837 859.928 60.9907C858.679 59.1444 858.05 56.518 858.05 53.1115V49.809H861.516V53.3022C861.516 57.3328 862.814 59.3438 865.409 59.3438C867.975 59.3438 869.253 57.1854 869.253 52.8688V49.0722C869.253 44.7555 867.878 42.5972 865.128 42.5972H863.162V38.1765H865.225C867.849 38.1765 869.166 36.2002 869.166 32.2562V29.3698C869.166 25.3391 867.917 23.3282 865.419 23.3282C862.949 23.3282 861.71 25.2785 861.71 29.1877V31.7448H858.243V29.5605C858.243 26.1106 858.863 23.4755 860.093 21.6466C861.323 19.8176 863.143 18.9075 865.554 18.9075C867.898 18.9075 869.699 19.8176 870.948 21.6466C872.197 23.4755 872.826 26.0933 872.826 29.4998V31.2421C872.826 36.0181 871.548 38.9653 868.982 40.0921V40.2135C871.606 41.3316 872.923 44.2874 872.923 49.0635V53.1115C872.923 56.5613 872.294 59.1964 871.045 61.0254C869.795 62.8456 867.946 63.7645 865.506 63.7645Z"
      fill={color}
    />
    <path
      d="M880.137 63.1404H876.477V19.5316H880.137V38.1592H887.922V19.5316H891.583V63.1404H887.922V42.5799H880.137V63.1404Z"
      fill={color}
    />
    <path
      d="M911.56 63.1404H907.851L906.631 53.857H899.029L897.809 63.1404H894.381L900.482 19.5316H905.411L911.56 63.1404ZM902.786 25.0705L899.601 49.6183H906.069L902.786 25.0705Z"
      fill={color}
    />
    <path
      d="M930.5 63.1404H926.656L921.165 41.4617L918.028 48.7515V63.1404H914.368V19.5316H918.028V41.0283L926.607 19.5316H930.403L923.606 35.9142L930.5 63.1404Z"
      fill={color}
    />
    <path
      d="M939.737 63.7645C937.326 63.7645 935.477 62.8283 934.179 60.9647C932.882 59.0924 932.233 56.44 932.233 52.9901V29.6211C932.233 26.1713 932.882 23.5275 934.179 21.6812C935.477 19.8349 937.326 18.9075 939.737 18.9075C942.178 18.9075 944.047 19.8349 945.344 21.6812C946.642 23.5275 947.29 26.1799 947.29 29.6211V52.9814C947.29 56.4313 946.642 59.0837 945.344 60.956C944.047 62.8283 942.178 63.7645 939.737 63.7645ZM939.737 59.3351C942.333 59.3351 943.63 57.3241 943.63 53.2935V29.3091C943.63 25.3218 942.333 23.3282 939.737 23.3282C937.171 23.3282 935.893 25.3218 935.893 29.3091V53.2935C935.893 57.3241 937.181 59.3351 939.737 59.3351Z"
      fill={color}
    />
    <path
      d="M954.37 63.1404H951.135V19.5316H956.432L961.264 54.3597L966.048 19.5316H971.393V63.1404H967.878V26.7521L962.678 63.1317H959.579L954.37 26.7521V63.1404Z"
      fill={color}
    />
    <path
      d="M982.79 63.1404H975.615V19.5316H979.275V36.911H982.79C987.728 36.911 990.198 40.4389 990.198 47.5033V52.5481C990.198 59.6039 987.728 63.1404 982.79 63.1404ZM979.275 41.3317V58.7111H982.79C984.107 58.7111 985.056 58.269 985.646 57.3675C986.237 56.4747 986.537 54.9665 986.537 52.8515V47.1826C986.537 45.0676 986.237 43.5594 985.646 42.6666C985.056 41.7737 984.097 41.323 982.79 41.323H979.275V41.3317Z"
      fill={color}
    />
    <path d="M998.08 63.1404H994.419V23.9523H988.6V19.5316H1003.89V23.9523H998.07V63.1404H998.08Z" fill={color} />
    <path
      d="M1020.12 63.1404H1006.66V19.5316H1020.12V23.9523H1010.32V38.1592H1018.29V42.5799H1010.32V58.7111H1020.12V63.1404Z"
      fill={color}
    />
    <path
      d="M1030.05 63.7645C1027.74 63.7645 1025.94 62.8543 1024.66 61.0254C1023.38 59.1964 1022.74 56.648 1022.74 53.3628V29.3091C1022.74 26.0326 1023.37 23.4755 1024.64 21.6466C1025.91 19.8176 1027.71 18.9075 1030.05 18.9075C1032.4 18.9075 1034.21 19.8176 1035.47 21.6466C1036.74 23.4755 1037.36 26.0326 1037.36 29.3091V33.7905H1033.9V28.997C1033.9 27.1681 1033.56 25.7725 1032.89 24.7931C1032.22 23.8136 1031.3 23.3282 1030.15 23.3282C1029 23.3282 1028.08 23.8136 1027.41 24.7931C1026.74 25.7725 1026.4 27.1681 1026.4 28.997V53.6662C1026.4 55.4952 1026.74 56.8994 1027.41 57.8702C1028.08 58.8497 1029 59.3351 1030.15 59.3351C1031.3 59.3351 1032.22 58.8497 1032.89 57.8702C1033.56 56.8907 1033.9 55.4952 1033.9 53.6662V47.5639H1037.36V53.3542C1037.36 56.6394 1036.74 59.1877 1035.47 61.0167C1034.21 62.8457 1032.4 63.7645 1030.05 63.7645Z"
      fill={color}
    />
    <path
      d="M1047.97 63.1404H1040.79V19.5316H1044.45V36.911H1047.97C1052.91 36.911 1055.38 40.4389 1055.38 47.5033V52.5481C1055.38 59.6039 1052.91 63.1404 1047.97 63.1404ZM1044.45 41.3317V58.7111H1047.97C1049.29 58.7111 1050.23 58.269 1050.82 57.3675C1051.42 56.4747 1051.72 54.9665 1051.72 52.8515V47.1826C1051.72 45.0676 1051.42 43.5594 1050.82 42.6666C1050.23 41.7737 1049.28 41.323 1047.97 41.323H1044.45V41.3317Z"
      fill={color}
    />
    <path
      d="M1072.91 63.7645C1070.6 63.7645 1068.8 62.8543 1067.52 61.0254C1066.24 59.1964 1065.6 56.648 1065.6 53.3628V29.3091C1065.6 26.0326 1066.23 23.4755 1067.5 21.6466C1068.77 19.8176 1070.57 18.9075 1072.91 18.9075C1075.26 18.9075 1077.07 19.8176 1078.33 21.6466C1079.59 23.4755 1080.22 26.0326 1080.22 29.3091V33.7905H1076.76V28.997C1076.76 27.1681 1076.42 25.7725 1075.75 24.7931C1075.08 23.8136 1074.16 23.3282 1073.01 23.3282C1071.86 23.3282 1070.94 23.8136 1070.27 24.7931C1069.6 25.7725 1069.26 27.1681 1069.26 28.997V53.6662C1069.26 55.4952 1069.6 56.8994 1070.27 57.8702C1070.94 58.8497 1071.86 59.3351 1073.01 59.3351C1074.16 59.3351 1075.08 58.8497 1075.75 57.8702C1076.42 56.8907 1076.76 55.4952 1076.76 53.6662V47.5639H1080.22V53.3542C1080.22 56.6394 1079.59 59.1877 1078.33 61.0167C1077.07 62.8457 1075.26 63.7645 1072.91 63.7645Z"
      fill={color}
    />
    <path
      d="M1094.39 52.1147L1102.69 19.5316H1106.48V63.1404H1103.15V30.306L1094.85 63.1404H1091.06V19.5316H1094.39V52.1147Z"
      fill={color}
    />
    <path
      d="M1114.42 63.1404H1110.75V19.5316H1114.42V38.1592H1122.2V19.5316H1125.86V63.1404H1122.2V42.5799H1114.42V63.1404Z"
      fill={color}
    />
    <path
      d="M1137.06 63.7645C1134.75 63.7645 1132.95 62.8543 1131.67 61.0254C1130.39 59.1964 1129.75 56.648 1129.75 53.3628V29.3091C1129.75 26.0326 1130.38 23.4755 1131.65 21.6466C1132.92 19.8176 1134.72 18.9075 1137.06 18.9075C1139.41 18.9075 1141.22 19.8176 1142.48 21.6466C1143.75 23.4755 1144.38 26.0326 1144.38 29.3091V33.7905H1140.91V28.997C1140.91 27.1681 1140.57 25.7725 1139.9 24.7931C1139.23 23.8136 1138.31 23.3282 1137.16 23.3282C1136.01 23.3282 1135.09 23.8136 1134.42 24.7931C1133.75 25.7725 1133.41 27.1681 1133.41 28.997V53.6662C1133.41 55.4952 1133.75 56.8994 1134.42 57.8702C1135.09 58.8497 1136.01 59.3351 1137.16 59.3351C1138.31 59.3351 1139.23 58.8497 1139.9 57.8702C1140.57 56.8907 1140.91 55.4952 1140.91 53.6662V47.5639H1144.38V53.3542C1144.38 56.6394 1143.75 59.1877 1142.48 61.0167C1141.21 62.8457 1139.41 63.7645 1137.06 63.7645Z"
      fill={color}
    />
    <path d="M1155.82 63.1404H1152.16V23.9523H1146.34V19.5316H1161.63V23.9523H1155.81V63.1404H1155.82Z" fill={color} />
    <path
      d="M1168.06 63.1404H1164.4V19.5316H1171.58C1176.51 19.5316 1178.98 23.0595 1178.98 30.1239V33.8598C1178.98 40.8809 1176.51 44.3915 1171.58 44.3915H1168.06V63.1404ZM1168.06 23.9523V39.9621H1171.58C1172.89 39.9621 1173.84 39.5201 1174.43 38.6273C1175.02 37.7345 1175.32 36.2262 1175.32 34.1112V29.8119C1175.32 27.6969 1175.02 26.1887 1174.43 25.2958C1173.84 24.403 1172.88 23.9523 1171.58 23.9523H1168.06Z"
      fill={color}
    />
    <path
      d="M1189.11 45.0677L1193.57 19.523H1197.03L1190.24 56.5875C1189.77 59.1619 1189.04 60.9995 1188.08 62.1003C1187.11 63.2012 1185.73 63.7472 1183.95 63.7472C1182.98 63.7472 1182.12 63.5219 1181.38 63.0625V58.7631C1182.06 59.1359 1182.77 59.3266 1183.49 59.3266C1184.43 59.3266 1185.13 59.0752 1185.6 58.5811C1186.06 58.087 1186.42 57.2116 1186.67 55.9634L1187.19 53.1636L1180.34 19.523H1184.09L1189.11 45.0677Z"
      fill={color}
    />
    <path
      d="M1215.79 63.1404H1211.95L1206.46 41.4617L1203.32 48.7515V63.1404H1199.66V19.5316H1203.32V41.0283L1211.9 19.5316H1215.69L1208.9 35.9142L1215.79 63.1404Z"
      fill={color}
    />
    <path
      d="M1236.42 68.9913H1232.76V63.1317H1218.56V19.5316H1222.22V58.7197H1230V19.5316H1233.67V58.7197H1236.43V68.9913H1236.42Z"
      fill={color}
    />
    <path
      d="M1242.53 52.1147L1250.82 19.5316H1254.62V63.1404H1251.29V30.306L1242.99 63.1404H1239.19V19.5316H1242.53V52.1147Z"
      fill={color}
    />
    <path
      d="M1272.35 63.1404H1258.89V19.5316H1272.35V23.9523H1262.55V38.1592H1270.52V42.5799H1262.55V58.7111H1272.35V63.1404Z"
      fill={color}
    />
    <path
      d="M1278.67 52.1147L1286.97 19.5316H1290.77V63.1404H1287.44V30.306L1279.14 63.1404H1275.34V19.5316H1278.67V52.1147ZM1278.11 9H1280.41C1280.59 11.2017 1281.47 12.3025 1283.03 12.3025C1284.66 12.3025 1285.57 11.2017 1285.75 9H1288C1287.93 11.0803 1287.47 12.7012 1286.61 13.8628C1285.75 15.0243 1284.56 15.605 1283.03 15.605C1281.5 15.605 1280.31 15.0329 1279.47 13.8888C1278.62 12.7533 1278.18 11.115 1278.11 9Z"
      fill={color}
    />
    <path
      d="M1305.78 52.1147L1314.07 19.5316H1317.87V63.1404H1314.54V30.306L1306.24 63.1404H1302.45V19.5316H1305.78V52.1147Z"
      fill={color}
    />
    <path
      d="M1321.86 63.2011C1321.42 63.2011 1321.03 63.1405 1320.69 63.0105V58.7111C1321.12 58.7978 1321.38 58.8325 1321.43 58.8325C1322.59 58.8325 1323.42 58.3731 1323.92 57.4629C1324.43 56.5528 1324.69 54.9752 1324.72 52.7302L1325.61 19.523H1338.97V63.1318H1335.31V23.9524H1329.07L1328.37 52.5482C1328.27 56.2494 1327.74 58.9452 1326.75 60.6441C1325.78 62.3517 1324.15 63.2011 1321.86 63.2011Z"
      fill={color}
    />
    <path
      d="M1346.57 52.1147L1354.87 19.5316H1358.67V63.1404H1355.34V30.306L1347.04 63.1404H1343.24V19.5316H1346.57V52.1147Z"
      fill={color}
    />
    <path d="M1374.01 63.1404H1370.34V19.5316H1385.44V63.1404H1381.78V23.9523H1374V63.1404H1374.01Z" fill={color} />
    <path
      d="M1393.37 63.1404H1389.71V19.5316H1396.89C1401.83 19.5316 1404.29 23.0595 1404.29 30.1239V33.8598C1404.29 40.8809 1401.83 44.3915 1396.89 44.3915H1393.37V63.1404ZM1393.37 23.9523V39.9621H1396.89C1398.2 39.9621 1399.15 39.5201 1399.74 38.6273C1400.33 37.7345 1400.63 36.2262 1400.63 34.1112V29.8119C1400.63 27.6969 1400.33 26.1887 1399.74 25.2958C1399.15 24.403 1398.19 23.9523 1396.89 23.9523H1393.37Z"
      fill={color}
    />
    <path
      d="M1414.57 63.7645C1412.16 63.7645 1410.31 62.8283 1409.01 60.9647C1407.71 59.0924 1407.06 56.44 1407.06 52.9901V29.6211C1407.06 26.1713 1407.71 23.5275 1409.01 21.6812C1410.31 19.8349 1412.16 18.9075 1414.57 18.9075C1417.01 18.9075 1418.88 19.8349 1420.17 21.6812C1421.47 23.5275 1422.12 26.1799 1422.12 29.6211V52.9814C1422.12 56.4313 1421.47 59.0837 1420.17 60.956C1418.88 62.8283 1417.01 63.7645 1414.57 63.7645ZM1414.57 59.3351C1417.16 59.3351 1418.46 57.3241 1418.46 53.2935V29.3091C1418.46 25.3218 1417.16 23.3282 1414.57 23.3282C1412 23.3282 1410.72 25.3218 1410.72 29.3091V53.2935C1410.72 57.3241 1412 59.3351 1414.57 59.3351Z"
      fill={color}
    />
    <path
      d="M1442.1 63.1404H1438.25L1432.76 41.4617L1429.63 48.7515V63.1404H1425.97V19.5316H1429.63V41.0283L1438.21 19.5316H1442L1435.2 35.9142L1442.1 63.1404Z"
      fill={color}
    />
    <path
      d="M1451.34 63.7645C1448.92 63.7645 1447.08 62.8283 1445.78 60.9647C1444.48 59.0924 1443.83 56.44 1443.83 52.9901V29.6211C1443.83 26.1713 1444.48 23.5275 1445.78 21.6812C1447.08 19.8349 1448.92 18.9075 1451.34 18.9075C1453.78 18.9075 1455.64 19.8349 1456.94 21.6812C1458.24 23.5275 1458.89 26.1799 1458.89 29.6211V52.9814C1458.89 56.4313 1458.24 59.0837 1456.94 60.956C1455.64 62.8283 1453.78 63.7645 1451.34 63.7645ZM1451.34 59.3351C1453.93 59.3351 1455.23 57.3241 1455.23 53.2935V29.3091C1455.23 25.3218 1453.93 23.3282 1451.34 23.3282C1448.77 23.3282 1447.49 25.3218 1447.49 29.3091V53.2935C1447.49 57.3241 1448.77 59.3351 1451.34 59.3351Z"
      fill={color}
    />
    <path
      d="M1466.38 63.1404H1462.72V19.5316H1466.38V38.1592H1474.17V19.5316H1477.83V63.1404H1474.17V42.5799H1466.38V63.1404Z"
      fill={color}
    />
    <path
      d="M1489.03 63.7645C1486.72 63.7645 1484.92 62.8543 1483.64 61.0254C1482.36 59.1964 1481.72 56.648 1481.72 53.3628V29.3091C1481.72 26.0326 1482.35 23.4755 1483.62 21.6466C1484.89 19.8176 1486.69 18.9075 1489.03 18.9075C1491.38 18.9075 1493.19 19.8176 1494.45 21.6466C1495.71 23.4755 1496.34 26.0326 1496.34 29.3091V33.7905H1492.88V28.997C1492.88 27.1681 1492.54 25.7725 1491.87 24.7931C1491.2 23.8136 1490.28 23.3282 1489.13 23.3282C1487.98 23.3282 1487.06 23.8136 1486.39 24.7931C1485.72 25.7725 1485.38 27.1681 1485.38 28.997V53.6662C1485.38 55.4952 1485.72 56.8994 1486.39 57.8702C1487.06 58.8497 1487.98 59.3351 1489.13 59.3351C1490.28 59.3351 1491.2 58.8497 1491.87 57.8702C1492.54 56.8907 1492.88 55.4952 1492.88 53.6662V47.5639H1496.34V53.3542C1496.34 56.6394 1495.71 59.1877 1494.45 61.0167C1493.19 62.8457 1491.38 63.7645 1489.03 63.7645Z"
      fill={color}
    />
    <path
      d="M1507.23 45.0677L1511.68 19.523H1515.15L1508.35 56.5875C1507.89 59.1619 1507.16 60.9995 1506.19 62.1003C1505.22 63.2012 1503.85 63.7472 1502.07 63.7472C1501.1 63.7472 1500.24 63.5219 1499.49 63.0625V58.7631C1500.18 59.1359 1500.89 59.3266 1501.6 59.3266C1502.54 59.3266 1503.25 59.0752 1503.71 58.5811C1504.18 58.087 1504.54 57.2116 1504.79 55.9634L1505.3 53.1636L1498.46 19.523H1502.2L1507.23 45.0677Z"
      fill={color}
    />
    <path
      d="M1514.92 63.2011C1514.48 63.2011 1514.08 63.1405 1513.74 63.0105V58.7111C1514.18 58.7978 1514.43 58.8325 1514.49 58.8325C1515.64 58.8325 1516.48 58.3731 1516.98 57.4629C1517.48 56.5528 1517.74 54.9752 1517.77 52.7302L1518.66 19.523H1532.03V63.1318H1528.37V23.9524H1522.13L1521.42 52.5482C1521.33 56.2494 1520.79 58.9452 1519.81 60.6441C1518.84 62.3517 1517.2 63.2011 1514.92 63.2011Z"
      fill={color}
    />
    <path
      d="M1543.47 63.1404H1536.3V19.5316H1539.96V36.911H1543.47C1548.41 36.911 1550.88 40.4389 1550.88 47.5033V52.5481C1550.88 59.6039 1548.41 63.1404 1543.47 63.1404ZM1539.96 41.3317V58.7111H1543.47C1544.79 58.7111 1545.74 58.269 1546.33 57.3675C1546.92 56.4747 1547.22 54.9665 1547.22 52.8515V47.1826C1547.22 45.0676 1546.92 43.5594 1546.33 42.6666C1545.74 41.7737 1544.78 41.323 1543.47 41.323H1539.96V41.3317Z"
      fill={color}
    />
    <path d="M1558.76 63.1404H1555.1V23.9523H1549.28V19.5316H1564.57V23.9523H1558.75V63.1404H1558.76Z" fill={color} />
    <path
      d="M1570.67 52.1147L1578.97 19.5316H1582.77V63.1404H1579.44V30.306L1571.14 63.1404H1567.34V19.5316H1570.67V52.1147Z"
      fill={color}
    />
    <path
      d="M1590.7 63.1404H1587.04V19.5316H1594.21C1599.15 19.5316 1601.62 23.0595 1601.62 30.1239V33.8598C1601.62 40.8809 1599.15 44.3915 1594.21 44.3915H1590.7V63.1404ZM1590.7 23.9523V39.9621H1594.21C1595.53 39.9621 1596.48 39.5201 1597.07 38.6273C1597.66 37.7345 1597.96 36.2262 1597.96 34.1112V29.8119C1597.96 27.6969 1597.66 26.1887 1597.07 25.2958C1596.48 24.403 1595.52 23.9523 1594.21 23.9523H1590.7Z"
      fill={color}
    />
    <path
      d="M1611.75 45.0677L1616.2 19.523H1619.67L1612.87 56.5875C1612.41 59.1619 1611.68 60.9995 1610.71 62.1003C1609.74 63.2012 1608.37 63.7472 1606.59 63.7472C1605.62 63.7472 1604.76 63.5219 1604.01 63.0625V58.7631C1604.7 59.1359 1605.41 59.3266 1606.12 59.3266C1607.06 59.3266 1607.77 59.0752 1608.23 58.5811C1608.7 58.087 1609.06 57.2116 1609.31 55.9634L1609.82 53.1636L1602.98 19.523H1606.72L1611.75 45.0677Z"
      fill={color}
    />
    <path
      d="M1625.63 52.1147L1633.92 19.5316H1637.72V63.1404H1634.39V30.306L1626.09 63.1404H1622.29V19.5316H1625.63V52.1147ZM1625.06 9H1627.36C1627.54 11.2017 1628.42 12.3025 1629.98 12.3025C1631.61 12.3025 1632.52 11.2017 1632.7 9H1634.95C1634.88 11.0803 1634.43 12.7012 1633.57 13.8628C1632.7 15.0243 1631.51 15.605 1629.98 15.605C1628.45 15.605 1627.26 15.0329 1626.42 13.8888C1625.59 12.7533 1625.13 11.115 1625.06 9Z"
      fill={color}
    />
    <path d="M1650.02 63.1404H1646.36V23.9523H1640.54V19.5316H1655.83V23.9523H1650.01V63.1404H1650.02Z" fill={color} />
    <path
      d="M1672.06 63.1404H1658.6V19.5316H1672.06V23.9523H1662.26V38.1592H1670.23V42.5799H1662.26V58.7111H1672.06V63.1404Z"
      fill={color}
    />
    <path
      d="M1681.99 63.7645C1679.68 63.7645 1677.88 62.8543 1676.6 61.0254C1675.32 59.1964 1674.68 56.648 1674.68 53.3628V29.3091C1674.68 26.0326 1675.31 23.4755 1676.58 21.6466C1677.85 19.8176 1679.65 18.9075 1681.99 18.9075C1684.34 18.9075 1686.15 19.8176 1687.41 21.6466C1688.67 23.4755 1689.3 26.0326 1689.3 29.3091V33.7905H1685.84V28.997C1685.84 27.1681 1685.5 25.7725 1684.83 24.7931C1684.16 23.8136 1683.24 23.3282 1682.09 23.3282C1680.94 23.3282 1680.02 23.8136 1679.35 24.7931C1678.68 25.7725 1678.34 27.1681 1678.34 28.997V53.6662C1678.34 55.4952 1678.68 56.8994 1679.35 57.8702C1680.02 58.8497 1680.94 59.3351 1682.09 59.3351C1683.24 59.3351 1684.16 58.8497 1684.83 57.8702C1685.5 56.8907 1685.84 55.4952 1685.84 53.6662V47.5639H1689.3V53.3542C1689.3 56.6394 1688.67 59.1877 1687.41 61.0167C1686.15 62.8457 1684.34 63.7645 1681.99 63.7645Z"
      fill={color}
    />
    <path
      d="M1699.91 63.1404H1692.73V19.5316H1696.39V36.911H1699.91C1704.84 36.911 1707.31 40.4389 1707.31 47.5033V52.5481C1707.31 59.6039 1704.84 63.1404 1699.91 63.1404ZM1696.39 41.3317V58.7111H1699.91C1701.22 58.7111 1702.17 58.269 1702.76 57.3675C1703.35 56.4747 1703.65 54.9665 1703.65 52.8515V47.1826C1703.65 45.0676 1703.35 43.5594 1702.76 42.6666C1702.17 41.7737 1701.21 41.323 1699.91 41.323H1696.39V41.3317Z"
      fill={color}
    />
    <path
      d="M1724.85 63.7645C1722.54 63.7645 1720.73 62.8543 1719.46 61.0254C1718.18 59.1964 1717.54 56.648 1717.54 53.3628V29.3091C1717.54 26.0326 1718.17 23.4755 1719.44 21.6466C1720.71 19.8176 1722.51 18.9075 1724.85 18.9075C1727.19 18.9075 1729 19.8176 1730.26 21.6466C1731.53 23.4755 1732.16 26.0326 1732.16 29.3091V33.7905H1728.69V28.997C1728.69 27.1681 1728.36 25.7725 1727.69 24.7931C1727.02 23.8136 1726.1 23.3282 1724.95 23.3282C1723.79 23.3282 1722.87 23.8136 1722.21 24.7931C1721.54 25.7725 1721.2 27.1681 1721.2 28.997V53.6662C1721.2 55.4952 1721.54 56.8994 1722.21 57.8702C1722.87 58.8497 1723.79 59.3351 1724.95 59.3351C1726.1 59.3351 1727.02 58.8497 1727.69 57.8702C1728.36 56.8907 1728.69 55.4952 1728.69 53.6662V47.5639H1732.16V53.3542C1732.16 56.6394 1731.53 59.1877 1730.26 61.0167C1729 62.8457 1727.19 63.7645 1724.85 63.7645Z"
      fill={color}
    />
    <path
      d="M1750.69 63.1404H1743V19.5316H1750.31C1752.9 19.5316 1754.78 20.3291 1755.96 21.9327C1757.13 23.5362 1757.73 26.0326 1757.73 29.4392V31.2421C1757.73 35.8968 1756.51 38.8006 1754.06 39.9621V40.0835C1756.84 41.167 1758.24 44.2181 1758.24 49.2456V52.9815C1758.24 59.7512 1755.72 63.1404 1750.69 63.1404ZM1746.66 23.9523V38.1592H1749.76C1751.26 38.1592 1752.35 37.6911 1753.04 36.755C1753.73 35.8188 1754.07 34.2933 1754.07 32.1783V29.9332C1754.07 27.8182 1753.77 26.2927 1753.18 25.3565C1752.59 24.4204 1751.61 23.9523 1750.23 23.9523H1746.66ZM1746.66 42.5799V58.7111H1750.69C1752.03 58.7111 1753.01 58.2516 1753.64 57.3415C1754.27 56.4314 1754.58 54.9318 1754.58 52.8601V49.1242C1754.58 46.7579 1754.23 45.0763 1753.52 44.0794C1752.82 43.0826 1751.62 42.5799 1749.94 42.5799H1746.66Z"
      fill={color}
    />
    <path
      d="M1765.28 63.1404H1761.62V19.5316H1768.79C1773.73 19.5316 1776.2 23.0595 1776.2 30.1239V33.8598C1776.2 40.8809 1773.73 44.3915 1768.79 44.3915H1765.28V63.1404ZM1765.28 23.9523V39.9621H1768.79C1770.11 39.9621 1771.06 39.5201 1771.65 38.6273C1772.24 37.7345 1772.54 36.2262 1772.54 34.1112V29.8119C1772.54 27.6969 1772.24 26.1887 1771.65 25.2958C1771.06 24.403 1770.1 23.9523 1768.79 23.9523H1765.28Z"
      fill={color}
    />
    <path
      d="M1793.27 63.1404H1789.56L1788.34 53.857H1780.74L1779.52 63.1404H1776.09L1782.19 19.5316H1787.12L1793.27 63.1404ZM1784.5 25.0705L1781.31 49.6183H1787.78L1784.5 25.0705Z"
      fill={color}
    />
    <path
      d="M1805.22 35.8535V19.5316H1808.88V63.1404H1805.22V43.8281C1803.97 45.9864 1802.19 47.0699 1799.88 47.0699C1795.68 47.0699 1793.59 44.0361 1793.59 37.9772V19.5316H1797.25V37.2837C1797.25 40.8549 1798.42 42.6405 1800.77 42.6405C1802.11 42.6405 1803.19 42.0511 1804 40.8636C1804.81 39.6761 1805.22 38.0118 1805.22 35.8535Z"
      fill={color}
    />
    <path
      d="M1820.23 63.7645C1817.82 63.7645 1815.97 62.8283 1814.67 60.9647C1813.38 59.0924 1812.73 56.44 1812.73 52.9901V29.6211C1812.73 26.1713 1813.38 23.5275 1814.67 21.6812C1815.97 19.8349 1817.82 18.9075 1820.23 18.9075C1822.67 18.9075 1824.54 19.8349 1825.84 21.6812C1827.14 23.5275 1827.78 26.1799 1827.78 29.6211V52.9814C1827.78 56.4313 1827.14 59.0837 1825.84 60.956C1824.54 62.8283 1822.67 63.7645 1820.23 63.7645ZM1820.23 59.3351C1822.83 59.3351 1824.12 57.3241 1824.12 53.2935V29.3091C1824.12 25.3218 1822.83 23.3282 1820.23 23.3282C1817.67 23.3282 1816.39 25.3218 1816.39 29.3091V53.2935C1816.39 57.3241 1817.67 59.3351 1820.23 59.3351Z"
      fill={color}
    />
    <path
      d="M1834.86 63.1404H1831.63V19.5316H1836.92L1841.76 54.3597L1846.54 19.5316H1851.89V63.1404H1848.37V26.7521L1843.17 63.1317H1840.07L1834.86 26.7521V63.1404Z"
      fill={color}
    />
    <path d="M1860 63.1404H1856.29V56.8474H1860V63.1404Z" fill={color} />
  </svg>
);

export const BeforeUsingM = ({ bgColor, color }: IDisclaimerVector) => (
  <svg width="375" height="80" viewBox="0 0 375 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="375" height="80" fill={bgColor} />
    <path
      d="M16.1546 30.2128L19.0309 9.39819H20.3465V37.2562H19.192V16.281L16.3157 37.2562H15V9.39819H16.1546V30.2128Z"
      fill={color}
    />
    <path
      d="M22.9477 37.2562H21.8267V9.39819H23.6625L25.3373 31.647L26.9953 9.39819H28.848V37.2562H27.6296V14.0107L25.8273 37.2507H24.7533L22.9477 14.0107V37.2562Z"
      fill={color}
    />
    <path
      d="M34.9731 37.2562H30.3079V9.39819H34.9731V12.2222H31.5765V21.2978H34.3387V24.1218H31.5765V34.4267H34.9731V37.2562Z"
      fill={color}
    />
    <path
      d="M41.4105 9C42.2563 9 42.9041 9.59249 43.3538 10.7719C43.8035 11.9514 44.0284 13.6458 44.0284 15.8441V30.767C44.0284 32.9709 43.8035 34.6653 43.3538 35.8613C42.9041 37.0574 42.2563 37.6499 41.4105 37.6499C40.5748 37.6499 39.9338 37.0518 39.484 35.8613C39.0343 34.6708 38.8094 32.9709 38.8094 30.767V24.1223H37.2823V37.2567H36.0137V9.39868H37.2823V21.2983H38.8094V15.8441C38.8094 13.6402 39.0343 11.9514 39.484 10.7719C39.9338 9.58695 40.5748 9 41.4105 9ZM42.7598 30.9664V15.6447C42.7598 13.0976 42.31 11.824 41.4105 11.824C40.5211 11.824 40.0781 13.0976 40.0781 15.6447V30.9664C40.0781 33.5412 40.5211 34.8259 41.4105 34.8259C42.31 34.8259 42.7598 33.5412 42.7598 30.9664Z"
      fill={color}
    />
    <path d="M48.0424 37.2562H46.7737V12.2222H44.7566V9.39819H50.0561V12.2222H48.039V37.2562H48.0424Z" fill={color} />
    <path
      d="M53.325 37.6554C52.5228 37.6554 51.8986 37.074 51.4556 35.9056C51.0125 34.7373 50.791 33.1093 50.791 31.0107V15.6447C50.791 13.5516 51.0092 11.9181 51.4488 10.7498C51.8885 9.58141 52.5128 9 53.325 9C54.1372 9 54.7648 9.58141 55.2011 10.7498C55.6408 11.9181 55.859 13.5516 55.859 15.6447V18.5075H54.6574V15.4454C54.6574 14.277 54.54 13.3855 54.3084 12.7598C54.0768 12.1341 53.7579 11.824 53.3586 11.824C52.9592 11.824 52.6403 12.1341 52.4087 12.7598C52.1771 13.3855 52.0597 14.277 52.0597 15.4454V31.2045C52.0597 32.3728 52.1771 33.2699 52.4087 33.8901C52.6403 34.5158 52.9592 34.8259 53.3586 34.8259C53.7579 34.8259 54.0768 34.5158 54.3084 33.8901C54.54 33.2643 54.6574 32.3728 54.6574 31.2045V27.3062H55.859V31.0051C55.859 33.1038 55.6408 34.7317 55.2011 35.9001C54.7615 37.0685 54.1372 37.6554 53.325 37.6554Z"
      fill={color}
    />
    <path
      d="M62.0179 9.39819V37.2562H60.7493V24.6811H59.7088C59.1651 24.6811 58.7825 24.9856 58.5543 25.5947C58.326 26.2038 58.2119 27.2504 58.2119 28.7399V33.873C58.2119 35.1742 58.1616 36.2983 58.0643 37.2562H56.7654C56.8862 36.3813 56.9433 35.2517 56.9433 33.873V28.9005C56.9433 25.5836 57.3829 23.6456 58.2589 23.0919V23.0476C57.3796 22.3333 56.9433 20.4617 56.9433 17.4383V15.7273C56.9433 13.5511 57.148 11.9509 57.5541 10.932C57.9602 9.91316 58.6113 9.39819 59.5142 9.39819H62.0179ZM59.7122 21.8515H60.7526V12.2222H59.5511C59.0745 12.2222 58.7321 12.5212 58.5274 13.1192C58.3227 13.7173 58.2186 14.6918 58.2186 16.0429V18.0308C58.2186 19.3819 58.3395 20.3565 58.5778 20.9545C58.8127 21.5525 59.192 21.8515 59.7122 21.8515Z"
      fill={color}
    />
    <path d="M67.3173 37.2562H66.0486V9.39819H71.281V37.2562H70.0123V12.2222H67.3139V37.2562H67.3173Z" fill={color} />
    <path
      d="M74.0299 37.2562H72.7612V9.39819H75.2482C76.9599 9.39819 77.8158 11.6519 77.8158 16.1647V18.5513C77.8158 23.0365 76.9599 25.2791 75.2482 25.2791H74.0299V37.2562ZM74.0299 12.2222V22.4496H75.2482C75.7047 22.4496 76.0336 22.1672 76.2383 21.5968C76.443 21.0265 76.5471 20.063 76.5471 18.7119V15.9654C76.5471 14.6143 76.443 13.6508 76.2383 13.0805C76.0336 12.5101 75.7013 12.2222 75.2482 12.2222H74.0299Z"
      fill={color}
    />
    <path
      d="M81.3767 37.6554C80.541 37.6554 79.9 37.0574 79.4502 35.8669C79.0005 34.6708 78.7756 32.9764 78.7756 30.7726V15.8441C78.7756 13.6402 79.0005 11.9514 79.4502 10.7719C79.9 9.59249 80.541 9 81.3767 9C82.2225 9 82.8703 9.59249 83.32 10.7719C83.7697 11.9514 83.9946 13.6458 83.9946 15.8441V30.767C83.9946 32.9709 83.7697 34.6653 83.32 35.8613C82.8703 37.0574 82.2225 37.6554 81.3767 37.6554ZM81.3767 34.8259C82.2762 34.8259 82.7259 33.5412 82.7259 30.9664V15.6447C82.7259 13.0976 82.2762 11.824 81.3767 11.824C80.4873 11.824 80.0443 13.0976 80.0443 15.6447V30.9664C80.0443 33.5412 80.4873 34.8259 81.3767 34.8259Z"
      fill={color}
    />
    <path d="M88.0087 37.2562H86.74V12.2222H84.7229V9.39819H90.0224V12.2222H88.0053V37.2562H88.0087Z" fill={color} />
    <path
      d="M92.137 30.2128L95.0133 9.39819H96.329V37.2562H95.1744V16.281L92.2981 37.2562H90.9824V9.39819H92.137V30.2128Z"
      fill={color}
    />
    <path
      d="M100.474 37.2562H97.8088V9.39819H100.343C101.242 9.39819 101.893 9.90762 102.303 10.932C102.709 11.9564 102.914 13.5511 102.914 15.7273V16.8791C102.914 19.8526 102.491 21.7076 101.645 22.4496V22.5271C102.608 23.2192 103.092 25.1684 103.092 28.38V30.7665C103.088 35.0912 102.216 37.2562 100.474 37.2562ZM99.0741 12.2222V21.2978H100.148C100.668 21.2978 101.048 20.9988 101.286 20.4008C101.524 19.8027 101.645 18.8282 101.645 17.4771V16.0429C101.645 14.6918 101.541 13.7173 101.336 13.1192C101.132 12.5212 100.789 12.2222 100.313 12.2222H99.0741ZM99.0741 24.1218V34.4267H100.47C100.937 34.4267 101.276 34.1332 101.494 33.5518C101.712 32.9704 101.82 32.0124 101.82 30.689V28.3025C101.82 26.7908 101.699 25.7165 101.454 25.0798C101.209 24.443 100.796 24.1218 100.212 24.1218H99.0741Z"
      fill={color}
    />
    <path
      d="M106.73 37.6554C105.894 37.6554 105.253 37.0574 104.804 35.8669C104.354 34.6708 104.129 32.9764 104.129 30.7726V15.8441C104.129 13.6402 104.354 11.9514 104.804 10.7719C105.253 9.59249 105.894 9 106.73 9C107.576 9 108.224 9.59249 108.673 10.7719C109.123 11.9514 109.348 13.6458 109.348 15.8441V30.767C109.348 32.9709 109.123 34.6653 108.673 35.8613C108.224 37.0574 107.576 37.6554 106.73 37.6554ZM106.73 34.8259C107.629 34.8259 108.079 33.5412 108.079 30.9664V15.6447C108.079 13.0976 107.629 11.824 106.73 11.824C105.841 11.824 105.398 13.0976 105.398 15.6447V30.9664C105.398 33.5412 105.841 34.8259 106.73 34.8259Z"
      fill={color}
    />
    <path d="M111.949 37.2562H110.68V9.39819H115.913V37.2562H114.644V12.2222H111.945V37.2562H111.949Z" fill={color} />
    <path
      d="M119.863 37.6554C119.027 37.6554 118.386 37.0574 117.936 35.8669C117.487 34.6708 117.262 32.9764 117.262 30.7726V15.8441C117.262 13.6402 117.487 11.9514 117.936 10.7719C118.386 9.59249 119.027 9 119.863 9C120.709 9 121.356 9.59249 121.806 10.7719C122.256 11.9514 122.481 13.6458 122.481 15.8441V30.767C122.481 32.9709 122.256 34.6653 121.806 35.8613C121.356 37.0574 120.709 37.6554 119.863 37.6554ZM119.863 34.8259C120.762 34.8259 121.212 33.5412 121.212 30.9664V15.6447C121.212 13.0976 120.762 11.824 119.863 11.824C118.973 11.824 118.53 13.0976 118.53 15.6447V30.9664C118.53 33.5412 118.973 34.8259 119.863 34.8259Z"
      fill={color}
    />
    <path
      d="M129.405 37.2562H128.072L126.169 23.4075L125.082 28.0643V37.2562H123.813V9.39819H125.082V23.1306L128.056 9.39819H129.371L127.015 19.8636L129.405 37.2562Z"
      fill={color}
    />
    <path
      d="M135.809 37.2562H134.523L134.1 31.3258H131.466L131.043 37.2562H129.854L131.969 9.39819H133.677L135.809 37.2562ZM132.768 12.9365L131.664 28.6181H133.905L132.768 12.9365Z"
      fill={color}
    />
    <path
      d="M139.01 37.6554C138.154 37.6554 137.51 37.0629 137.077 35.8835C136.644 34.704 136.426 33.0262 136.426 30.8501V28.7404H137.628V30.9719C137.628 33.5467 138.077 34.8314 138.977 34.8314C139.866 34.8314 140.309 33.4526 140.309 30.695V28.2697C140.309 25.5122 139.833 24.1334 138.879 24.1334H138.195V21.3094H138.91C139.819 21.3094 140.276 20.0469 140.276 17.5274V15.6835C140.276 13.1087 139.843 11.824 138.977 11.824C138.121 11.824 137.691 13.0699 137.691 15.5672V17.2007H136.49V15.8053C136.49 13.6015 136.705 11.9181 137.131 10.7498C137.557 9.58141 138.188 9 139.024 9C139.836 9 140.46 9.58141 140.893 10.7498C141.326 11.9181 141.544 13.5904 141.544 15.7666V16.8795C141.544 19.9306 141.101 21.8133 140.212 22.5331V22.6106C141.121 23.3249 141.578 25.2131 141.578 28.2642V30.8501C141.578 33.0539 141.36 34.7373 140.927 35.9056C140.494 37.0685 139.853 37.6554 139.01 37.6554Z"
      fill={color}
    />
    <path
      d="M148.143 37.2562H146.857L146.434 31.3258H143.8L143.377 37.2562H142.189L144.303 9.39819H146.012L148.143 37.2562ZM145.105 12.9365L144.001 28.6181H146.243L145.105 12.9365Z"
      fill={color}
    />
    <path
      d="M150.385 37.2562H149.116V9.39819H150.385V21.2978H153.083V9.39819H154.352V37.2562H153.083V24.1218H150.385V37.2562Z"
      fill={color}
    />
    <path
      d="M156.987 30.2128L159.863 9.39819H161.179V37.2562H160.024V16.281L157.148 37.2562H155.832V9.39819H156.987V30.2128Z"
      fill={color}
    />
    <path
      d="M167.629 9.39819V37.2562H166.361V24.6811H165.32C164.776 24.6811 164.394 24.9856 164.166 25.5947C163.937 26.2038 163.823 27.2504 163.823 28.7399V33.873C163.823 35.1742 163.773 36.2983 163.676 37.2562H162.377C162.498 36.3813 162.555 35.2517 162.555 33.873V28.9005C162.555 25.5836 162.994 23.6456 163.87 23.0919V23.0476C162.991 22.3333 162.555 20.4617 162.555 17.4383V15.7273C162.555 13.5511 162.759 11.9509 163.165 10.932C163.572 9.91316 164.223 9.39819 165.125 9.39819H167.629ZM165.324 21.8515H166.364V12.2222H165.162C164.686 12.2222 164.343 12.5212 164.139 13.1192C163.934 13.7173 163.83 14.6918 163.83 16.0429V18.0308C163.83 19.3819 163.951 20.3565 164.189 20.9545C164.424 21.5525 164.803 21.8515 165.324 21.8515Z"
      fill={color}
    />
    <path d="M170.442 37.2568H169.156V33.2368H170.442V37.2568Z" fill={color} />
    <path d="M175.822 37.2562H174.553V9.39819H179.786V37.2562H178.517V12.2222H175.819V37.2562H175.822Z" fill={color} />
    <path
      d="M185.931 37.2562H181.266V9.39819H185.931V12.2222H182.534V21.2978H185.296V24.1218H182.534V34.4267H185.931V37.2562Z"
      fill={color}
    />
    <path
      d="M188.24 37.2562H186.971V9.39819H189.458C191.17 9.39819 192.026 11.6519 192.026 16.1647V18.5513C192.026 23.0365 191.17 25.2791 189.458 25.2791H188.24V37.2562ZM188.24 12.2222V22.4496H189.458C189.915 22.4496 190.244 22.1672 190.449 21.5968C190.653 21.0265 190.757 20.063 190.757 18.7119V15.9654C190.757 14.6143 190.653 13.6508 190.449 13.0805C190.244 12.5101 189.912 12.2222 189.458 12.2222H188.24Z"
      fill={color}
    />
    <path
      d="M197.778 37.2562H193.113V9.39819H197.778V12.2222H194.382V21.2978H197.144V24.1218H194.382V34.4267H197.778V37.2562Z"
      fill={color}
    />
    <path
      d="M204.605 9.39866V34.4327H205.417V40.9999H204.149V37.2567H199.581V40.9999H198.312V34.4272H199.205C199.463 33.6575 199.611 32.3839 199.644 30.6064L199.97 9.39313H204.605V9.39866ZM200.46 34.4272H203.336V12.2227H201.175L200.93 30.4902C200.9 32.1347 200.742 33.4471 200.46 34.4272Z"
      fill={color}
    />
    <path d="M210.23 37.2562H208.962V9.39819H214.194V37.2562H212.925V12.2222H210.227V37.2562H210.23Z" fill={color} />
    <path
      d="M216.943 37.2562H215.674V9.39819H218.161C219.873 9.39819 220.729 11.6519 220.729 16.1647V18.5513C220.729 23.0365 219.873 25.2791 218.161 25.2791H216.943V37.2562ZM216.943 12.2222V22.4496H218.161C218.618 22.4496 218.946 22.1672 219.151 21.5968C219.356 21.0265 219.46 20.063 219.46 18.7119V15.9654C219.46 14.6143 219.356 13.6508 219.151 13.0805C218.946 12.5101 218.614 12.2222 218.161 12.2222H216.943Z"
      fill={color}
    />
    <path
      d="M222.97 30.2128L225.847 9.39819H227.162V37.2562H226.008V16.281L223.132 37.2562H221.816V9.39819H222.97V30.2128Z"
      fill={color}
    />
    <path
      d="M229.764 37.2562H228.643V9.39819H230.478L232.153 31.647L233.811 9.39819H235.664V37.2562H234.446V14.0107L232.643 37.2507H231.569L229.764 14.0107V37.2562Z"
      fill={color}
    />
    <path
      d="M241.793 37.2562H237.127V9.39819H241.793V12.2222H238.396V21.2978H241.158V24.1218H238.396V34.4267H241.793V37.2562Z"
      fill={color}
    />
    <path
      d="M244.102 37.2562H242.833V9.39819H244.102V21.2978H246.8V9.39819H248.069V37.2562H246.8V24.1218H244.102V37.2562Z"
      fill={color}
    />
    <path
      d="M254.211 37.2562H249.545V9.39819H254.211V12.2222H250.814V21.2978H253.576V24.1218H250.814V34.4267H254.211V37.2562Z"
      fill={color}
    />
    <path
      d="M256.516 37.2562H255.248V9.39819H256.516V21.2978H259.215V9.39819H260.484V37.2562H259.215V24.1218H256.516V37.2562Z"
      fill={color}
    />
    <path
      d="M263.115 30.2128L265.991 9.39819H267.307V37.2562H266.152V16.281L263.276 37.2562H261.96V9.39819H263.115V30.2128Z"
      fill={color}
    />
    <path
      d="M273.452 37.2562H268.787V9.39819H273.452V12.2222H270.056V21.2978H272.818V24.1218H270.056V34.4267H273.452V37.2562Z"
      fill={color}
    />
    <path
      d="M275.614 37.2562H274.493V9.39819H276.329L278.003 31.647L279.661 9.39819H281.514V37.2562H280.296V14.0107L278.493 37.2507H277.419L275.614 14.0107V37.2562Z"
      fill={color}
    />
    <path
      d="M288.015 37.6554C287.179 37.6554 286.538 37.0574 286.088 35.8669C285.639 34.6708 285.414 32.9764 285.414 30.7726V15.8441C285.414 13.6402 285.639 11.9514 286.088 10.7719C286.538 9.59249 287.179 9 288.015 9C288.861 9 289.508 9.59249 289.958 10.7719C290.408 11.9514 290.633 13.6458 290.633 15.8441V30.767C290.633 32.9709 290.408 34.6653 289.958 35.8613C289.508 37.0574 288.861 37.6554 288.015 37.6554ZM288.015 34.8259C288.914 34.8259 289.364 33.5412 289.364 30.9664V15.6447C289.364 13.0976 288.914 11.824 288.015 11.824C287.126 11.824 286.682 13.0976 286.682 15.6447V30.9664C286.682 33.5412 287.129 34.8259 288.015 34.8259Z"
      fill={color}
    />
    <path
      d="M294.191 37.6554C293.335 37.6554 292.69 37.0629 292.257 35.8835C291.824 34.704 291.606 33.0262 291.606 30.8501V28.7404H292.808V30.9719C292.808 33.5467 293.257 34.8314 294.157 34.8314C295.046 34.8314 295.489 33.4526 295.489 30.695V28.2697C295.489 25.5122 295.013 24.1334 294.06 24.1334H293.378V21.3094H294.093C295.003 21.3094 295.459 20.0469 295.459 17.5274V15.6835C295.459 13.1087 295.026 11.824 294.16 11.824C293.304 11.824 292.875 13.0699 292.875 15.5672V17.2007H291.673V15.8053C291.673 13.6015 291.888 11.9181 292.314 10.7498C292.741 9.58141 293.372 9 294.207 9C295.02 9 295.644 9.58141 296.077 10.7498C296.51 11.9181 296.728 13.5904 296.728 15.7666V16.8795C296.728 19.9306 296.285 21.8133 295.395 22.5331V22.6106C296.305 23.3249 296.761 25.2131 296.761 28.2642V30.8501C296.761 33.0539 296.543 34.7373 296.11 35.9056C295.677 37.0685 295.036 37.6554 294.191 37.6554Z"
      fill={color}
    />
    <path
      d="M299.262 37.2562H297.993V9.39819H299.262V21.2978H301.96V9.39819H303.229V37.2562H301.96V24.1218H299.262V37.2562Z"
      fill={color}
    />
    <path
      d="M310.153 37.2562H308.867L308.444 31.3258H305.81L305.387 37.2562H304.199L306.313 9.39819H308.022L310.153 37.2562ZM307.112 12.9365L306.008 28.6181H308.25L307.112 12.9365Z"
      fill={color}
    />
    <path
      d="M316.718 37.2562H315.385L313.482 23.4075L312.395 28.0643V37.2562H311.126V9.39819H312.395V23.1306L315.369 9.39819H316.684L314.328 19.8636L316.718 37.2562Z"
      fill={color}
    />
    <path
      d="M319.919 37.6554C319.084 37.6554 318.443 37.0574 317.993 35.8669C317.543 34.6708 317.318 32.9764 317.318 30.7726V15.8441C317.318 13.6402 317.543 11.9514 317.993 10.7719C318.443 9.59249 319.084 9 319.919 9C320.765 9 321.413 9.59249 321.863 10.7719C322.312 11.9514 322.537 13.6458 322.537 15.8441V30.767C322.537 32.9709 322.312 34.6653 321.863 35.8613C321.413 37.0574 320.765 37.6554 319.919 37.6554ZM319.919 34.8259C320.819 34.8259 321.269 33.5412 321.269 30.9664V15.6447C321.269 13.0976 320.819 11.824 319.919 11.824C319.03 11.824 318.587 13.0976 318.587 15.6447V30.9664C318.587 33.5412 319.033 34.8259 319.919 34.8259Z"
      fill={color}
    />
    <path
      d="M324.991 37.2562H323.87V9.39819H325.706L327.381 31.647L329.039 9.39819H330.891V37.2562H329.673V14.0107L327.871 37.2507H326.797L324.991 14.0107V37.2562Z"
      fill={color}
    />
    <path
      d="M334.841 37.2562H332.354V9.39819H333.623V20.5004H334.841C336.553 20.5004 337.409 22.7541 337.409 27.267V30.4897C337.409 34.997 336.553 37.2562 334.841 37.2562ZM333.623 23.3244V34.4267H334.841C335.298 34.4267 335.627 34.1443 335.832 33.5684C336.036 32.9981 336.14 32.0346 336.14 30.6835V27.0621C336.14 25.711 336.036 24.7475 335.832 24.1772C335.627 23.6068 335.295 23.3189 334.841 23.3189H333.623V23.3244Z"
      fill={color}
    />
    <path d="M340.141 37.2562H338.872V12.2222H336.855V9.39819H342.155V12.2222H340.138V37.2562H340.141Z" fill={color} />
    <path
      d="M347.78 37.2562H343.115V9.39819H347.78V12.2222H344.383V21.2978H347.146V24.1218H344.383V34.4267H347.78V37.2562Z"
      fill={color}
    />
    <path
      d="M351.223 37.6554C350.421 37.6554 349.797 37.074 349.354 35.9056C348.911 34.7373 348.689 33.1093 348.689 31.0107V15.6447C348.689 13.5516 348.908 11.9181 349.347 10.7498C349.787 9.58141 350.411 9 351.223 9C352.036 9 352.663 9.58141 353.1 10.7498C353.539 11.9181 353.757 13.5516 353.757 15.6447V18.5075H352.556V15.4454C352.556 14.277 352.438 13.3855 352.207 12.7598C351.975 12.1341 351.656 11.824 351.257 11.824C350.858 11.824 350.539 12.1341 350.307 12.7598C350.076 13.3855 349.958 14.277 349.958 15.4454V31.2045C349.958 32.3728 350.076 33.2699 350.307 33.8901C350.539 34.5158 350.858 34.8259 351.257 34.8259C351.656 34.8259 351.975 34.5158 352.207 33.8901C352.438 33.2643 352.556 32.3728 352.556 31.2045V27.3062H353.757V31.0051C353.757 33.1038 353.539 34.7317 353.1 35.9001C352.663 37.0685 352.036 37.6554 351.223 37.6554Z"
      fill={color}
    />
    <path
      d="M357.433 37.2562H354.946V9.39819H356.214V20.5004H357.433C359.144 20.5004 360 22.7541 360 27.267V30.4897C360 34.997 359.144 37.2562 357.433 37.2562ZM356.214 23.3244V34.4267H357.433C357.889 34.4267 358.218 34.1443 358.423 33.5684C358.627 32.9981 358.731 32.0346 358.731 30.6835V27.0621C358.731 25.711 358.627 24.7475 358.423 24.1772C358.218 23.6068 357.886 23.3189 357.433 23.3189H356.214V23.3244Z"
      fill={color}
    />
    <path
      d="M18.175 70.0371C17.1699 70.0371 16.3878 69.5212 15.8327 68.4847C15.2775 67.4482 15 66.0039 15 64.142V50.5098C15 48.6529 15.2733 47.2037 15.8242 46.1672C16.3751 45.1306 17.1573 44.6148 18.175 44.6148C19.1927 44.6148 19.9791 45.1306 20.5258 46.1672C21.0767 47.2037 21.3501 48.6529 21.3501 50.5098V53.0496H19.8445V50.333C19.8445 49.2964 19.6974 48.5055 19.4072 47.9504C19.117 47.3953 18.7175 47.1202 18.2171 47.1202C17.7166 47.1202 17.3171 47.3953 17.027 47.9504C16.7368 48.5055 16.5896 49.2964 16.5896 50.333V64.314C16.5896 65.3505 16.7368 66.1463 17.027 66.6965C17.3171 67.2517 17.7166 67.5268 18.2171 67.5268C18.7175 67.5268 19.117 67.2517 19.4072 66.6965C19.6974 66.1414 19.8445 65.3505 19.8445 64.314V60.8556H21.3501V64.1371C21.3501 65.999 21.0767 67.4432 20.5258 68.4798C19.9791 69.5163 19.1927 70.0371 18.175 70.0371Z"
      fill={color}
    />
    <path
      d="M27.5023 63.4351L31.1063 44.969H32.7548V69.6838H31.3082V51.0752L27.7042 69.6838H26.0557V44.969H27.5023V63.4351Z"
      fill={color}
    />
    <path
      d="M36.1992 69.6838H34.6096V44.969H36.1992V55.526H39.5803V44.969H41.17V69.6838H39.5803V58.0314H36.1992V69.6838Z"
      fill={color}
    />
    <path
      d="M46.0354 70.0371C45.0303 70.0371 44.2481 69.5212 43.693 68.4847C43.1379 67.4482 42.8604 66.0039 42.8604 64.142V50.5098C42.8604 48.6529 43.1337 47.2037 43.6846 46.1672C44.2355 45.1306 45.0177 44.6148 46.0354 44.6148C47.0531 44.6148 47.8395 45.1306 48.3862 46.1672C48.9371 47.2037 49.2105 48.6529 49.2105 50.5098V53.0496H47.7049V50.333C47.7049 49.2964 47.5577 48.5055 47.2676 47.9504C46.9774 47.3953 46.5779 47.1202 46.0774 47.1202C45.577 47.1202 45.1775 47.3953 44.8873 47.9504C44.5971 48.5055 44.4499 49.2964 44.4499 50.333V64.314C44.4499 65.3505 44.5971 66.1463 44.8873 66.6965C45.1775 67.2517 45.577 67.5268 46.0774 67.5268C46.5779 67.5268 46.9774 67.2517 47.2676 66.6965C47.5577 66.1414 47.7049 65.3505 47.7049 64.314V60.8556H49.2105V64.1371C49.2105 65.999 48.9371 67.4432 48.3862 68.4798C47.8353 69.5163 47.0531 70.0371 46.0354 70.0371Z"
      fill={color}
    />
    <path d="M54.1812 69.6838H52.5916V47.4744H50.0642V44.969H56.7045V47.4744H54.177V69.6838H54.1812Z" fill={color} />
    <path
      d="M59.4969 69.6838H57.9072V44.969H61.0234C63.1682 44.969 64.2405 46.9684 64.2405 50.9721V53.0894C64.2405 57.0685 63.1682 59.0581 61.0234 59.0581H59.4969V69.6838ZM59.4969 47.4744V56.5478H61.0234C61.5954 56.5478 62.0075 56.2972 62.264 55.7913C62.5205 55.2853 62.6509 54.4305 62.6509 53.2318V50.7952C62.6509 49.5966 62.5205 48.7418 62.264 48.2358C62.0075 47.7298 61.5911 47.4744 61.0234 47.4744H59.4969Z"
      fill={color}
    />
    <path
      d="M68.6394 59.4406L70.5738 44.9634H72.0794L69.1272 65.9693C68.9254 67.4284 68.61 68.4698 68.1894 69.0937C67.7689 69.7176 67.1717 70.0271 66.3979 70.0271C65.9774 70.0271 65.6031 69.8993 65.2793 69.639V67.2024C65.5779 67.4136 65.8849 67.5217 66.1961 67.5217C66.604 67.5217 66.911 67.3792 67.1128 67.0992C67.3147 66.8192 67.4703 66.323 67.5796 65.6156L67.8025 64.0289L64.8293 44.9634H66.4568L68.6394 59.4406Z"
      fill={color}
    />
    <path
      d="M80.2252 69.6838H78.5556L76.1712 57.3976L74.8086 61.5291V69.6838H73.219V44.969H74.8086V57.152L78.5346 44.969H80.1831L77.2309 54.2536L80.2252 69.6838Z"
      fill={color}
    />
    <path
      d="M89.1869 72.9998H87.5973V69.6789H81.428V44.969H83.0176V67.1784H86.3987V44.969H87.9883V67.1784H89.1911V72.9998H89.1869Z"
      fill={color}
    />
    <path
      d="M91.8363 63.4351L95.4403 44.969H97.0888V69.6838H95.6422V51.0752L92.0382 69.6838H90.3896V44.969H91.8363V63.4351Z"
      fill={color}
    />
    <path
      d="M104.789 69.6838H98.9431V44.969H104.789V47.4744H100.533V55.526H103.994V58.0314H100.533V67.1735H104.789V69.6838Z"
      fill={color}
    />
    <path
      d="M107.535 63.4348L111.139 44.9687H112.787V69.6835H111.341V51.075L107.737 69.6835H106.088V44.9687H107.535V63.4348ZM107.291 39H108.288C108.367 40.2478 108.75 40.8717 109.427 40.8717C110.134 40.8717 110.529 40.2478 110.609 39H111.585C111.555 40.179 111.357 41.0976 110.983 41.7559C110.609 42.4142 110.092 42.7433 109.427 42.7433C108.763 42.7433 108.245 42.4191 107.88 41.7707C107.514 41.1271 107.32 40.1987 107.291 39Z"
      fill={color}
    />
    <path
      d="M119.306 63.4351L122.91 44.969H124.558V69.6838H123.111V51.0752L119.507 69.6838H117.859V44.969H119.306V63.4351Z"
      fill={color}
    />
    <path
      d="M126.291 69.7176C126.101 69.7176 125.929 69.6832 125.782 69.6095V67.1729C125.971 67.222 126.08 67.2417 126.106 67.2417C126.606 67.2417 126.968 66.9813 127.186 66.4655C127.405 65.9497 127.519 65.0556 127.531 63.7833L127.918 44.9634H133.721V69.6783H132.132V47.4737H129.424L129.117 63.6801C129.075 65.7777 128.843 67.3055 128.414 68.2684C127.994 69.2362 127.283 69.7176 126.291 69.7176Z"
      fill={color}
    />
    <path
      d="M137.023 63.4351L140.627 44.969H142.275V69.6838H140.829V51.0752L137.225 69.6838H135.576V44.969H137.023V63.4351Z"
      fill={color}
    />
    <path d="M148.937 69.6838H147.347V44.969H153.903V69.6838H152.314V47.4744H148.933V69.6838H148.937Z" fill={color} />
    <path
      d="M157.348 69.6838H155.758V44.969H158.874C161.019 44.969 162.091 46.9684 162.091 50.9721V53.0894C162.091 57.0685 161.019 59.0581 158.874 59.0581H157.348V69.6838ZM157.348 47.4744V56.5478H158.874C159.446 56.5478 159.858 56.2972 160.115 55.7913C160.371 55.2853 160.502 54.4305 160.502 53.2318V50.7952C160.502 49.5966 160.371 48.7418 160.115 48.2358C159.858 47.7298 159.442 47.4744 158.874 47.4744H157.348Z"
      fill={color}
    />
    <path
      d="M166.553 70.0371C165.506 70.0371 164.702 69.5065 164.139 68.4503C163.575 67.3892 163.294 65.886 163.294 63.9308V50.6867C163.294 48.7315 163.575 47.2332 164.139 46.1868C164.702 45.1404 165.506 44.6148 166.553 44.6148C167.613 44.6148 168.424 45.1404 168.988 46.1868C169.551 47.2332 169.833 48.7364 169.833 50.6867V63.9259C169.833 65.8811 169.551 67.3843 168.988 68.4454C168.424 69.5065 167.613 70.0371 166.553 70.0371ZM166.553 67.5268C167.68 67.5268 168.243 66.3871 168.243 64.1027V50.5098C168.243 48.2501 167.68 47.1202 166.553 47.1202C165.438 47.1202 164.883 48.2501 164.883 50.5098V64.1027C164.883 66.3871 165.438 67.5268 166.553 67.5268Z"
      fill={color}
    />
    <path
      d="M178.509 69.6838H176.839L174.455 57.3976L173.092 61.5291V69.6838H171.503V44.969H173.092V57.152L176.818 44.969H178.467L175.515 54.2536L178.509 69.6838Z"
      fill={color}
    />
    <path
      d="M182.521 70.0371C181.473 70.0371 180.67 69.5065 180.107 68.4503C179.543 67.3892 179.261 65.886 179.261 63.9308V50.6867C179.261 48.7315 179.543 47.2332 180.107 46.1868C180.67 45.1404 181.473 44.6148 182.521 44.6148C183.58 44.6148 184.392 45.1404 184.956 46.1868C185.519 47.2332 185.801 48.7364 185.801 50.6867V63.9259C185.801 65.8811 185.519 67.3843 184.956 68.4454C184.392 69.5065 183.58 70.0371 182.521 70.0371ZM182.521 67.5268C183.648 67.5268 184.211 66.3871 184.211 64.1027V50.5098C184.211 48.2501 183.648 47.1202 182.521 47.1202C181.406 47.1202 180.851 48.2501 180.851 50.5098V64.1027C180.851 66.3871 181.406 67.5268 182.521 67.5268Z"
      fill={color}
    />
    <path
      d="M189.056 69.6838H187.466V44.969H189.056V55.526H192.437V44.969H194.027V69.6838H192.437V58.0314H189.056V69.6838Z"
      fill={color}
    />
    <path
      d="M198.893 70.0371C197.887 70.0371 197.105 69.5212 196.55 68.4847C195.995 67.4482 195.718 66.0039 195.718 64.142V50.5098C195.718 48.6529 195.991 47.2037 196.542 46.1672C197.093 45.1306 197.875 44.6148 198.893 44.6148C199.91 44.6148 200.697 45.1306 201.243 46.1672C201.794 47.2037 202.068 48.6529 202.068 50.5098V53.0496H200.562V50.333C200.562 49.2964 200.415 48.5055 200.125 47.9504C199.835 47.3953 199.435 47.1202 198.935 47.1202C198.434 47.1202 198.035 47.3953 197.744 47.9504C197.454 48.5055 197.307 49.2964 197.307 50.333V64.314C197.307 65.3505 197.454 66.1463 197.744 66.6965C198.035 67.2517 198.434 67.5268 198.935 67.5268C199.435 67.5268 199.835 67.2517 200.125 66.6965C200.415 66.1414 200.562 65.3505 200.562 64.314V60.8556H202.068V64.1371C202.068 65.999 201.794 67.4432 201.243 68.4798C200.697 69.5163 199.91 70.0371 198.893 70.0371Z"
      fill={color}
    />
    <path
      d="M206.794 59.4406L208.729 44.9634H210.234L207.282 65.9693C207.08 67.4284 206.765 68.4698 206.344 69.0937C205.924 69.7176 205.327 70.0271 204.553 70.0271C204.132 70.0271 203.758 69.8993 203.434 69.639V67.2024C203.733 67.4136 204.04 67.5217 204.351 67.5217C204.759 67.5217 205.066 67.3792 205.268 67.0992C205.47 66.8192 205.625 66.323 205.734 65.6156L205.957 64.0289L202.984 44.9634H204.612L206.794 59.4406Z"
      fill={color}
    />
    <path
      d="M210.133 69.7176C209.944 69.7176 209.771 69.6832 209.624 69.6095V67.1729C209.814 67.222 209.923 67.2417 209.948 67.2417C210.449 67.2417 210.81 66.9813 211.029 66.4655C211.248 65.9497 211.361 65.0556 211.374 63.7833L211.761 44.9634H217.564V69.6783H215.974V47.4737H213.266L212.959 63.6801C212.917 65.7777 212.686 67.3055 212.257 68.2684C211.836 69.2362 211.126 69.7176 210.133 69.7176Z"
      fill={color}
    />
    <path
      d="M222.535 69.6838H219.418V44.969H221.008V54.8186H222.535C224.679 54.8186 225.752 56.818 225.752 60.8217V63.6807C225.752 67.6795 224.679 69.6838 222.535 69.6838ZM221.008 57.324V67.1735H222.535C223.107 67.1735 223.519 66.923 223.775 66.4121C224.032 65.9061 224.162 65.0513 224.162 63.8527V60.6399C224.162 59.4412 224.032 58.5865 223.775 58.0805C223.519 57.5745 223.102 57.319 222.535 57.319H221.008V57.324Z"
      fill={color}
    />
    <path d="M229.175 69.6838H227.585V47.4744H225.058V44.969H231.698V47.4744H229.171V69.6838H229.175Z" fill={color} />
    <path
      d="M234.348 63.4351L237.952 44.969H239.6V69.6838H238.154V51.0752L234.55 69.6838H232.901V44.969H234.348V63.4351Z"
      fill={color}
    />
    <path
      d="M243.045 69.6838H241.455V44.969H244.571C246.716 44.969 247.788 46.9684 247.788 50.9721V53.0894C247.788 57.0685 246.716 59.0581 244.571 59.0581H243.045V69.6838ZM243.045 47.4744V56.5478H244.571C245.143 56.5478 245.555 56.2972 245.812 55.7913C246.068 55.2853 246.199 54.4305 246.199 53.2318V50.7952C246.199 49.5966 246.068 48.7418 245.812 48.2358C245.555 47.7298 245.139 47.4744 244.571 47.4744H243.045Z"
      fill={color}
    />
    <path
      d="M252.187 59.4406L254.122 44.9634H255.627L252.675 65.9693C252.473 67.4284 252.158 68.4698 251.737 69.0937C251.317 69.7176 250.719 70.0271 249.946 70.0271C249.525 70.0271 249.151 69.8993 248.827 69.639V67.2024C249.125 67.4136 249.433 67.5217 249.744 67.5217C250.152 67.5217 250.459 67.3792 250.66 67.0992C250.862 66.8192 251.018 66.323 251.127 65.6156L251.35 64.0289L248.377 44.9634H250.004L252.187 59.4406Z"
      fill={color}
    />
    <path
      d="M258.213 63.4348L261.817 44.9687H263.466V69.6835H262.019V51.075L258.415 69.6835H256.767V44.9687H258.213V63.4348ZM257.969 39H258.966C259.046 40.2478 259.429 40.8717 260.106 40.8717C260.812 40.8717 261.207 40.2478 261.287 39H262.263C262.234 40.179 262.036 41.0976 261.662 41.7559C261.287 42.4142 260.77 42.7433 260.106 42.7433C259.441 42.7433 258.924 42.4191 258.558 41.7707C258.196 41.1271 257.999 40.1987 257.969 39Z"
      fill={color}
    />
    <path d="M268.806 69.6838H267.217V47.4744H264.689V44.969H271.33V47.4744H268.802V69.6838H268.806Z" fill={color} />
    <path
      d="M278.378 69.6838H272.532V44.969H278.378V47.4744H274.122V55.526H277.583V58.0314H274.122V67.1735H278.378V69.6838Z"
      fill={color}
    />
    <path
      d="M282.693 70.0371C281.688 70.0371 280.906 69.5212 280.35 68.4847C279.795 67.4482 279.518 66.0039 279.518 64.142V50.5098C279.518 48.6529 279.791 47.2037 280.342 46.1672C280.893 45.1306 281.675 44.6148 282.693 44.6148C283.711 44.6148 284.497 45.1306 285.044 46.1672C285.595 47.2037 285.868 48.6529 285.868 50.5098V53.0496H284.362V50.333C284.362 49.2964 284.215 48.5055 283.925 47.9504C283.635 47.3953 283.235 47.1202 282.735 47.1202C282.234 47.1202 281.835 47.3953 281.545 47.9504C281.255 48.5055 281.107 49.2964 281.107 50.333V64.314C281.107 65.3505 281.255 66.1463 281.545 66.6965C281.835 67.2517 282.234 67.5268 282.735 67.5268C283.235 67.5268 283.635 67.2517 283.925 66.6965C284.215 66.1414 284.362 65.3505 284.362 64.314V60.8556H285.868V64.1371C285.868 65.999 285.595 67.4432 285.044 68.4798C284.497 69.5163 283.711 70.0371 282.693 70.0371Z"
      fill={color}
    />
    <path
      d="M290.472 69.6838H287.356V44.969H288.946V54.8186H290.472C292.617 54.8186 293.69 56.818 293.69 60.8217V63.6807C293.69 67.6795 292.617 69.6838 290.472 69.6838ZM288.946 57.324V67.1735H290.472C291.044 67.1735 291.456 66.923 291.713 66.4121C291.97 65.9061 292.1 65.0513 292.1 63.8527V60.6399C292.1 59.4412 291.97 58.5865 291.713 58.0805C291.456 57.5745 291.04 57.319 290.472 57.319H288.946V57.324Z"
      fill={color}
    />
    <path
      d="M301.305 70.0371C300.3 70.0371 299.518 69.5212 298.963 68.4847C298.408 67.4482 298.13 66.0039 298.13 64.142V50.5098C298.13 48.6529 298.404 47.2037 298.955 46.1672C299.506 45.1306 300.288 44.6148 301.305 44.6148C302.323 44.6148 303.11 45.1306 303.656 46.1672C304.207 47.2037 304.481 48.6529 304.481 50.5098V53.0496H302.975V50.333C302.975 49.2964 302.828 48.5055 302.538 47.9504C302.247 47.3953 301.848 47.1202 301.347 47.1202C300.847 47.1202 300.448 47.3953 300.157 47.9504C299.867 48.5055 299.72 49.2964 299.72 50.333V64.314C299.72 65.3505 299.867 66.1463 300.157 66.6965C300.448 67.2517 300.847 67.5268 301.347 67.5268C301.848 67.5268 302.247 67.2517 302.538 66.6965C302.828 66.1414 302.975 65.3505 302.975 64.314V60.8556H304.481V64.1371C304.481 65.999 304.207 67.4432 303.656 68.4798C303.11 69.5163 302.323 70.0371 301.305 70.0371Z"
      fill={color}
    />
    <path
      d="M312.526 69.6838H309.187V44.969H312.362C313.489 44.969 314.304 45.4209 314.818 46.3298C315.326 47.2386 315.583 48.6534 315.583 50.584V51.6058C315.583 54.2438 315.053 55.8895 313.993 56.5478V56.6166C315.2 57.2306 315.806 58.9598 315.806 61.8091V63.9264C315.806 67.763 314.712 69.6838 312.526 69.6838ZM310.776 47.4744V55.526H312.122C312.774 55.526 313.249 55.2607 313.547 54.7302C313.846 54.1996 313.997 53.335 313.997 52.1363V50.864C313.997 49.6654 313.867 48.8008 313.611 48.2702C313.354 47.7397 312.925 47.4744 312.328 47.4744H310.776ZM310.776 58.0314V67.1735H312.526C313.11 67.1735 313.535 66.9132 313.808 66.3974C314.082 65.8815 314.216 65.0317 314.216 63.8576V61.7403C314.216 60.3992 314.065 59.4462 313.758 58.8812C313.451 58.3163 312.934 58.0314 312.202 58.0314H310.776Z"
      fill={color}
    />
    <path
      d="M318.863 69.6838H317.273V44.969H320.39C322.534 44.969 323.607 46.9684 323.607 50.9721V53.0894C323.607 57.0685 322.534 59.0581 320.39 59.0581H318.863V69.6838ZM318.863 47.4744V56.5478H320.39C320.962 56.5478 321.374 56.2972 321.63 55.7913C321.887 55.2853 322.017 54.4305 322.017 53.2318V50.7952C322.017 49.5966 321.887 48.7418 321.63 48.2358C321.374 47.7298 320.957 47.4744 320.39 47.4744H318.863Z"
      fill={color}
    />
    <path
      d="M331.021 69.6838H329.41L328.88 64.4225H325.579L325.049 69.6838H323.561L326.21 44.969H328.35L331.021 69.6838ZM327.211 48.1081L325.827 62.0203H328.636L327.211 48.1081Z"
      fill={color}
    />
    <path
      d="M336.21 54.2192V44.969H337.8V69.6838H336.21V58.7388C335.668 59.962 334.894 60.576 333.889 60.576C332.068 60.576 331.159 58.8567 331.159 55.4228V44.969H332.749V55.0298C332.749 57.0538 333.258 58.0657 334.276 58.0657C334.86 58.0657 335.327 57.7317 335.68 57.0587C336.033 56.3857 336.21 55.4425 336.21 54.2192Z"
      fill={color}
    />
    <path
      d="M342.729 70.0371C341.682 70.0371 340.878 69.5065 340.315 68.4503C339.751 67.3892 339.469 65.886 339.469 63.9308V50.6867C339.469 48.7315 339.751 47.2332 340.315 46.1868C340.878 45.1404 341.682 44.6148 342.729 44.6148C343.788 44.6148 344.6 45.1404 345.164 46.1868C345.727 47.2332 346.009 48.7364 346.009 50.6867V63.9259C346.009 65.8811 345.727 67.3843 345.164 68.4454C344.6 69.5065 343.788 70.0371 342.729 70.0371ZM342.729 67.5268C343.856 67.5268 344.419 66.3871 344.419 64.1027V50.5098C344.419 48.2501 343.856 47.1202 342.729 47.1202C341.614 47.1202 341.059 48.2501 341.059 50.5098V64.1027C341.059 66.3871 341.614 67.5268 342.729 67.5268Z"
      fill={color}
    />
    <path
      d="M349.083 69.6838H347.678V44.969H349.979L352.077 64.7075L354.155 44.969H356.476V69.6838H354.949V49.0611L352.691 69.6789H351.345L349.083 49.0611V69.6838Z"
      fill={color}
    />
    <path d="M360 69.6832H358.389V66.1167H360V69.6832Z" fill={color} />
  </svg>
);
