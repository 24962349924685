import React from 'react';
import { withTheme } from 'styled-components';
import Icon, { IIcon } from './Icon';

const IconLogo: React.FC<IIcon> = ({ theme = {}, bgColor, color, ...props }) => (
  <Icon viewBox="0 0 112 172" fill="none" {...props}>
    <path
      d="M0 0H112V164C112 168.418 108.418 172 104 172H0V0Z"
      fill={theme.logo?.bgColor || theme.bgColor || bgColor}
    />
    <path
      d="M14.4561 68.4951H28.0604C31.8443 68.4951 34.777 69.5505 36.7321 71.4914C38.3898 73.1371 39.2818 75.4582 39.2818 78.3291C39.2818 83.1005 36.6873 86.0968 32.9033 87.4878L40.1738 98.0416H32.5245L26.0605 88.5877H21.0057V98.0416H14.4561V68.4951ZM27.6368 82.8458C30.826 82.8458 32.6549 81.1556 32.6549 78.5839C32.6549 75.7978 30.6998 74.3624 27.5105 74.3624H21.0057V82.8458H27.6368Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M45.1921 68.4951H67.6391V74.2774H51.6969V80.27H65.7288V86.0523H51.6969V92.2592H67.8509V98.0416H45.1921V68.4951Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M72.7834 93.1003L89.1492 74.1925H73.2926V68.4951H97.5684V73.4323L81.2026 92.3401H97.5684V98.0375H72.7834V93.1003Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M22.3906 103.917H26.3619L34.7526 123.492H30.2477L28.4596 119.133H20.1789L18.3908 123.492H14L22.3906 103.917ZM26.9199 115.357L24.3172 109.053L21.7145 115.357H26.9199Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M37.1842 113.775C37.1842 108.252 41.518 103.723 47.4485 103.723C50.9718 103.723 53.0979 104.669 55.1386 106.388L52.4259 109.635C50.9147 108.385 49.5747 107.666 47.3059 107.666C44.1737 107.666 41.685 110.415 41.685 113.771C41.685 117.326 44.1452 119.934 47.6155 119.934C49.1796 119.934 50.5807 119.546 51.6683 118.769V115.991H47.3345V112.3H55.8351V120.739C53.8229 122.433 51.0532 123.82 47.4729 123.82C41.3796 123.824 37.1842 119.578 37.1842 113.775Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M60.2584 104.059H69.207C71.6957 104.059 73.6264 104.755 74.9135 106.028C76.0051 107.112 76.5916 108.636 76.5916 110.525C76.5916 113.663 74.8849 115.632 72.3963 116.55L77.1781 123.488H72.1437L67.8914 117.269H64.5637V123.488H60.2584V104.059ZM68.926 113.497C71.0236 113.497 72.2252 112.385 72.2252 110.695C72.2252 108.863 70.9381 107.917 68.8404 107.917H64.5637V113.497H68.926Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M80.4488 115.163V104.059H84.7582V115.054C84.7582 118.22 86.3508 119.857 88.982 119.857C91.6092 119.857 93.2059 118.276 93.2059 115.195V104.063H97.5111V115.029C97.5111 120.917 94.1834 123.804 88.925 123.804C83.6625 123.796 80.4488 120.88 80.4488 115.163Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M14.4887 140.569L21.5678 132.388H14.7087V129.922H25.2132V132.057L18.1301 140.241H25.2132V142.708H14.4887V140.569Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M49.9617 129.926H52.7925V135.514L58.0143 129.926H61.4357L56.1977 135.348L61.6801 142.708H58.279L54.2874 137.265L52.7966 138.797V142.704H49.9658V129.926H49.9617Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M89.8702 129.833H92.481L98.0001 142.703H95.039L93.8618 139.836H88.4161L87.2389 142.703H84.3511L89.8702 129.833ZM92.8476 137.354L91.1369 133.209L89.4262 137.354H92.8476Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M14.7007 14H34.4106C45.9212 14 52.8822 20.773 52.8822 30.6959C52.8822 41.7794 44.1983 47.5253 33.376 47.5253H25.3112V61.8922H14.7007V14ZM33.7222 38.1523C39.0295 38.1523 42.1291 35.0064 42.1291 30.7647C42.1291 26.0458 38.8217 23.5105 33.5145 23.5105H25.3153V38.1523H33.7222V38.1523Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M60.739 14H97.1283V23.373H71.2844V33.0897H94.0287V42.4628H71.2844V52.5192H97.4745V61.8922H60.739V14Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M14.4305 154.462C14.4305 152.517 15.9579 150.924 18.0433 150.924C19.2856 150.924 20.031 151.255 20.7519 151.862L19.7988 153.006C19.2693 152.566 18.7968 152.311 17.9985 152.311C16.8947 152.311 16.019 153.277 16.019 154.462C16.019 155.711 16.8865 156.633 18.1044 156.633C18.6543 156.633 19.1471 156.496 19.5341 156.221V155.242H18.0026V153.944H20.9963V156.916C20.2876 157.511 19.3141 158 18.0515 158C15.909 158 14.4305 156.504 14.4305 154.462Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M28.8548 151.038H30.37V156.513H33.8077V157.879H28.8548V151.038Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M43.7093 150.989H45.1064L48.0594 157.879H46.4749L45.8436 156.347H42.9272L42.2959 157.879H40.7522L43.7093 150.989ZM45.3059 155.016L44.3895 152.796L43.473 155.016H45.3059Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M55.3178 156.739L59.1099 152.36H55.436V151.042H61.0569V152.186L57.2648 156.565H61.0569V157.883H55.3178V156.739Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M68.6776 156.318H70.2742V157.883H68.6776V156.318Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M78.111 151.038H81.2636C82.1393 151.038 82.8195 151.284 83.2716 151.733C83.6545 152.113 83.8622 152.651 83.8622 153.318C83.8622 154.422 83.2635 155.118 82.3837 155.441L84.0659 157.883H82.2941L80.7992 155.692H79.6262V157.883H78.111V151.038V151.038ZM81.1617 154.361C81.899 154.361 82.3226 153.969 82.3226 153.375C82.3226 152.728 81.8705 152.396 81.1332 152.396H79.6262V154.361H81.1617Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
    <path
      d="M91.5198 154.952V151.042H93.035V154.911C93.035 156.027 93.5971 156.601 94.5217 156.601C95.4463 156.601 96.0083 156.043 96.0083 154.96V151.042H97.5236V154.903C97.5236 156.973 96.3505 157.992 94.5013 157.992C92.6521 157.992 91.5198 156.961 91.5198 154.952Z"
      fill={theme.logo?.color || theme.body?.color || color}
    />
  </Icon>
);

export default withTheme(IconLogo);
