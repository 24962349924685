import React from 'react';
import styled from 'styled-components';
import { EnumPages } from '../../constants';
import colors from 'theme/colors';
import Flex from 'Components/Flex';
import Disclaimer from './Disclaimer';
import Info from './Info';
import Note from './Note';

interface IFooterTheme {
  bgColor: string;
  color: string;
}

export const FooterThemes: { [key: string]: IFooterTheme } = {
  [EnumPages.PROBLEM]: {
    bgColor: colors.green,
    color: colors.paleGreen,
  },
  [EnumPages.SYMPTOMS]: {
    bgColor: colors.red,
    color: colors.paleRed,
  },
  [EnumPages.TEST]: {
    bgColor: colors.purple,
    color: colors.palePurple,
  },
  [EnumPages.ADVICES]: {
    bgColor: colors.mint,
    color: colors.paleMint,
  },
  [EnumPages.NIGHTDROPS]: {
    bgColor: colors.deepBlue,
    color: colors.moonYellow,
  },
  [EnumPages.MAIN]: {
    bgColor: colors.blue,
    color: colors.paleBlue,
  },
};

interface IFooter {
  theme: IFooterTheme;
}

const Container = styled(Flex).attrs({
  column: true,
})`
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
`;

const Footer: React.FC<IFooter> = ({ theme }) => (
  <Container {...theme}>
    <Info />
    <Disclaimer color={theme.color} />
    <Note />
  </Container>
);

export default Footer;
