import { IDisclaimerVector } from '.';
import React from 'react';

export const SeeDoctorPc = ({ bgColor, color }: IDisclaimerVector) => (
  <svg width="1920" height="80" viewBox="0 0 1920 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1920 0H0V80H1920V0Z" fill={bgColor} />
    <path
      d="M88.5195 62.9999H65.8395V17.6399H88.5195V21.2687H69.9867V38.1815H85.2795V41.8103H69.9867V59.3711H88.5195V62.9999Z"
      fill={color}
    />
    <path
      d="M106.929 63.6479C103.085 63.6479 100.061 62.5679 97.8573 60.4079C95.6973 58.2479 94.6173 55.2024 94.6173 51.2711V29.3687C94.6173 25.4375 95.6973 22.3919 97.8573 20.2319C100.061 18.0719 103.085 16.9919 106.929 16.9919C110.947 16.9919 114.036 18.0719 116.196 20.2319C118.356 22.3919 119.436 25.4375 119.436 29.3687V32.3495H115.483V29.1743C115.483 26.4095 114.727 24.2927 113.215 22.8239C111.746 21.3551 109.673 20.6207 106.994 20.6207C104.402 20.6207 102.372 21.3767 100.903 22.8887C99.4773 24.3575 98.7645 26.4527 98.7645 29.1743V51.4655C98.7645 54.1871 99.4773 56.3039 100.903 57.8159C102.372 59.2847 104.402 60.0191 106.994 60.0191C109.673 60.0191 111.746 59.2847 113.215 57.8159C114.727 56.3471 115.483 54.2303 115.483 51.4655V46.9943H119.436V51.2711C119.436 55.2024 118.356 58.2479 116.196 60.4079C114.036 62.5679 110.947 63.6479 106.929 63.6479Z"
      fill={color}
    />
    <path d="M139.411 62.9999H135.264V21.2687H124.442V17.6399H150.297V21.2687H139.411V62.9999Z" fill={color} />
    <path
      d="M168.766 62.9999H156.648V17.6399H160.795V36.8855H168.766C173.043 36.8855 176.196 37.8791 178.227 39.8663C180.3 41.8535 181.337 44.9423 181.337 49.1327V50.6231C181.337 54.8135 180.3 57.924 178.227 59.9543C176.196 61.9847 173.043 62.9999 168.766 62.9999ZM160.795 40.5143V59.3711H168.701C171.639 59.3711 173.777 58.7015 175.116 57.3623C176.499 55.9799 177.19 53.8199 177.19 50.8823V48.9383C177.19 46.0007 176.499 43.8623 175.116 42.5231C173.777 41.1839 171.639 40.5143 168.701 40.5143H160.795Z"
      fill={color}
    />
    <path d="M204.586 62.9999H200.439V17.6399H225.905V62.9999H221.628V21.2687H204.586V62.9999Z" fill={color} />
    <path
      d="M239.011 62.9999H234.864V17.6399H246.787C251.107 17.6399 254.304 18.6119 256.377 20.5559C258.451 22.4999 259.488 25.5023 259.488 29.5631V31.1831C259.488 35.2439 258.451 38.2463 256.377 40.1903C254.304 42.1343 251.107 43.1063 246.787 43.1063H239.011V62.9999ZM239.011 21.2687V39.4775H246.787C249.811 39.4775 251.993 38.8511 253.332 37.5983C254.671 36.3023 255.341 34.1639 255.341 31.1831V29.5631C255.341 26.5823 254.671 24.4655 253.332 23.2127C251.993 21.9167 249.811 21.2687 246.787 21.2687H239.011Z"
      fill={color}
    />
    <path
      d="M265.92 51.0767V29.5631C265.92 25.5023 267.021 22.3919 269.224 20.2319C271.428 18.0719 274.581 16.9919 278.685 16.9919C282.789 16.9919 285.943 18.0719 288.146 20.2319C290.349 22.3919 291.451 25.5023 291.451 29.5631V51.0767C291.451 55.1376 290.349 58.2479 288.146 60.4079C285.943 62.5679 282.789 63.6479 278.685 63.6479C274.581 63.6479 271.428 62.5679 269.224 60.4079C267.021 58.2479 265.92 55.1376 265.92 51.0767ZM287.304 51.4007V29.2391C287.304 23.4935 284.431 20.6207 278.685 20.6207C272.94 20.6207 270.067 23.4935 270.067 29.2391V51.4007C270.067 57.1463 272.94 60.0191 278.685 60.0191C284.431 60.0191 287.304 57.1463 287.304 51.4007Z"
      fill={color}
    />
    <path d="M311.726 62.9999H307.578V21.2687H296.757V17.6399H322.612V21.2687H311.726V62.9999Z" fill={color} />
    <path
      d="M332.851 55.0295L350.477 17.6399H354.948V62.9999H350.995V25.6751L333.434 62.9999H328.963V17.6399H332.851V55.0295Z"
      fill={color}
    />
    <path
      d="M376.854 62.9999H363.894V17.6399H376.012C380.116 17.6399 383.248 18.5903 385.408 20.4911C387.568 22.3487 388.648 25.0703 388.648 28.6559V29.4983C388.648 34.5959 386.38 37.8575 381.844 39.2831V39.4127C386.985 40.6655 389.555 44.1647 389.555 49.9103V51.0767C389.555 55.1376 388.497 58.1399 386.38 60.0839C384.306 62.0279 381.131 62.9999 376.854 62.9999ZM368.041 21.2687V37.8575H375.105C378.345 37.8575 380.699 37.2311 382.168 35.9783C383.68 34.7255 384.436 32.7383 384.436 30.0167V29.1743C384.436 26.4095 383.745 24.4007 382.362 23.1479C381.023 21.8951 378.885 21.2687 375.947 21.2687H368.041ZM368.041 41.4863V59.3711H376.854C379.878 59.3711 382.038 58.7447 383.334 57.4919C384.673 56.1959 385.343 54.0575 385.343 51.0767V49.8455C385.343 46.9079 384.544 44.7911 382.945 43.4951C381.39 42.1559 378.841 41.4863 375.299 41.4863H368.041Z"
      fill={color}
    />
    <path
      d="M396.279 51.0767V29.5631C396.279 25.5023 397.381 22.3919 399.584 20.2319C401.787 18.0719 404.941 16.9919 409.045 16.9919C413.149 16.9919 416.302 18.0719 418.505 20.2319C420.709 22.3919 421.81 25.5023 421.81 29.5631V51.0767C421.81 55.1376 420.709 58.2479 418.505 60.4079C416.302 62.5679 413.149 63.6479 409.045 63.6479C404.941 63.6479 401.787 62.5679 399.584 60.4079C397.381 58.2479 396.279 55.1376 396.279 51.0767ZM417.663 51.4007V29.2391C417.663 23.4935 414.79 20.6207 409.045 20.6207C403.299 20.6207 400.426 23.4935 400.426 29.2391V51.4007C400.426 57.1463 403.299 60.0191 409.045 60.0191C414.79 60.0191 417.663 57.1463 417.663 51.4007Z"
      fill={color}
    />
    <path d="M434.234 62.9999H430.086V17.6399H455.553V62.9999H451.276V21.2687H434.234V62.9999Z" fill={color} />
    <path
      d="M463.863 51.0767V29.5631C463.863 25.5023 464.965 22.3919 467.168 20.2319C469.371 18.0719 472.525 16.9919 476.629 16.9919C480.733 16.9919 483.887 18.0719 486.09 20.2319C488.293 22.3919 489.395 25.5023 489.395 29.5631V51.0767C489.395 55.1376 488.293 58.2479 486.09 60.4079C483.887 62.5679 480.733 63.6479 476.629 63.6479C472.525 63.6479 469.371 62.5679 467.168 60.4079C464.965 58.2479 463.863 55.1376 463.863 51.0767ZM485.247 51.4007V29.2391C485.247 23.4935 482.375 20.6207 476.629 20.6207C470.883 20.6207 468.011 23.4935 468.011 29.2391V51.4007C468.011 57.1463 470.883 60.0191 476.629 60.0191C482.375 60.0191 485.247 57.1463 485.247 51.4007Z"
      fill={color}
    />
    <path
      d="M524.757 62.9999H520.156L508.816 38.2463L501.818 47.5775V62.9999H497.671V17.6399H501.818V41.7455L519.962 17.6399H524.563L511.732 34.7471L524.757 62.9999Z"
      fill={color}
    />
    <path
      d="M557.263 62.9999H552.986L550.394 53.0207H535.036L532.509 62.9999H528.556L540.026 17.6399H545.599L557.263 62.9999ZM542.618 22.6295L535.943 49.3271H549.487L542.618 22.6295Z"
      fill={color}
    />
    <path
      d="M574.178 63.6479C569.902 63.6479 566.726 62.5895 564.653 60.4727C562.579 58.3127 561.542 55.2671 561.542 51.3359V49.3919H565.43V51.4655C565.43 57.1679 568.325 60.0191 574.114 60.0191C579.859 60.0191 582.732 57.0383 582.732 51.0767V49.5863C582.732 44.0135 579.902 41.2271 574.243 41.2271H570.485V37.4687H574.373C576.965 37.4687 578.995 36.7991 580.464 35.4599C581.933 34.0775 582.667 32.1551 582.667 29.6927V28.7207C582.667 26.1719 581.933 24.1847 580.464 22.7591C578.995 21.3335 576.9 20.6207 574.178 20.6207C568.606 20.6207 565.819 23.4503 565.819 29.1095V30.2111H561.866V29.4335C561.866 25.4159 562.903 22.3487 564.977 20.2319C567.05 18.0719 570.161 16.9919 574.308 16.9919C578.326 16.9919 581.393 18.0071 583.51 20.0375C585.67 22.0679 586.75 24.9191 586.75 28.5911V29.4983C586.75 31.8311 586.123 33.8615 584.87 35.5895C583.661 37.3175 581.954 38.5055 579.751 39.1535V39.2183C584.46 40.2983 586.814 43.7543 586.814 49.5863V51.0767C586.814 59.4576 582.602 63.6479 574.178 63.6479Z"
      fill={color}
    />
    <path
      d="M620.101 62.9999H615.824L613.232 53.0207H597.874L595.347 62.9999H591.394L602.864 17.6399H608.437L620.101 62.9999ZM605.456 22.6295L598.782 49.3271H612.325L605.456 22.6295Z"
      fill={color}
    />
    <path
      d="M630.659 62.9999H626.511V17.6399H630.659V38.1815H647.701V17.6399H651.913V62.9999H647.701V41.8103H630.659V62.9999Z"
      fill={color}
    />
    <path
      d="M664.761 55.0295L682.387 17.6399H686.858V62.9999H682.905V25.6751L665.344 62.9999H660.873V17.6399H664.761V55.0295Z"
      fill={color}
    />
    <path
      d="M719.78 17.6399V62.9999H715.633V42.4583H708.635C705.308 42.4583 702.932 43.0847 701.507 44.3375C700.081 45.5903 699.368 47.7287 699.368 50.7527V56.7791C699.368 59.2415 699.131 61.3151 698.656 62.9999H694.444C694.962 61.6175 695.221 59.5439 695.221 56.7791V50.9471C695.221 47.7503 695.804 45.3095 696.971 43.6247C698.137 41.9399 699.995 40.8599 702.544 40.3847V40.3199C699.995 39.7583 698.137 38.6351 696.971 36.9503C695.804 35.2655 695.221 32.8679 695.221 29.7575V28.7207C695.221 24.9623 696.258 22.1759 698.332 20.3615C700.405 18.5471 703.58 17.6399 707.857 17.6399H719.78ZM708.764 38.7647H715.633V21.2687H707.922C704.898 21.2687 702.716 21.9167 701.377 23.2127C700.038 24.5087 699.368 26.6255 699.368 29.5631V30.5351C699.368 33.3863 700.103 35.4815 701.572 36.8207C703.084 38.1167 705.481 38.7647 708.764 38.7647Z"
      fill={color}
    />
    <path d="M733.182 62.9999H728.97V56.6495H733.182V62.9999Z" fill={color} />
    <path
      d="M758.55 62.9999H754.403V17.6399H758.55V38.1815H775.592V17.6399H779.804V62.9999H775.592V41.8103H758.55V62.9999Z"
      fill={color}
    />
    <path
      d="M811.445 62.9999H788.765V17.6399H811.445V21.2687H792.912V38.1815H808.205V41.8103H792.912V59.3711H811.445V62.9999Z"
      fill={color}
    />
    <path
      d="M817.542 51.0767V29.5631C817.542 25.5023 818.644 22.3919 820.847 20.2319C823.05 18.0719 826.204 16.9919 830.308 16.9919C834.412 16.9919 837.566 18.0719 839.769 20.2319C841.972 22.3919 843.074 25.5023 843.074 29.5631V51.0767C843.074 55.1376 841.972 58.2479 839.769 60.4079C837.566 62.5679 834.412 63.6479 830.308 63.6479C826.204 63.6479 823.05 62.5679 820.847 60.4079C818.644 58.2479 817.542 55.1376 817.542 51.0767ZM838.926 51.4007V29.2391C838.926 23.4935 836.054 20.6207 830.308 20.6207C824.562 20.6207 821.69 23.4935 821.69 29.2391V51.4007C821.69 57.1463 824.562 60.0191 830.308 60.0191C836.054 60.0191 838.926 57.1463 838.926 51.4007Z"
      fill={color}
    />
    <path
      d="M855.497 36.8855H863.986C868.263 36.8855 871.416 37.8791 873.447 39.8663C875.52 41.8535 876.557 44.9423 876.557 49.1327V50.6231C876.557 54.8135 875.52 57.924 873.447 59.9543C871.416 61.9847 868.263 62.9999 863.986 62.9999H851.35V17.6399H873.447V21.2687H855.497V36.8855ZM872.41 50.8823V48.9383C872.41 46.0007 871.719 43.8623 870.336 42.5231C868.997 41.1839 866.859 40.5143 863.921 40.5143H855.497V59.3711H863.921C866.859 59.3711 868.997 58.7015 870.336 57.3623C871.719 55.9799 872.41 53.8199 872.41 50.8823Z"
      fill={color}
    />
    <path
      d="M884.61 62.9999H880.398L892.451 39.8663L880.917 17.6399H885.518L894.978 36.1079L904.439 17.6399H908.716L897.117 39.8663L909.234 62.9999H904.569L894.59 43.4951L884.61 62.9999Z"
      fill={color}
    />
    <path
      d="M913.793 51.0767V29.5631C913.793 25.5023 914.895 22.3919 917.098 20.2319C919.301 18.0719 922.455 16.9919 926.559 16.9919C930.663 16.9919 933.816 18.0719 936.02 20.2319C938.223 22.3919 939.324 25.5023 939.324 29.5631V51.0767C939.324 55.1376 938.223 58.2479 936.02 60.4079C933.816 62.5679 930.663 63.6479 926.559 63.6479C922.455 63.6479 919.301 62.5679 917.098 60.4079C914.895 58.2479 913.793 55.1376 913.793 51.0767ZM935.177 51.4007V29.2391C935.177 23.4935 932.304 20.6207 926.559 20.6207C920.813 20.6207 917.94 23.4935 917.94 29.2391V51.4007C917.94 57.1463 920.813 60.0191 926.559 60.0191C932.304 60.0191 935.177 57.1463 935.177 51.4007Z"
      fill={color}
    />
    <path
      d="M975.026 17.6399V59.3711H978.849V69.0911H974.702V62.9999H948.653V69.0911H944.44V59.3711H948.847C950.575 57.7295 951.525 54.9431 951.698 51.0119L953.383 17.6399H975.026ZM953.189 59.3711H970.814V21.2687H957.271L955.845 50.8823C955.673 54.6407 954.787 57.4703 953.189 59.3711Z"
      fill={color}
    />
    <path
      d="M989.204 55.0295L1006.83 17.6399H1011.3V62.9999H1007.35V25.6751L989.787 62.9999H985.316V17.6399H989.204V55.0295Z"
      fill={color}
    />
    <path
      d="M1024.01 62.9999H1020.25V17.6399H1026.4L1036.97 55.4831L1047.46 17.6399H1053.75V62.9999H1049.67V23.4719L1038.52 62.9999H1035.22L1024.01 23.4719V62.9999Z"
      fill={color}
    />
    <path
      d="M1088.89 62.9999H1084.61L1082.02 53.0207H1066.66L1064.13 62.9999H1060.18L1071.65 17.6399H1077.22L1088.89 62.9999ZM1074.24 22.6295L1067.57 49.3271H1081.11L1074.24 22.6295Z"
      fill={color}
    />
    <path
      d="M1134.41 62.9999H1129.81L1118.47 38.2463L1111.47 47.5775V62.9999H1107.32V17.6399H1111.47V41.7455L1129.61 17.6399H1134.21L1121.38 34.7471L1134.41 62.9999Z"
      fill={color}
    />
    <path
      d="M1138.95 51.0767V29.5631C1138.95 25.5023 1140.05 22.3919 1142.25 20.2319C1144.46 18.0719 1147.61 16.9919 1151.71 16.9919C1155.82 16.9919 1158.97 18.0719 1161.17 20.2319C1163.38 22.3919 1164.48 25.5023 1164.48 29.5631V51.0767C1164.48 55.1376 1163.38 58.2479 1161.17 60.4079C1158.97 62.5679 1155.82 63.6479 1151.71 63.6479C1147.61 63.6479 1144.46 62.5679 1142.25 60.4079C1140.05 58.2479 1138.95 55.1376 1138.95 51.0767ZM1160.33 51.4007V29.2391C1160.33 23.4935 1157.46 20.6207 1151.71 20.6207C1145.97 20.6207 1143.1 23.4935 1143.1 29.2391V51.4007C1143.1 57.1463 1145.97 60.0191 1151.71 60.0191C1157.46 60.0191 1160.33 57.1463 1160.33 51.4007Z"
      fill={color}
    />
    <path
      d="M1176.9 62.9999H1172.76V17.6399H1176.9V38.1815H1193.94V17.6399H1198.16V62.9999H1193.94V41.8103H1176.9V62.9999Z"
      fill={color}
    />
    <path
      d="M1218.78 63.6479C1214.94 63.6479 1211.91 62.5679 1209.71 60.4079C1207.55 58.2479 1206.47 55.2024 1206.47 51.2711V29.3687C1206.47 25.4375 1207.55 22.3919 1209.71 20.2319C1211.91 18.0719 1214.94 16.9919 1218.78 16.9919C1222.8 16.9919 1225.89 18.0719 1228.05 20.2319C1230.21 22.3919 1231.29 25.4375 1231.29 29.3687V32.3495H1227.33V29.1743C1227.33 26.4095 1226.58 24.2927 1225.07 22.8239C1223.6 21.3551 1221.52 20.6207 1218.85 20.6207C1216.25 20.6207 1214.22 21.3767 1212.75 22.8887C1211.33 24.3575 1210.62 26.4527 1210.62 29.1743V51.4655C1210.62 54.1871 1211.33 56.3039 1212.75 57.8159C1214.22 59.2847 1216.25 60.0191 1218.85 60.0191C1221.52 60.0191 1223.6 59.2847 1225.07 57.8159C1226.58 56.3471 1227.33 54.2303 1227.33 51.4655V46.9943H1231.29V51.2711C1231.29 55.2024 1230.21 58.2479 1228.05 60.4079C1225.89 62.5679 1222.8 63.6479 1218.78 63.6479Z"
      fill={color}
    />
    <path
      d="M1242.13 63.6479C1240.44 63.6479 1239.08 63.4103 1238.04 62.9351V59.3711C1239.21 59.8031 1240.48 60.0191 1241.87 60.0191C1243.51 60.0191 1244.76 59.7167 1245.62 59.1119C1246.49 58.4639 1247.18 57.4487 1247.7 56.0663L1249.06 52.1135L1236.55 17.6399H1241.02L1251.2 46.2815L1260.72 17.6399H1264.74L1251.65 56.5847C1250.79 59.1767 1249.62 61.0127 1248.15 62.0927C1246.73 63.1295 1244.72 63.6479 1242.13 63.6479Z"
      fill={color}
    />
    <path
      d="M1265.19 63.0647C1264.54 63.0647 1264 63 1263.57 62.8703V59.2415C1263.91 59.3711 1264.35 59.4359 1264.86 59.4359C1267.11 59.4359 1268.71 58.8527 1269.66 57.6863C1270.61 56.5199 1271.15 54.5111 1271.28 51.6599L1272.96 17.6399H1294.61V62.9999H1290.46V21.2687H1276.85L1275.3 52.2431C1275.12 56.0015 1274.26 58.7447 1272.7 60.4727C1271.15 62.2007 1268.64 63.0647 1265.19 63.0647Z"
      fill={color}
    />
    <path
      d="M1315.68 62.9999H1303.56V17.6399H1307.7V36.8855H1315.68C1319.95 36.8855 1323.11 37.8791 1325.14 39.8663C1327.21 41.8535 1328.25 44.9423 1328.25 49.1327V50.6231C1328.25 54.8135 1327.21 57.924 1325.14 59.9543C1323.11 61.9847 1319.95 62.9999 1315.68 62.9999ZM1307.7 40.5143V59.3711H1315.61C1318.55 59.3711 1320.69 58.7015 1322.03 57.3623C1323.41 55.9799 1324.1 53.8199 1324.1 50.8823V48.9383C1324.1 46.0007 1323.41 43.8623 1322.03 42.5231C1320.69 41.1839 1318.55 40.5143 1315.61 40.5143H1307.7Z"
      fill={color}
    />
    <path d="M1341.56 62.9999H1337.42V21.2687H1326.6V17.6399H1352.45V21.2687H1341.56V62.9999Z" fill={color} />
    <path
      d="M1379.86 62.9999H1375.58L1372.99 53.0207H1357.63L1355.1 62.9999H1351.15L1362.62 17.6399H1368.19L1379.86 62.9999ZM1365.21 22.6295L1358.54 49.3271H1372.08L1365.21 22.6295Z"
      fill={color}
    />
    <path
      d="M1416.53 69.0911H1412.38V62.9999H1386.27V17.6399H1390.41V59.3711H1407.52V17.6399H1411.73V59.3711H1416.53V69.0911Z"
      fill={color}
    />
    <path
      d="M1426.92 55.0295L1444.55 17.6399H1449.02V62.9999H1445.06V25.6751L1427.5 62.9999H1423.03V17.6399H1426.92V55.0295Z"
      fill={color}
    />
    <path
      d="M1481.94 17.6399V62.9999H1477.79V42.4583H1470.79C1467.47 42.4583 1465.09 43.0847 1463.67 44.3375C1462.24 45.5903 1461.53 47.7287 1461.53 50.7527V56.7791C1461.53 59.2415 1461.29 61.3151 1460.82 62.9999H1456.6C1457.12 61.6175 1457.38 59.5439 1457.38 56.7791V50.9471C1457.38 47.7503 1457.96 45.3095 1459.13 43.6247C1460.3 41.9399 1462.15 40.8599 1464.7 40.3847V40.3199C1462.15 39.7583 1460.3 38.6351 1459.13 36.9503C1457.96 35.2655 1457.38 32.8679 1457.38 29.7575V28.7207C1457.38 24.9623 1458.42 22.1759 1460.49 20.3615C1462.56 18.5471 1465.74 17.6399 1470.02 17.6399H1481.94ZM1470.92 38.7647H1477.79V21.2687H1470.08C1467.06 21.2687 1464.88 21.9167 1463.54 23.2127C1462.2 24.5087 1461.53 26.6255 1461.53 29.5631V30.5351C1461.53 33.3863 1462.26 35.4815 1463.73 36.8207C1465.24 38.1167 1467.64 38.7647 1470.92 38.7647Z"
      fill={color}
    />
    <path
      d="M1514.56 63.6479C1510.71 63.6479 1507.69 62.5679 1505.49 60.4079C1503.33 58.2479 1502.25 55.2024 1502.25 51.2711V29.3687C1502.25 25.4375 1503.33 22.3919 1505.49 20.2319C1507.69 18.0719 1510.71 16.9919 1514.56 16.9919C1518.58 16.9919 1521.66 18.0719 1523.82 20.2319C1525.98 22.3919 1527.06 25.4375 1527.06 29.3687V32.3495H1523.11V29.1743C1523.11 26.4095 1522.36 24.2927 1520.84 22.8239C1519.37 21.3551 1517.3 20.6207 1514.62 20.6207C1512.03 20.6207 1510 21.3767 1508.53 22.8887C1507.11 24.3575 1506.39 26.4527 1506.39 29.1743V51.4655C1506.39 54.1871 1507.11 56.3039 1508.53 57.8159C1510 59.2847 1512.03 60.0191 1514.62 60.0191C1517.3 60.0191 1519.37 59.2847 1520.84 57.8159C1522.36 56.3471 1523.11 54.2303 1523.11 51.4655V46.9943H1527.06V51.2711C1527.06 55.2024 1525.98 58.2479 1523.82 60.4079C1521.66 62.5679 1518.58 63.6479 1514.56 63.6479Z"
      fill={color}
    />
    <path d="M1538.74 62.9999H1534.6V17.6399H1560.06V62.9999H1555.79V21.2687H1538.74V62.9999Z" fill={color} />
    <path
      d="M1591.7 62.9999H1569.02V17.6399H1591.7V21.2687H1573.17V38.1815H1588.46V41.8103H1573.17V59.3711H1591.7V62.9999Z"
      fill={color}
    />
    <path
      d="M1628.71 69.0911H1624.56V62.9999H1598.45V17.6399H1602.6V59.3711H1619.7V17.6399H1623.91V59.3711H1628.71V69.0911Z"
      fill={color}
    />
    <path
      d="M1639.1 55.0295L1656.73 17.6399H1661.2V62.9999H1657.25V25.6751L1639.69 62.9999H1635.21V17.6399H1639.1V55.0295Z"
      fill={color}
    />
    <path
      d="M1696.33 62.9999H1692.05L1689.46 53.0207H1674.1L1671.57 62.9999H1667.62L1679.09 17.6399H1684.66L1696.33 62.9999ZM1681.68 22.6295L1675.01 49.3271H1688.55L1681.68 22.6295Z"
      fill={color}
    />
    <path
      d="M1701.96 63.0647C1701.31 63.0647 1700.77 63 1700.34 62.8703V59.2415C1700.68 59.3711 1701.11 59.4359 1701.63 59.4359C1703.88 59.4359 1705.48 58.8527 1706.43 57.6863C1707.38 56.5199 1707.92 54.5111 1708.05 51.6599L1709.73 17.6399H1731.37V62.9999H1727.23V21.2687H1713.62L1712.06 52.2431C1711.89 56.0015 1711.03 58.7447 1709.47 60.4727C1707.92 62.2007 1705.41 63.0647 1701.96 63.0647Z"
      fill={color}
    />
    <path
      d="M1744.21 55.0295L1761.84 17.6399H1766.31V62.9999H1762.36V25.6751L1744.8 62.9999H1740.32V17.6399H1744.21V55.0295Z"
      fill={color}
    />
    <path
      d="M1786.92 63.6479C1783.08 63.6479 1780.05 62.5679 1777.85 60.4079C1775.69 58.2479 1774.61 55.2024 1774.61 51.2711V29.3687C1774.61 25.4375 1775.69 22.3919 1777.85 20.2319C1780.05 18.0719 1783.08 16.9919 1786.92 16.9919C1790.94 16.9919 1794.03 18.0719 1796.19 20.2319C1798.35 22.3919 1799.43 25.4375 1799.43 29.3687V32.3495H1795.47V29.1743C1795.47 26.4095 1794.72 24.2927 1793.21 22.8239C1791.74 21.3551 1789.66 20.6207 1786.98 20.6207C1784.39 20.6207 1782.36 21.3767 1780.89 22.8887C1779.47 24.3575 1778.76 26.4527 1778.76 29.1743V51.4655C1778.76 54.1871 1779.47 56.3039 1780.89 57.8159C1782.36 59.2847 1784.39 60.0191 1786.98 60.0191C1789.66 60.0191 1791.74 59.2847 1793.21 57.8159C1794.72 56.3471 1795.47 54.2303 1795.47 51.4655V46.9943H1799.43V51.2711C1799.43 55.2024 1798.35 58.2479 1796.19 60.4079C1794.03 62.5679 1790.94 63.6479 1786.92 63.6479Z"
      fill={color}
    />
    <path d="M1819.4 62.9999H1815.25V21.2687H1804.43V17.6399H1830.29V21.2687H1819.4V62.9999Z" fill={color} />
    <path
      d="M1857.69 62.9999H1853.42L1850.82 53.0207H1835.47L1832.94 62.9999H1828.99L1840.46 17.6399H1846.03L1857.69 62.9999ZM1843.05 22.6295L1836.37 49.3271H1849.92L1843.05 22.6295Z"
      fill={color}
    />
  </svg>
);

export const SeeDoctorM = ({ bgColor, color }: IDisclaimerVector) => (
  <svg width="375" height="80" viewBox="0 0 375 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="375" height="80" fill={bgColor} />
    <path
      d="M16.6115 72.0243H15V42.427H16.6115V55.4079H21.5777V42.427H23.1891V72.0243H21.5777V57.776H16.6115V72.0243Z"
      fill={color}
    />
    <path
      d="M32.9688 72.0243H25.6873V42.427H32.9688V44.7951H27.2987V55.4079H31.9534V57.776H27.2987V69.6163H32.9688V72.0243Z"
      fill={color}
    />
    <path
      d="M34.5562 65.2134V49.2374C34.5562 46.896 34.9066 45.1083 35.6105 43.8676C36.3114 42.6268 37.3208 42.0065 38.6387 42.0065C39.9567 42.0065 40.9661 42.6268 41.67 43.8676C42.3709 45.1083 42.7243 46.896 42.7243 49.2374V65.2201C42.7243 67.5614 42.3738 69.3491 41.67 70.5899C40.9691 71.8306 39.9567 72.451 38.6387 72.451C37.3208 72.451 36.3114 71.8306 35.6075 70.5899C34.9066 69.3425 34.5562 67.5548 34.5562 65.2134ZM41.1098 65.3868V49.0639C41.1098 45.9354 40.2861 44.3745 38.6387 44.3745C36.9913 44.3745 36.1676 45.9421 36.1676 49.0639V65.3802C36.1676 68.482 36.9913 70.0295 38.6387 70.0295C40.2861 70.0362 41.1098 68.4886 41.1098 65.3868Z"
      fill={color}
    />
    <path
      d="M49.0922 72.0243H45.0366V42.427H52.1355V44.7951H46.6481V54.981H49.0922C50.479 54.981 51.5004 55.5614 52.1624 56.7154C52.8214 57.8694 53.1508 59.6504 53.1508 62.0452V64.8802C53.1508 67.3016 52.8214 69.1026 52.1624 70.27C51.5034 71.4373 50.482 72.0243 49.0922 72.0243ZM46.6481 57.3557V69.6163H49.0922C49.9429 69.6163 50.5629 69.256 50.9523 68.5356C51.3417 67.8152 51.5364 66.6545 51.5364 65.0469V61.9184C51.5364 60.3108 51.3417 59.1501 50.9523 58.4297C50.5629 57.7093 49.9429 57.3491 49.0922 57.3491H46.6481V57.3557Z"
      fill={color}
    />
    <path
      d="M55.6999 72.0243H54.1394L57.8595 56.9288L54.2951 42.427H56.0115L58.872 54.1806L61.7594 42.427H63.3199L59.7556 56.9288L63.4757 72.0243H61.7594L58.7432 59.5971L55.6999 72.0243Z"
      fill={color}
    />
    <path
      d="M64.5928 65.2134V49.2374C64.5928 46.896 64.9432 45.1083 65.6471 43.8676C66.348 42.6268 67.3574 42.0065 68.6753 42.0065C69.9933 42.0065 71.0027 42.6268 71.7066 43.8676C72.4075 45.1083 72.7609 46.896 72.7609 49.2374V65.2201C72.7609 67.5614 72.4105 69.3491 71.7066 70.5899C71.0057 71.8306 69.9933 72.451 68.6753 72.451C67.3574 72.451 66.348 71.8306 65.6441 70.5899C64.9432 69.3425 64.5928 67.5548 64.5928 65.2134ZM71.1465 65.3868V49.0639C71.1465 45.9354 70.3228 44.3745 68.6753 44.3745C67.0279 44.3745 66.2042 45.9421 66.2042 49.0639V65.3802C66.2042 68.482 67.0279 70.0295 68.6753 70.0295C70.3228 70.0362 71.1465 68.4886 71.1465 65.3868Z"
      fill={color}
    />
    <path
      d="M83.9934 42.427V69.6096H85.2424V75.9933H83.631V72.0243H75.7504V76H74.1389V69.6163H75.5946C76.011 68.7958 76.2626 67.2882 76.3494 65.0936L76.8946 42.4337H83.9934V42.427ZM77.2331 69.6096H82.3819V44.7951H78.4042L77.9879 65.0002C77.934 66.948 77.6823 68.4889 77.2331 69.6096Z"
      fill={color}
    />
    <path
      d="M88.4143 66.0608L93.5902 42.427H95.3065V72.0243H93.7968V48.2638L88.6479 72.0243H86.9316V42.427H88.4143V66.0608Z"
      fill={color}
    />
    <path
      d="M99.2604 72.0243H97.8047V42.427H100.225L103.268 67.1615L106.311 42.427H108.731V72.0243H107.144V46.2359L103.915 72.0243H102.51L99.2604 46.2359V72.0243Z"
      fill={color}
    />
    <path
      d="M119.805 72.0241H118.167L117.388 65.4736H112.733L111.978 72.0241H110.445L114.009 42.4335H116.193L119.805 72.0241ZM115.046 45.642L113.018 63.1456H117.127L115.046 45.642Z"
      fill={color}
    />
    <path
      d="M134.395 72.0243H132.679L129.324 56.5086L127.296 62.0919V72.0243H125.685V42.427H127.296V57.856L132.655 42.427H134.344L130.39 53.6269L134.395 72.0243Z"
      fill={color}
    />
    <path
      d="M135.539 65.2134V49.2374C135.539 46.896 135.89 45.1083 136.594 43.8676C137.295 42.6268 138.307 42.0065 139.625 42.0065C140.943 42.0065 141.952 42.6268 142.656 43.8676C143.357 45.1083 143.71 46.896 143.71 49.2374V65.2201C143.71 67.5614 143.36 69.3491 142.656 70.5899C141.955 71.8306 140.943 72.451 139.625 72.451C138.307 72.451 137.298 71.8306 136.594 70.5899C135.89 69.3425 135.539 67.5548 135.539 65.2134ZM142.093 65.3868V49.0639C142.093 45.9354 141.269 44.3745 139.622 44.3745C137.974 44.3745 137.151 45.9421 137.151 49.0639V65.3802C137.151 68.482 137.974 70.0295 139.622 70.0295C141.269 70.0362 142.093 68.4886 142.093 65.3868Z"
      fill={color}
    />
    <path
      d="M147.631 72.0243H146.02V42.427H147.631V55.4079H152.597V42.427H154.209V72.0243H152.597V57.776H147.631V72.0243Z"
      fill={color}
    />
    <path
      d="M160.481 72.4445C159.232 72.4445 158.262 71.8308 157.57 70.6034C156.875 69.376 156.53 67.6684 156.53 65.4671V48.9374C156.53 46.7695 156.878 45.0685 157.57 43.8411C158.262 42.6137 159.235 42 160.481 42C161.763 42 162.751 42.607 163.446 43.8211C164.138 45.0351 164.486 46.7361 164.486 48.9374V51.9792H162.952V48.8107C162.952 47.4032 162.731 46.3092 162.29 45.5354C161.847 44.7616 161.254 44.3747 160.508 44.3747C159.762 44.3747 159.181 44.7616 158.765 45.5354C158.348 46.3092 158.142 47.4032 158.142 48.8107V65.6338C158.142 67.0413 158.351 68.1286 158.765 68.8891C159.181 69.6495 159.762 70.0297 160.508 70.0297C161.29 70.0297 161.892 69.6495 162.317 68.8891C162.742 68.1286 162.955 67.0413 162.955 65.6338V61.6182H164.489V65.4671C164.489 67.6684 164.141 69.376 163.449 70.6034C162.751 71.8308 161.763 72.4445 160.481 72.4445Z"
      fill={color}
    />
    <path
      d="M170.545 60.4842L173.328 42.4337H174.888L170.883 67.8819C170.623 69.5429 170.251 70.7236 169.766 71.4106C169.281 72.0977 168.586 72.4446 167.685 72.4446C167.13 72.4446 166.678 72.2912 166.334 71.9776V69.6496C166.699 69.9031 167.088 70.0298 167.505 70.0298C168.026 70.0298 168.424 69.8364 168.7 69.4562C168.976 69.0759 169.185 68.4356 169.323 67.535L169.712 65.0002L165.812 42.427H167.478L170.545 60.4842Z"
      fill={color}
    />
    <path
      d="M174.73 72.0642C174.592 72.0642 174.4 72.0375 174.157 71.9774V69.6094C174.331 69.6628 174.469 69.6961 174.574 69.6961C175.233 69.6961 175.715 69.3492 176.018 68.6622C176.32 67.9751 176.491 66.7811 176.524 65.0934L177.045 42.4335H184.12V72.0241H182.508V44.7949H178.555L178.111 65C178.075 67.4548 177.8 69.2425 177.279 70.3698C176.76 71.5038 175.91 72.0642 174.73 72.0642Z"
      fill={color}
    />
    <path
      d="M190.491 72.0243H186.642V42.427H188.253V54.5608H190.491C191.878 54.5608 192.899 55.1411 193.561 56.2951C194.22 57.4491 194.549 59.2302 194.549 61.6249V64.8802C194.549 67.3016 194.22 69.1026 193.561 70.27C192.899 71.4373 191.878 72.0243 190.491 72.0243ZM188.253 56.9288V69.6096H190.491C191.341 69.6096 191.961 69.2494 192.351 68.529C192.74 67.8085 192.935 66.6479 192.935 65.0402V61.4915C192.935 59.8839 192.74 58.7232 192.351 58.0028C191.961 57.2824 191.341 56.9222 190.491 56.9222H188.253V56.9288Z"
      fill={color}
    />
    <path d="M198.683 72.0243H197.071V44.7951H193.744V42.427H202.041V44.7951H198.686V72.0243H198.683Z" fill={color} />
    <path
      d="M210.775 72.0241H209.136L208.358 65.4736H203.703L202.948 72.0241H201.415L204.979 42.4335H207.162L210.775 72.0241ZM206.015 45.642L203.987 63.1456H208.097L206.015 45.642Z"
      fill={color}
    />
    <path
      d="M222.193 75.9933H220.581V72.0243H212.494V42.427H214.106V69.6096H219.072V42.427H220.683V69.6096H222.193V75.9933Z"
      fill={color}
    />
    <path
      d="M225.392 66.0608L230.568 42.427H232.284V72.0243H230.774V48.2638L225.625 72.0243H223.909V42.427H225.392V66.0608Z"
      fill={color}
    />
    <path
      d="M242.531 42.427V72.0243H240.919V58.3697H238.915C237.927 58.3697 237.229 58.7165 236.822 59.4036C236.414 60.0973 236.211 61.2847 236.211 62.979V68.4356C236.211 69.8697 236.142 71.0704 236.004 72.031H234.339C234.512 71.2105 234.599 70.0298 234.599 68.4823V63.1124C234.599 59.6171 235.345 57.5892 236.837 57.0222V56.9355C235.345 56.2885 234.599 54.3006 234.599 50.972V49.1509C234.599 46.8096 234.92 45.1086 235.561 44.0346C236.202 42.9606 237.232 42.427 238.655 42.427H242.531ZM238.939 55.9149H240.916V44.7951H238.679C237.81 44.7951 237.184 45.1553 236.795 45.8757C236.405 46.5961 236.211 47.7568 236.211 49.3644V51.4389C236.211 53.0199 236.432 54.1605 236.873 54.861C237.316 55.5614 238.005 55.9149 238.939 55.9149Z"
      fill={color}
    />
    <path
      d="M252.984 72.4445C251.735 72.4445 250.765 71.8308 250.073 70.6034C249.378 69.376 249.034 67.6684 249.034 65.4671V48.9374C249.034 46.7695 249.381 45.0685 250.073 43.8411C250.765 42.6137 251.738 42 252.984 42C254.266 42 255.255 42.607 255.95 43.8211C256.642 45.0351 256.989 46.7361 256.989 48.9374V51.9792H255.456V48.8107C255.456 47.4032 255.234 46.3092 254.794 45.5354C254.35 44.7616 253.757 44.3747 253.011 44.3747C252.266 44.3747 251.685 44.7616 251.268 45.5354C250.852 46.3092 250.645 47.4032 250.645 48.8107V65.6338C250.645 67.0413 250.855 68.1286 251.268 68.8891C251.685 69.6495 252.266 70.0297 253.011 70.0297C253.793 70.0297 254.395 69.6495 254.821 68.8891C255.246 68.1286 255.459 67.0413 255.459 65.6338V61.6182H256.992V65.4671C256.992 67.6684 256.645 69.376 255.953 70.6034C255.255 71.8308 254.266 72.4445 252.984 72.4445Z"
      fill={color}
    />
    <path d="M260.655 72.0243H259.044V42.427H267.236V72.0243H265.625V44.7951H260.658V72.0243H260.655Z" fill={color} />
    <path
      d="M277.013 72.0243H269.731V42.427H277.013V44.7951H271.342V55.4079H275.997V57.776H271.342V69.6163H277.013V72.0243Z"
      fill={color}
    />
    <path
      d="M288.509 75.9933H286.897V72.0243H278.81V42.427H280.421V69.6096H285.387V42.427H286.999V69.6096H288.509V75.9933Z"
      fill={color}
    />
    <path
      d="M291.708 66.0608L296.883 42.427H298.6V72.0243H297.09V48.2638L291.941 72.0243H290.225V42.427H291.708V66.0608Z"
      fill={color}
    />
    <path
      d="M309.652 72.0241H308.014L307.235 65.4736H302.58L301.826 72.0241H300.292L303.856 42.4335H306.04L309.652 72.0241ZM304.893 45.642L302.865 63.1456H306.974L304.893 45.642Z"
      fill={color}
    />
    <path
      d="M311.186 72.0642C311.048 72.0642 310.856 72.0375 310.614 71.9774V69.6094C310.787 69.6628 310.925 69.6961 311.03 69.6961C311.689 69.6961 312.171 69.3492 312.474 68.6622C312.776 67.9751 312.947 66.7811 312.98 65.0934L313.501 42.4335H320.576V72.0241H318.964V44.7949H315.011L314.567 65C314.531 67.4548 314.256 69.2425 313.735 70.3698C313.213 71.5038 312.366 72.0642 311.186 72.0642Z"
      fill={color}
    />
    <path
      d="M324.578 66.0608L329.754 42.427H331.47V72.0243H329.96V48.2638L324.814 72.0243H323.098V42.427H324.581V66.0608H324.578Z"
      fill={color}
    />
    <path
      d="M337.739 72.4445C336.49 72.4445 335.519 71.8308 334.827 70.6034C334.133 69.376 333.788 67.6684 333.788 65.4671V48.9374C333.788 46.7695 334.136 45.0685 334.827 43.8411C335.519 42.6137 336.493 42 337.739 42C339.021 42 340.009 42.607 340.704 43.8211C341.396 45.0351 341.744 46.7361 341.744 48.9374V51.9792H340.21V48.8107C340.21 47.4032 339.988 46.3092 339.548 45.5354C339.105 44.7616 338.512 44.3747 337.766 44.3747C337.02 44.3747 336.439 44.7616 336.023 45.5354C335.606 46.3092 335.4 47.4032 335.4 48.8107V65.6338C335.4 67.0413 335.609 68.1286 336.023 68.8891C336.439 69.6495 337.02 70.0297 337.766 70.0297C338.548 70.0297 339.15 69.6495 339.575 68.8891C340 68.1286 340.213 67.0413 340.213 65.6338V61.6182H341.747V65.4671C341.747 67.6684 341.399 69.376 340.707 70.6034C340.009 71.8308 339.021 72.4445 337.739 72.4445Z"
      fill={color}
    />
    <path d="M347.908 72.0243H346.297V44.7951H342.969V42.427H351.266V44.7951H347.911V72.0243H347.908Z" fill={color} />
    <path
      d="M360 72.0241H358.362L357.583 65.4736H352.928L352.173 72.0241H350.64L354.204 42.4335H356.388L360 72.0241ZM355.24 45.642L353.213 63.1456H357.322L355.24 45.642Z"
      fill={color}
    />
    <path
      d="M26.9268 37.586H15V8.41443H26.9268V10.1699H17.0003V21.5045H25.2273V23.2534H17.0003V35.8372H26.9268V37.586Z"
      fill={color}
    />
    <path
      d="M36.2869 38C34.2565 38 32.6672 37.4017 31.5192 36.2051C30.3711 35.0085 29.7996 33.3583 29.7996 31.2479V14.7456C29.7996 12.6351 30.3711 10.9849 31.5192 9.7883C32.6672 8.59829 34.2565 8 36.2869 8C38.3775 8 39.9918 8.59829 41.1398 9.79487C42.2879 10.9915 42.8594 12.6417 42.8594 14.7521V17.7107H40.9443V14.6667C40.9443 13.1085 40.5282 11.9053 39.706 11.044C38.8788 10.1828 37.7558 9.75542 36.332 9.75542C34.9383 9.75542 33.8353 10.1893 33.0232 11.044C32.211 11.9053 31.8049 13.1151 31.8049 14.6667V31.3333C31.8049 32.8915 32.211 34.0947 33.0232 34.9559C33.8353 35.8172 34.9383 36.2446 36.332 36.2446C37.7558 36.2446 38.8788 35.8172 39.706 34.9559C40.5332 34.0947 40.9443 32.8849 40.9443 31.3333V27.4609H42.8594V31.2544C42.8594 33.3649 42.2879 35.0217 41.1398 36.2117C39.9918 37.4017 38.3725 38 36.2869 38Z"
      fill={color}
    />
    <path d="M52.9114 37.586H50.9111V10.1699H45.1206V8.41443H58.7019V10.1699H52.9114V37.586Z" fill={color} />
    <path
      d="M68.0165 37.586H61.7498V8.41443H63.7501V20.6629H68.0165C70.3076 20.6629 71.9921 21.2218 73.065 22.3329C74.1378 23.444 74.6743 25.1929 74.6743 27.586V30.6301C74.6743 33.0167 74.1378 34.7787 73.065 35.9029C71.9921 37.0206 70.3076 37.586 68.0165 37.586ZM63.7501 22.4184V35.8372H68.0165C69.6709 35.8372 70.8591 35.4493 71.586 34.6735C72.313 33.8977 72.6739 32.5893 72.6739 30.755V27.5071C72.6739 25.6728 72.313 24.371 71.586 23.5887C70.8591 22.8128 69.6709 22.4249 68.0165 22.4249H63.7501V22.4184Z"
      fill={color}
    />
    <path d="M87.1678 37.586H85.1675V8.41443H98.5332V37.586H96.5328V10.1699H87.1728V37.586H87.1678Z" fill={color} />
    <path
      d="M105.015 37.586H103.015V8.41443H109.282C111.573 8.41443 113.257 8.97985 114.33 10.1041C115.403 11.2284 115.94 12.9838 115.94 15.377V17.5006C115.94 19.8871 115.403 21.6426 114.33 22.7537C113.257 23.8648 111.573 24.4236 109.282 24.4236H105.015V37.586ZM105.015 10.1699V22.6682H109.282C110.936 22.6682 112.124 22.2803 112.851 21.5045C113.578 20.7287 113.939 19.4203 113.939 17.586V15.252C113.939 13.4177 113.578 12.1159 112.851 11.3336C112.124 10.5578 110.936 10.1699 109.282 10.1699H105.015Z"
      fill={color}
    />
    <path
      d="M119.033 30.9586V15.0414C119.033 12.7666 119.614 11.0178 120.772 9.8146C121.935 8.60487 123.59 8 125.736 8C127.881 8 129.536 8.60487 130.699 9.8146C131.857 11.0243 132.439 12.7666 132.439 15.0414V30.9586C132.439 33.2334 131.857 34.9822 130.699 36.1854C129.536 37.3951 127.881 38 125.736 38C123.59 38 121.935 37.3951 120.772 36.1854C119.614 34.9822 119.033 33.24 119.033 30.9586ZM130.438 31.0835V14.9165C130.438 11.4714 128.874 9.74885 125.736 9.74885C122.597 9.74885 121.033 11.4714 121.033 14.9165V31.0835C121.033 34.5286 122.597 36.2512 125.736 36.2512C128.874 36.2512 130.438 34.5286 130.438 31.0835Z"
      fill={color}
    />
    <path d="M142.626 37.586H140.626V10.1699H134.835V8.41443H148.416V10.1699H142.626V37.586Z" fill={color} />
    <path
      d="M153.375 33.2073L162.905 8.41443H165.126V37.586H163.211V12.8326L153.68 37.586H151.459V8.41443H153.375V33.2073Z"
      fill={color}
    />
    <path
      d="M176.406 37.586H169.613V8.41443H175.97C178.321 8.41443 180.021 8.93382 181.063 9.97919C182.106 11.018 182.633 12.6945 182.633 15.0022V16.2514C182.633 19.4466 181.414 21.419 178.978 22.1685V22.254C181.735 22.918 183.114 24.989 183.114 28.4604V30.9194C183.114 33.1942 182.577 34.8773 181.505 35.9621C180.422 37.0403 178.727 37.586 176.406 37.586ZM171.613 10.1699V21.5045H175.488C177.258 21.5045 178.557 21.1166 179.384 20.3408C180.211 19.565 180.622 18.3158 180.622 16.5933V15.1666C180.622 13.3914 180.261 12.1094 179.534 11.3336C178.807 10.5578 177.604 10.1699 175.92 10.1699H171.613ZM171.613 23.2534V35.8372H176.401C178.055 35.8372 179.254 35.4493 179.991 34.6735C180.732 33.8977 181.098 32.6288 181.098 30.8799V28.421C181.098 26.5604 180.677 25.2323 179.835 24.4434C178.993 23.6544 177.574 23.2534 175.569 23.2534H171.613Z"
      fill={color}
    />
    <path
      d="M186.458 30.9586V15.0414C186.458 12.7666 187.039 11.0178 188.197 9.8146C189.356 8.60487 191.01 8 193.161 8C195.306 8 196.961 8.60487 198.124 9.8146C199.282 11.0243 199.864 12.7666 199.864 15.0414V30.9586C199.864 33.2334 199.282 34.9822 198.124 36.1854C196.961 37.3951 195.306 38 193.161 38C191.015 38 189.361 37.3951 188.197 36.1854C187.039 34.9822 186.458 33.24 186.458 30.9586ZM197.863 31.0835V14.9165C197.863 11.4714 196.299 9.74885 193.161 9.74885C190.022 9.74885 188.463 11.4714 188.463 14.9165V31.0835C188.463 34.5286 190.027 36.2512 193.166 36.2512C196.304 36.2512 197.863 34.5286 197.863 31.0835Z"
      fill={color}
    />
    <path d="M206.005 37.586H204.005V8.41443H217.37V37.586H215.37V10.1699H206.01V37.586H206.005Z" fill={color} />
    <path
      d="M221.501 30.9586V15.0414C221.501 12.7666 222.083 11.0178 223.241 9.8146C224.399 8.60487 226.054 8 228.204 8C230.35 8 232.005 8.60487 233.168 9.8146C234.326 11.0243 234.907 12.7666 234.907 15.0414V30.9586C234.907 33.2334 234.326 34.9822 233.168 36.1854C232.005 37.3951 230.35 38 228.204 38C226.059 38 224.404 37.3951 223.241 36.1854C222.078 34.9822 221.501 33.24 221.501 30.9586ZM232.902 31.0835V14.9165C232.902 11.4714 231.338 9.74885 228.199 9.74885C225.061 9.74885 223.497 11.4714 223.497 14.9165V31.0835C223.497 34.5286 225.061 36.2512 228.199 36.2512C231.338 36.2512 232.902 34.5286 232.902 31.0835Z"
      fill={color}
    />
    <path
      d="M253.231 37.586H251.1L244.874 21.5439L241.044 27.5466V37.586H239.043V8.41443H241.044V24.4565L251.01 8.41443H253.141L246.217 19.4992L253.231 37.586Z"
      fill={color}
    />
    <path
      d="M270.382 37.5856H268.336L266.857 30.8794H258.325L256.886 37.5856H254.971L261.283 8.42053H263.98L270.382 37.5856ZM262.546 11.0438L258.671 29.17H266.507L262.546 11.0438Z"
      fill={color}
    />
    <path
      d="M279.221 38C276.985 38 275.315 37.3886 274.217 36.1657C273.114 34.9428 272.563 33.2202 272.563 30.998V28.9993H274.478V31.0835C274.478 34.5286 276.047 36.2512 279.18 36.2512C282.314 36.2512 283.883 34.476 283.883 30.9191V28.1709C283.883 24.8968 282.359 23.2531 279.311 23.2531H277.31V21.4583H279.356C280.75 21.4583 281.843 21.0375 282.645 20.1893C283.442 19.3412 283.843 18.1578 283.843 16.6259V14.9559C283.843 11.4845 282.304 9.74885 279.231 9.74885C276.182 9.74885 274.658 11.4583 274.658 14.8771V16.3761H272.743V15.002C272.743 10.334 274.934 8 279.316 8C281.462 8 283.086 8.60487 284.189 9.8146C285.292 11.0243 285.843 12.7535 285.843 15.002V16.2906C285.843 19.5122 284.595 21.4977 282.098 22.2472V22.2867C284.625 22.9244 285.883 24.8836 285.883 28.1644V30.998C285.878 35.666 283.657 38 279.221 38Z"
      fill={color}
    />
    <path
      d="M303.465 37.5856H301.42L299.941 30.8794H291.408L289.969 37.5856H288.054L294.366 8.42053H297.063L303.465 37.5856ZM295.629 11.0438L291.754 29.17H299.59L295.629 11.0438Z"
      fill={color}
    />
    <path
      d="M308.599 37.586H306.599V8.41443H308.599V21.4979H317.959V8.41443H319.959V37.586H317.959V23.2534H308.599V37.586Z"
      fill={color}
    />
    <path
      d="M326.361 33.2073L335.892 8.41443H338.113V37.586H336.198V12.8326L326.667 37.586H324.446V8.41443H326.361V33.2073Z"
      fill={color}
    />
    <path
      d="M355.219 8.41443V37.586H353.218V23.7925H349.343C347.513 23.7925 346.214 24.1606 345.447 24.897C344.68 25.6334 344.294 26.8891 344.294 28.6708V34.0883C344.294 35.3967 344.179 36.5604 343.943 37.586H341.898C342.159 36.751 342.289 35.5873 342.289 34.0883V28.7563C342.289 26.9812 342.6 25.6071 343.227 24.6537C343.848 23.6938 344.846 23.0758 346.209 22.7997V22.7603C343.598 22.1488 342.294 20.1633 342.294 16.8036V15.0088C342.294 12.7011 342.815 11.0311 343.863 9.98576C344.906 8.94697 346.606 8.421 348.957 8.421H355.219V8.41443ZM349.428 21.9976H353.213V10.1633H348.992C347.307 10.1633 346.104 10.5512 345.377 11.327C344.65 12.1028 344.289 13.3848 344.289 15.16V17.1192C344.289 18.8418 344.7 20.0844 345.528 20.847C346.36 21.6163 347.658 21.9976 349.428 21.9976Z"
      fill={color}
    />
    <path d="M360 37.5857H357.909V33.7921H360V37.5857Z" fill={color} />
  </svg>
);
