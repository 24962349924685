import React from 'react';
import styled from 'styled-components';
import Flex from 'Components/Flex';
import { media, EnumScreens, getVwLg, getVwSm } from 'theme/helpers/css';
import logo from './img/logo.svg';
import { ExternalLink, Link } from 'Components/Link';
import { Text } from 'Components/Typography/Text';
import { EnumFontFamily, TextStylesLg, TextStylesSm, EnumTextType } from 'theme';
import { phone, sentissUrl, siteMap } from './constants';

const Container = styled(Flex).attrs({ between: true })`
  display: grid;
  padding: ${getVwSm('15px')};
  grid-row-gap: ${getVwSm('24px')};

  ${media.up(EnumScreens.lg)} {
    padding: ${getVwLg('24px')} ${getVwLg('64px')};
    grid-template-columns: ${getVwLg('540px')} ${getVwLg('520px')} ${getVwLg('545px')};
    justify-content: ;
  }
`;

const Logo = styled.img.attrs({ src: logo, width: '224', height: '128' })`
  margin-right: ${getVwSm('12px')};
  width: ${getVwSm('140px')};
  height: auto;

  ${media.up(EnumScreens.lg)} {
    width: ${getVwLg('224px')};
    margin-right: ${getVwLg('18px')};
  }
`;

const Title = styled(Text).attrs({
  textStyle: {
    sm: TextStylesSm[EnumFontFamily.futura + EnumTextType.header],
    lg: TextStylesLg[EnumFontFamily.futura + EnumTextType.header],
  },
})`
  margin-bottom: ${getVwSm('6px')};
  opacity: 0.6;

  ${media.up(EnumScreens.lg)} {
    margin-bottom: ${getVwLg('6px')};
  }
`;

const SiteMapList = styled.ul`
  display: grid;
  grid-row-gap: ${getVwSm('6px')};

  ${media.up(EnumScreens.lg)} {
    grid-template-columns: repeat(2, max-content);
    grid-gap: 0 ${getVwLg('32px')};
  }
`;

const Info: React.FC = () => (
  <Container>
    <Contacts />
    <Address />
    <SiteMap />
  </Container>
);

interface IInfoColumn {
  title: string;
}

const InfoColumn: React.FC<IInfoColumn> = ({ children, title }) => (
  <Flex column>
    <Title>{title}</Title>
    {children}
  </Flex>
);

const Contacts = () => (
  <InfoColumn title="Контакты организации:">
    <Flex>
      <Logo />
      <Flex column center>
        <Text>ООО «Сентисс Рус» </Text>
        <ExternalLink href={`tel: ${phone}`}>{phone}</ExternalLink>
        <ExternalLink href={sentissUrl} target="_blank">
          www.sentiss.ru
        </ExternalLink>
      </Flex>
    </Flex>
  </InfoColumn>
);

const Address = () => (
  <InfoColumn title="Адрес:">
    <Flex middle>
      <Text>
        Россия, 115432, Москва, Проектируемый 4062-й проезд, д.6, стр.16, этаж 4, оф.12 <br />
        Бизнес-Центр&nbsp;«Порт Плаза»
      </Text>
    </Flex>
  </InfoColumn>
);

const SiteMap = () => (
  <InfoColumn title="Карта сайта:">
    <SiteMapList>
      {siteMap.map(({ to, text }) => (
        <Link to={to} key={to}>
          {text}
        </Link>
      ))}
    </SiteMapList>
  </InfoColumn>
);

export default Info;
