import React from 'react';
import '../assets/css/fonts.css';
import '../assets/css/index.css';
import { ThemeProvider } from 'styled-components';

const Layout: React.FC<{ theme?: any }> = ({ children, theme = {} }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Layout;
