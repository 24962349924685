import { navigate } from 'gatsby-link';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import styled from 'styled-components';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import IconLogo from './Icons/IconLogo';
import { useLocation } from '@reach/router';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
`;

const StyledLogo = styled(IconLogo)`
  width: ${getVwSm('72px')};
  height: auto;

  ${mediaLgUp} {
    width: ${getVwLg('112px')};
  }
`;

const Logo: React.FC = () => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const isNotMainPage = pathname !== '/';

  return isMobile && isNotMainPage ? null : (
    <Container>
      <StyledLogo onClick={() => isNotMainPage && navigate('/')} />
    </Container>
  );
};

export default Logo;
