import styled from 'styled-components';
import { getVwLg, getVwSm, media, EnumScreens } from '../../theme/helpers/css';
import { EnumFontFamily, TextStylesLg, TextStylesSm, EnumTextType } from '../../theme';
import colors from '../../theme/colors';

export interface ITextStyle {
  sm: string;
  lg: string;
}

interface IText {
  textStyle?: ITextStyle;
  color?: string;
}

const defaultTextStyle: ITextStyle = {
  sm: TextStylesSm[EnumFontFamily.futura + EnumTextType.body],
  lg: TextStylesLg[EnumFontFamily.futura + EnumTextType.body],
};

export const Text = styled.div<IText>`
  color: ${({ color, theme }) => color ?? theme.color ?? 'inherit'};

  ${({ textStyle = defaultTextStyle }) => `
    ${textStyle.sm}

    ${media.up(EnumScreens.lg)} {
      ${textStyle.lg}
    }
  `}
`;

export const handTextStyle: ITextStyle = {
  sm: TextStylesSm[EnumFontFamily.fewriter + EnumTextType.body],
  lg: TextStylesLg[EnumFontFamily.fewriter + EnumTextType.body],
};

export const HandText = styled(Text).attrs(({ textStyle = handTextStyle, ...props }) => ({
  textStyle,
  ...props,
}))<IText>``;

export const Highlight = styled.div`
  display: inline-block;
  line-height: inherit;
  background-color: ${props => props.bg || colors.moonYellow};
  color: ${props => props.color || colors.blue};

  padding: 0 ${getVwSm('6px')};
  margin: 0 ${getVwSm`-6px`};

  ${media.up(EnumScreens.lg)} {
    padding: 0 ${getVwLg('4px')};
    margin: 0 ${getVwLg('-4px')};
  }
`;

interface ITitle {
  lvl: number | string;
}

export const Title = styled(Text).attrs(({ lvl, textStyle }) => ({
  as: `h${lvl}`,
  textStyle,
}))<ITitle>``;
