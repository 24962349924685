import useIsMobile from 'hooks/useIsMobile';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { NotDoctorPc, NotDoctorM } from './NotDoctor';
import { BeforeUsingPc, BeforeUsingM } from './BeforeUsing';
import { SeeDoctorPc, SeeDoctorM } from './SeeDoctor';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import Box from 'Components/Box';
import Flex from 'Components/Flex';

export interface IDisclaimerVector {
  bgColor: string;
  color: string;
}

export enum EnumDisclaimers {
  NOT_DOCTOR,
  BEFORE_USING,
  SEE_DOCTOR,
}

const mDisclaimers = {
  [EnumDisclaimers.NOT_DOCTOR]: NotDoctorM,
  [EnumDisclaimers.BEFORE_USING]: BeforeUsingM,
  [EnumDisclaimers.SEE_DOCTOR]: SeeDoctorM,
};

const pcDisclaimers = {
  [EnumDisclaimers.NOT_DOCTOR]: NotDoctorPc,
  [EnumDisclaimers.BEFORE_USING]: BeforeUsingPc,
  [EnumDisclaimers.SEE_DOCTOR]: SeeDoctorPc,
};

const Container = styled(Box)`
  width: 100vw;
  bottom: 0;
  left: 0;
  position: sticky;
  height: ${getVwSm('80px')};
  z-index: 100;

  svg {
    width: 100% !important;
    height: auto !important;
  }

  ${mediaLgUp} {
    height: ${getVwLg('80px')};
  }
`;

export const DisclaimerWrapper = styled(Flex).attrs({ bottom: true })`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
`;

const Disclaimer: React.FC<{ type: EnumDisclaimers }> = ({ type = EnumDisclaimers.NOT_DOCTOR }) => {
  const isMobile = useIsMobile();
  const Disclaimer = isMobile ? mDisclaimers[type] : pcDisclaimers[type];
  const { disclaimer, bgColor, body } = useContext(ThemeContext);

  return (
    <Container>
      <Disclaimer bgColor={disclaimer?.bgColor || bgColor} color={disclaimer?.color || body?.color} />
    </Container>
  );
};

export default Disclaimer;
