import styled from 'styled-components';
import { EnumFontFamily, TextStylesLg, TextStylesSm, EnumTextType } from 'theme';
import { ITextStyle, Text } from './Typography/Text';
import { Link as GastbyLink } from 'gatsby';

// const ExternalLink = styled.a`
//   color: ${props => props.color || 'inherit'};
//   text-decoration: underline;
//   cursor: pointer;
// `;

const linkTextStyle: ITextStyle = {
  sm: TextStylesSm[EnumFontFamily.futura + EnumTextType.link],
  lg: TextStylesLg[EnumFontFamily.futura + EnumTextType.link],
};

export const Link = styled(Text).attrs(props => ({ textStyle: linkTextStyle, as: GastbyLink, ...props }))`
  text-decoration: underline;
  cursor: pointer;
`;

export const ExternalLink = styled(Link).attrs({ as: 'a', target: '_blank' })``;
