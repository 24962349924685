import { SVGAttributes } from 'react';
import styled from 'styled-components';
import { getVwLg, getVwSm, media, EnumScreens } from '../../theme/helpers/css';

interface IDimension {
  sm?: string;
  lg?: string;
}

export interface IIcon extends SVGAttributes<SVGElement> {
  theme?: any;
  bgColor?: string;
  color?: string;
  w?: string | IDimension;
  h?: string | IDimension;
  onClick?: (e: any) => void;
  className?: stringl;
}

const getDimension = (name: string, value: IDimension) => {
  if (typeof value === 'object')
    return `
    ${name}: ${value.sm || getVwSm('24px')};
    
    ${media.up(EnumScreens.lg)} {
      ${name}: ${value.lg || getVwLg('24px')};
    }
  `;
  else return `${name}: ${value || '24px'};`;
};

const Icon = styled.svg.attrs(({ className, ...props }) => ({ className, ...props }))<IIcon>`
  flex-shrink: 0;

  ${({ w, h, color, onClick }) => `
    ${getDimension('width', w)}
    ${getDimension('height', h || w)}
    fill: ${color || 'none'};
    cursor: ${onClick ? 'pointer' : 'default'};
  `}
`;

export default Icon;
