import { useEffect } from 'react';

const useScrollListener = (cb: (e: Event) => void, enable = true): void => {
  useEffect(() => {
    if (enable) {
      document.addEventListener('scroll', cb);
      return () => document.removeEventListener('scroll', cb);
    }
  }, [cb, enable]);
};

export default useScrollListener;
